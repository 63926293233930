import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { 
  HiHome, 
  HiAcademicCap,
  HiArrowPath,
  HiBookOpen,
  HiUserPlus,
  HiHeart,
  HiArrowRight,
  HiCheck,
  HiCheckCircle,
  HiInformationCircle,
  HiExclamationTriangle
} from "react-icons/hi2";
import AuthenticateModal from '../../components/AuthenticateModal/AuthenticateModal';
import { getGroupComparisonStats, getPrimaryAssessmentStats } from '../../services/statsService';
import { API_ENDPOINTS } from '../../config/api';

const CompletionStats = ({ 
  triviaProgress, 
  effectiveUserId, 
  handleReturnHome 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { tempUserId, userId } = useSelector((state) => state.user); // Get tempUserId and userId from Redux
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showSequentialConfig, setShowSequentialConfig] = useState(false);
  const [modifiedProgress, setModifiedProgress] = useState(null);
  const [comparisonStats, setComparisonStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isTemporaryUser = tempUserId && tempUserId >= 10000 && tempUserId <= 99999;
  const [conversionStatus, setConversionStatus] = useState({
    attempted: false,
    success: false,
    error: null
  });
  const groupId = location?.state?.modeId;
  const [userCommunityId, setUserCommunityId] = useState(null);
  const [reflectionStepData, setReflectionStepData] = useState(null);
  
  // Check for examen sequence information
  const examSteps = location?.state?.examSteps || ['reading', 'trivia', 'reflection'];
  const currentStepIndex = location?.state?.currentStepIndex || 0;
  const nextStep = location?.state?.nextStep || 'reflection'; // Default to reflection if not set
  const isReflection = location?.state?.isReflection || false;
  
  // Check if there's a next step in the sequence
  const hasNextStep = nextStep && currentStepIndex < examSteps.length - 1;
  
  // Sequential learning data
  const isSequentialLearning = location.state?.isSequentialLearning;
  const journeyId = location.state?.journeyId;
  const currentSequence = location.state?.currentSequence;
  const totalSteps = location.state?.totalSteps;

  // Extract fromHome parameter to handle special routing from home page
  const fromHome = location.state?.fromHome || false;
  
  // Add loading state for reflection step data
  const [loadingReflectionStep, setLoadingReflectionStep] = useState(false);
  
  // Add conversion loading state
  const [conversionLoading, setConversionLoading] = useState(false);

  // Get the primary topic from the progress data
  const primaryTopic = triviaProgress.current_topic || 
                      (triviaProgress.topic_scores && Object.keys(triviaProgress.topic_scores)[0]) || 
                      'Scripture';


  // Add simplified useEffect hook to set community ID directly
  useEffect(() => {
    // Always set to default community ID 389
    setUserCommunityId(389);
  }, [effectiveUserId]);


  // Effect to fetch the reflection step data - using same approach as home/dashboard
  useEffect(() => {
    const fetchReflectionStepData = async () => {
      if (!effectiveUserId) return;
      
      try {
        setLoadingReflectionStep(true);
        
        // If we're in a sequential learning flow with journey ID, use that directly
        if (isSequentialLearning && journeyId) {
          // Find the reflection step with sequence=3 from the journey
          const reflectionStep = {
            sequence: 3,
            groupId: groupId, // Use the same groupId as trivia
            type: 'questions',
            title: 'Personal Reflection',
            isReflection: true
          };
          
          setReflectionStepData(reflectionStep);
        } 
        // For home page flow
        else if (fromHome) {
          // For home page, we just reuse the same groupId but set isReflection flag
          const reflectionStep = {
            sequence: 3,
            groupId: groupId, // Same groupId as trivia
            title: 'Personal Reflection',
            isReflection: true
          };
          
          setReflectionStepData(reflectionStep);
        }
        // Standard user dashboard flow
        else {
          // For normal dashboard flow, we also reuse the same groupId
          const reflectionStep = {
            sequence: 3,
            groupId: groupId, // Same groupId as trivia but with reflection flag
            title: 'Personal Reflection',
            isReflection: true
          };
          
          setReflectionStepData(reflectionStep);
        }
      } catch (error) {
        console.error("Error in fetchReflectionStepData:", error);
      } finally {
        setLoadingReflectionStep(false);
      }
    };

    if (effectiveUserId) {
      fetchReflectionStepData();
    }
  }, [effectiveUserId, journeyId, isSequentialLearning, fromHome, groupId, userCommunityId]);

  // Effect to fetch comparison stats (keeping this for functionality but won't display in UI)
  useEffect(() => {
    const fetchComparisonStats = async () => {
      if (!groupId || !effectiveUserId) return;
      
      try {
        setIsLoading(true);
        const [comparisonData, primaryData] = await Promise.all([
          getGroupComparisonStats(groupId, effectiveUserId),
          getPrimaryAssessmentStats(groupId, effectiveUserId)
        ]);
        setComparisonStats(comparisonData); 
      } catch (error) {
        console.error('Error fetching comparison stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComparisonStats();
  }, [groupId, effectiveUserId]);

  // Attempt conversion when component mounts
  useEffect(() => {
    const attemptConversion = async () => {
      // Only attempt if we haven't tried before and have a user ID
      if (!conversionStatus.attempted && effectiveUserId) {
        try {
          console.log('Attempting to convert any temp user data to', effectiveUserId);
          
          setConversionStatus(prev => ({ ...prev, attempted: true }));
          setConversionLoading(true);
          
          // Direct API call to /register/convert-temp-user
          // We only need to pass the userId - backend will look up temp user ID if needed
          const result = await axios.post(API_ENDPOINTS.CONVERT_TEMP_USER, {
            userId: effectiveUserId
          });
          
          if (result.data.success) {
            console.log('Backend data conversion completed:', result.data);
            setConversionStatus({
              attempted: true,
              success: true,
              error: null
            });
          } else {
            console.log('No conversion needed or completed', result.data);
            setConversionStatus({
              attempted: true,
              success: true, // Still mark as success to avoid warning
              error: null
            });
          }
        } catch (error) {
          console.error('Error during user data consolidation:', error);
          setConversionStatus({
            attempted: true,
            success: false,
            error: error.message
          });
        } finally {
          setConversionLoading(false);
        }
      }
    };
    
    attemptConversion();
  }, [effectiveUserId, conversionStatus.attempted]);


  // The handleAuthSuccess function inside the CompletionStats component
  const handleAuthSuccess = async (userData) => {
    try {
      // No need to convert data here - backend handles conversion during registration
      // and we also attempt conversion when the component mounts
      
      setShowAuthModal(false);
      
      // If there's a next step, proceed to it
      if (hasNextStep) {
        handleProceedToNextStep();
      } else if (userCommunityId) {
        navigate(`/user-dashboard/${userCommunityId}`);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error during auth success handling:', error);
      setShowAuthModal(false);
      // Navigation fallback in case of error
      if (userCommunityId) {
        navigate(`/user-dashboard/${userCommunityId}`);
      } else {
        navigate('/dashboard');
      }
    }
  };

  const handleRegisterClick = () => {
    setShowAuthModal(true);
  };

  const handleViewRecommendations = () => {
    const currentModeId = location?.state?.modeId;
    if (!currentModeId) {
      console.error('No modeId found in location state');
      return;
    }

    const progressWithGroupId = {
      ...triviaProgress,
      group_id: currentModeId
    };

    setModifiedProgress(progressWithGroupId);
    setShowSequentialConfig(true);
  };

  // Function to handle proceeding to the next step in the examen flow
  const handleProceedToNextStep = () => {
    if (!nextStep) {
      console.warn('No next step defined in state');
      handleReturnToDashboard();
      return;
    }
    
    // Update current step index for the next step
    const nextStepIndex = currentStepIndex + 1;
    
    // Determine which groupId to use for reflection - use same as trivia
    const reflectionGroupId = groupId;
    
    switch (nextStep) {
      case 'trivia':
        navigate('/trivia', { 
          state: { 
            modeId: isSequentialLearning ? groupId : 'latest',
            modeName: 'Weekly Gospel Trivia',
            difficulty: 'enriching',
            language: 'english',
            questionCount: 6,
            durationSeconds: 120,
            showSourceText: true,
            nextStep: 'reflection', // Next step after trivia is reflection
            examSteps,
            currentStepIndex: nextStepIndex,
            isSequentialLearning: isSequentialLearning,
            journeyId: journeyId,
            currentSequence: currentSequence,
            totalSteps: totalSteps,
            fromHome: fromHome
          }
        });
        break;


      case 'reflection':
        // First, store all parameters we need for the reflection
        const reflectionNavigationState = { 
          modeId: reflectionGroupId,
          modeName: 'Personal Reflection',
          difficulty: 'advanced',
          language: 'english',
          questionCount: 2,
          durationSeconds: 0,
          showSourceText: true,
          isReflection: true,
          forceNewConnection: true,     // Force WebSocket to reconnect
          freshStart: true,             // Signal components to reset state
          skipCompletionStats: true,    // Prevent showing completion stats from trivia
          examSteps,
          currentStepIndex: nextStepIndex,
          isSequentialLearning: isSequentialLearning,
          journeyId: journeyId,
          currentSequence: isSequentialLearning ? 3 : null,
          totalSteps: totalSteps,
          groupId: reflectionGroupId
        };
        
        // Navigate to a temporary URL first to force component unmount/cleanup
        navigate('/', { replace: true });
        
        // Short timeout to ensure complete unmount
        setTimeout(() => {
          // Navigate to reflection with a clean state
          navigate('/reflection', { 
            state: reflectionNavigationState,
            replace: true
          });
        }, 50);
        break;


      default:
        console.warn(`Unknown next step: ${nextStep}`);
        handleReturnToDashboard();
    }
  };

  // Function to return to dashboard
  const handleReturnToDashboard = () => {
    if (fromHome) {
      // If we came from the home page, just go back to home
      navigate('/');
    } else if (userCommunityId) {
      navigate(`/user-dashboard/${userCommunityId}`);
    } else {
      handleReturnHome();
    }
  };

  // Render conversion warning notification if needed
  const renderConversionWarning = () => {
    if (conversionStatus.attempted && !conversionStatus.success && effectiveUserId && effectiveUserId !== tempUserId) {
      return (
        <div className="mx-6 mb-6">
          <div className="bg-yellow-50 p-4 rounded-md border border-yellow-200">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-0.5">
                <HiExclamationTriangle className="h-5 w-5 text-yellow-600" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Data Sync Notice</h3>
                <div className="mt-1 text-sm text-yellow-700">
                  <p>
                    Some of your progress data may be incomplete. This will not affect your future activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  // Render conversion loading state
  const renderConversionLoading = () => {
    if (conversionLoading) {
      return (
        <div className="mx-6 mb-6">
          <div className="bg-blue-50 p-4 rounded-md border border-blue-200">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-0.5">
                <HiArrowPath className="h-5 w-5 text-blue-600 animate-spin" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-800">Syncing Your Data</h3>
                <div className="mt-1 text-sm text-blue-700">
                  <p>
                    Please wait while we save your progress data...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-md shadow-md overflow-hidden border border-gray-200 max-w-lg mx-auto">
      {/* Header */}
      <div className="flex flex-col items-center justify-center text-center p-8 pb-6">
        <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
          <HiCheckCircle className="w-8 h-8 text-indigo-600" />
        </div>
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Gospel Study Complete</h2>
        <p className="text-gray-600 max-w-md">
          You've thoughtfully engaged with the Gospel teachings for this Sunday!
        </p>
      </div>
      
      {/* Scripture Stats - Simple Card */}
      <div className="mx-6 mb-6 bg-blue-50 rounded-lg p-6 border border-blue-100">
        <div className="flex items-center justify-center mb-4">
          <HiBookOpen className="w-5 h-5 mr-2 text-indigo-600" />
          <h3 className="font-medium text-indigo-900">Your Scripture Journey</h3>
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white rounded-md p-4 text-center border border-gray-200">
            <div className="text-3xl font-bold text-indigo-700 mb-1">
              {triviaProgress.correct_answers || 0}
            </div>
            <div className="text-sm text-gray-600">
              Correct Answers
            </div>
          </div>
          
          <div className="bg-white rounded-md p-4 text-center border border-gray-200">
            <div className="text-xl font-medium text-indigo-700 mb-1 capitalize">
              {primaryTopic}
            </div>
            <div className="text-sm text-gray-600">
              Scripture Focus
            </div>
          </div>
        </div>
        
        <div className="mt-4 text-center text-sm text-gray-600">
          {triviaProgress.correct_answers || 0} of {triviaProgress.total_questions || 0} correct
        </div>
      </div>
      
      {/* Conversion Loading Indicator */}
      {renderConversionLoading()}
      
      {/* Data Conversion Warning (if applicable) */}
      {renderConversionWarning()}
      
      {/* Registration for Temporary Users */}
      {isTemporaryUser && (
        <div className="mx-6 mb-6">
          <div className="bg-blue-50 p-4 rounded-md border border-blue-200">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-0.5">
                <HiInformationCircle className="h-5 w-5 text-blue-600" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-800">Create an Account</h3>
                <div className="mt-1 text-sm text-blue-700">
                  <p>
                    Save your progress and continue your spiritual journey with a free account.
                  </p>
                </div>
                <div className="mt-3">
                  <button
                    onClick={handleRegisterClick}
                    className="flex items-center text-sm bg-white text-blue-700 px-3 py-1.5 rounded-md border border-blue-300 hover:bg-blue-50"
                  >
                    <HiUserPlus className="w-4 h-4 mr-1.5" />
                    Register Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Action Buttons */}
      <div className="p-6 pt-0">
        {loadingReflectionStep ? (
          <div className="mb-4 flex items-center justify-center py-4 bg-gray-50 rounded-md border border-gray-200">
            <HiArrowPath className="w-5 h-5 text-indigo-600 animate-spin mr-2" />
            <span className="text-gray-700">Loading next step...</span>
          </div>
        ) : hasNextStep && nextStep === 'reflection' ? (
          <div className="space-y-3">
          
            {/* Secondary Action: Return to Dashboard */}
            <button
              onClick={handleReturnToDashboard}
              disabled={conversionLoading}
              className="w-full flex items-center justify-center gap-2 bg-gray-100 text-gray-700 hover:bg-gray-200
                px-4 py-2.5 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <HiHome className="w-4 h-4" />
              Return to Dashboard
            </button>
          </div>
        ) : hasNextStep ? (
          <button
            onClick={handleProceedToNextStep}
            disabled={conversionLoading}
            className={`w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-4 py-3 rounded-md
              transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              font-medium mb-3 text-base disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {conversionLoading ? (
              <>
                <HiArrowPath className="w-4 h-4 animate-spin" />
                <span>Preparing Next Step...</span>
              </>
            ) : (
              <>
                {nextStep === 'trivia' ? 'Continue to Gospel Trivia' : 
                 nextStep === 'reflection' ? 'Continue to Personal Reflection' : 
                 'Continue to Next Step'}
                <HiArrowRight className="w-4 h-4 ml-1" />
              </>
            )}
          </button>
        ) : (
          <button
            onClick={handleReturnToDashboard}
            disabled={conversionLoading}
            className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white hover:bg-indigo-700
              px-4 py-3 rounded-md transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              font-medium text-base disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {conversionLoading ? (
              <>
                <HiArrowPath className="w-4 h-4 animate-spin" />
                <span>Finalizing Data...</span>
              </>
            ) : (
              <>
                <HiHome className="w-4 h-4 mr-1" />
                Return to Dashboard
              </>
            )}
          </button>
        )}
      </div>

      <AuthenticateModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onSuccess={handleAuthSuccess}
        isFromCompletionStats={true}
        tempUserId={effectiveUserId}
        initialMode="signup"
      />
    </div>
  );
};

export default CompletionStats;