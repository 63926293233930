import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authenticateService from '../../services/authenticateService';
import { signIn, signup } from '../../store/actions/userActions'; 
import { 
  HiEnvelope, 
  HiLockClosed, 
  HiUserCircle,
  HiArrowRight,
  HiExclamationCircle,
  HiXMark,
  HiCheck
} from "react-icons/hi2";
import Analytics from '../../services/analytics'; // Import analytics service

const AuthenticateModal = ({ 
  isOpen, 
  onClose, 
  isFromCompletionStats = false,
  tempUserId = null,
  onSuccess = null,
  onModeToggle = null, // New callback for mode toggle
  onAuthFailure = null, // New callback for auth failures
  isFromTrivia = false,  
  communityId = null,
  initialMode = 'register'  
}) => {
  // Add initialization logging
  useEffect(() => {
    console.log('AuthenticateModal initialized with:', { 
      isFromCompletionStats, 
      tempUserId, 
      isFromTrivia, 
      communityId,
      initialMode
    });
    
    // Track modal display
    Analytics.trackAuthModalOpen(
      isFromTrivia ? 'trivia' : isFromCompletionStats ? 'completion_stats' : 'direct',
      {
        initialMode,
        isFromTrivia,
        tempUserId: tempUserId ? 'present' : 'absent',
        communityId: communityId || 'default'
      }
    );
  }, [isFromCompletionStats, tempUserId, isFromTrivia, communityId, initialMode]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(initialMode === 'login');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    language: 'english', // Keep this in the state for existing logic
    personalContext: '', // Keep this in the state for existing logic
    communityId: communityId || '389', // Default to weekly community ID
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
    name: false,
    language: false, // Keep this in the state for existing logic
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const getValidationError = (field) => {
    if (!touched[field]) return '';
    
    switch (field) {
      case 'email':
        if (!formData.email) return 'Email is required';
        if (!validateEmail(formData.email)) return 'Invalid email format';
        return '';
      case 'password':
        if (!formData.password) return 'Password is required';
        return '';
      case 'name':
        if (!isLogin && !formData.name) return 'Name is required';
        return '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Track form field interaction
    Analytics.trackAnswerSelection({
      questionType: 'auth_form',
      questionNumber: 1,
      action: 'field_input',
      field: name,
      hasValue: value.length > 0,
      authMode: isLogin ? 'login' : 'register'
    });
    
    // Update form data
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    setError('');
  };

  const handleBlur = (field) => {
    // Track field blur with validation status
    const error = getValidationError(field);
    
    Analytics.trackAnswerSelection({
      questionType: 'auth_form',
      questionNumber: 1,
      action: 'field_blur',
      field,
      isValid: !error,
      authMode: isLogin ? 'login' : 'register'
    });
    
    setTouched(prev => ({
      ...prev,
      [field]: true
    }));
  };

  const toggleMode = () => {
    console.log(`Toggling auth mode from ${isLogin ? 'login' : 'signup'} to ${!isLogin ? 'login' : 'signup'}`);
    
    const fromMode = isLogin ? 'login' : 'register';
    const toMode = !isLogin ? 'login' : 'register';
    
    // Track mode toggle
    Analytics.trackAuthModeToggle(fromMode, toMode);
    
    // Call parent callback if provided
    if (onModeToggle) {
      onModeToggle(fromMode, toMode);
    }
    
    setIsLogin(!isLogin);
    setError('');
    setFormData({
      email: '',
      password: '',
      name: '',
      language: 'english',
      communityId: communityId || '389',
    });
    setTouched({
      email: false,
      password: false,
      name: false,
      language: false,
    });
  };

  const handleForgotPassword = () => {
    // Track forgot password click
    Analytics.trackAnswerSelection({
      questionType: 'auth_form',
      action: 'forgot_password',
      authMode: 'login'
    });
    
    onClose();
    navigate('/forgot-password');
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    // Track form submission attempt
    Analytics.trackAnswerSubmission({
      questionType: 'auth_form',
      authMode: isLogin ? 'login' : 'register',
      hasEmail: !!formData.email,
      hasPassword: !!formData.password,
      hasName: !!formData.name,
      language: formData.language,
      isFromTrivia,
      isFromCompletionStats
    });
    
    try {
      if (isLogin) {
        // Login logic
        console.log('Attempting login with email:', formData.email);
        
        const loginData = {
          email: formData.email,
          password: formData.password,
          communityId: communityId // Add communityId to login request
        };
        
        const response = await dispatch(signIn(loginData));
        console.log('Login response:', response);
        
        if (response) {
          if (onSuccess) {
            console.log('Calling onSuccess with login response and tempUserId:', tempUserId);
            // Include tempUserId in the success callback
            const enhancedResponse = {
              ...response,
              tempUserId: tempUserId
            };
            onSuccess(enhancedResponse);
          } else {
            onClose();
            // Only navigate if not from trivia
            if (!isFromTrivia) {
              if (communityId) {
                // If they're logging in from a community invitation, join that community
                try {
                  console.log(`Joining community ${communityId} after login for user ${response.user_id}`);
                  await authenticateService.joinCommunity(communityId, response.user_id);
                } catch (joinError) {
                  console.error('Error joining community after login:', joinError);
                }
                navigate(`/user-dashboard/${communityId}`);
              } else if (response.community_id) {
                navigate(`/user-dashboard/${response.community_id}`);
              } else {
                navigate('/dashboard');
              }
            }
          }
        }
      } else {
        // Registration logic
        console.log('Starting signup process with tempUserId:', tempUserId);
        
        // Use the communityId from URL if provided, otherwise use the default
        const finalCommunityId = communityId || formData.communityId;
        console.log('Community ID for registration:', finalCommunityId);
        
        const signupData = {
          fullName: formData.name,
          email: formData.email,
          password: formData.password,
          subscriptionType: 'weekly',  // Always default to weekly
          subscription_status: 'active', // Direct to active status
          tempUserId: tempUserId,
          communityId: finalCommunityId,
          language: formData.language,
          personalContext: formData.personalContext
        };
        
        console.log('Final signup payload:', JSON.stringify(signupData, null, 2));
        
        const response = await dispatch(signup(signupData));
        if (response) {
          console.log('Registration response received:', response);
          
          // Make sure response includes user_id for parent component
          if (!response.user_id && response.userId) {
            response.user_id = response.userId;
          }
          
          // Add tempUserId and registration flag to the response
          const responseWithFlag = {
            ...response,
            user_id: response.user_id || response.userId, // Ensure user_id is available
            communityId: finalCommunityId,  // Pass along the community ID
            tempUserId: tempUserId,  // Make sure tempUserId is included in response
            isNewUser: true  // Flag this as a new user registration
          };
          
          console.log('Passing to parent component:', responseWithFlag);
          
          // Check if we have an onSuccess callback
          if (onSuccess) {
            onSuccess(responseWithFlag);
          } else {
            onClose();
            if (!isFromTrivia) {
              if (finalCommunityId || response.community_id) {
                navigate(`/user-dashboard/${finalCommunityId || response.community_id}`);
              } else {
                navigate('/dashboard');
              }
            }
          }
        }
      }
    } catch (err) {
      console.error('Authentication error:', err);
      console.error('Error details:', err.response?.data);
      
      // Track authentication failure
      const errorType = err.response?.data?.error || err.message;
      
      Analytics.trackAuthFailure(isLogin ? 'login' : 'register', {
        errorType: errorType || 'unknown',
        message: err.response?.data?.error || err.message,
        field: errorType?.toLowerCase().includes('email') ? 'email' : 
               errorType?.toLowerCase().includes('password') ? 'password' : 'unknown',
        source: isFromTrivia ? 'trivia' : isFromCompletionStats ? 'completion_stats' : 'modal'
      });
      
      // Call parent callback if provided
      if (onAuthFailure) {
        onAuthFailure(isLogin ? 'login' : 'register', {
          type: errorType,
          message: err.response?.data?.error || err.message
        });
      }
      
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };




  // Add effect to log when modal is opened/closed
  useEffect(() => {
    if (isOpen) {
      console.log('AuthenticateModal opened with tempUserId:', tempUserId);
    }
  }, [isOpen, tempUserId]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 overflow-y-auto h-full w-full z-50 flex items-center justify-center p-1 sm:p-4">
      <div className="relative mx-auto w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow-xl overflow-hidden">
          <button
            onClick={() => {
              // Track modal close
              Analytics.trackAnswerSelection({
                questionType: 'ui_interaction',
                action: 'close_modal',
                authMode: isLogin ? 'login' : 'register',
                stage: 'form',
                hasEnteredData: formData.email.length > 0 || formData.password.length > 0 || formData.name.length > 0
              });
              
              onClose();
            }}
            className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-gray-500 z-10"
            aria-label="Close"
          >
            <HiXMark className="h-4 w-4 sm:h-6 sm:w-6" />
          </button>

          {/* Header with gradient background */}
          <div className="bg-gradient-to-r from-indigo-600 to-indigo-700 px-4 sm:px-6 py-3 sm:py-4">
            <h2 className="text-lg sm:text-xl font-semibold text-white">
              {isLogin ? 'Sign in to Your Account' : isFromCompletionStats ? 'Save Your Progress' : 'Create Your Account'}
            </h2>
            <p className="mt-1 text-sm text-indigo-100">
              {isLogin ? 'Welcome back!' : 'Join our community of gospel explorers'}
            </p>
          </div>

          <div className="px-4 sm:px-6 py-3 sm:py-5">
            <p className="text-center text-sm text-gray-600 mb-4">
              {isFromCompletionStats ? (
                "Already have an account? "
              ) : (
                isLogin ? "Don't have an account? " : "Already have an account? "
              )}
              <button
                onClick={toggleMode}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                {isFromCompletionStats ? 'Sign in instead' : (isLogin ? 'Sign up' : 'Sign in')}
              </button>
            </p>

            <form className="space-y-3 sm:space-y-4" onSubmit={handleSubmit}>
              {!isLogin && (
                <>
                  <div>
                    <div className="flex items-center justify-between">
                      <label className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      {getValidationError('name') && (
                        <p className="text-xs text-red-600">
                          {getValidationError('name')}
                        </p>
                      )}
                    </div>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HiUserCircle className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={() => handleBlur('name')}
                        className={`appearance-none block w-full pl-10 py-2 border ${
                          getValidationError('name') ? 'border-red-300' : 'border-gray-300'
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        placeholder="John Smith"
                      />
                      {getValidationError('name') && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <HiExclamationCircle className="h-5 w-5 text-red-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div>
                <div className="flex items-center justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  {getValidationError('email') && (
                    <p className="text-xs text-red-600">
                      {getValidationError('email')}
                    </p>
                  )}
                </div>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiEnvelope className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => handleBlur('email')}
                    className={`appearance-none block w-full pl-10 py-2 border ${
                      getValidationError('email') ? 'border-red-300' : 'border-gray-300'
                    } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    placeholder="you@example.com"
                  />
                  {getValidationError('email') && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <HiExclamationCircle className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="flex justify-between items-center">
                  <label className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  {isLogin && (
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="text-xs font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password?
                    </button>
                  )}
                  {getValidationError('password') && (
                    <p className="text-xs text-red-600">
                      {getValidationError('password')}
                    </p>
                  )}
                </div>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiLockClosed className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => handleBlur('password')}
                    className={`appearance-none block w-full pl-10 py-2 border ${
                      getValidationError('password') ? 'border-red-300' : 'border-gray-300'
                    } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    placeholder="••••••••"
                  />
                  {getValidationError('password') && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <HiExclamationCircle className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-3">
                  <div className="flex">
                    <HiExclamationCircle className="h-5 w-5 text-red-400" />
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  ) : (
                    <div className="flex items-center">
                      {isLogin ? 'Sign in' : 'Create account'}
                      <HiArrowRight className="ml-2 h-4 w-4" />
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticateModal;
