import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticateModal from './AuthenticateModal';
import Analytics from '../../services/analytics'; // Import analytics service

// This component manages the streamlined authentication flow without payment steps
const AuthenticationFlow = ({
  isOpen,
  onClose,
  isFromCompletionStats = false,
  tempUserId = null,
  onSuccess = null,
  onModeToggle = null,  // New callback for mode toggle tracking
  onAuthFailure = null, // New callback for auth failure tracking
  isFromTrivia = false,
  communityId = null,
  initialMode = 'register', // Default to register mode
  planType = 'weekly' // Default plan type
}) => {
  const navigate = useNavigate();
  
  const handleAuthComplete = async (data) => {
    console.log('Auth complete with data:', data);
    
    // Ensure tempUserId is passed along to parent component
    const enhancedData = {
      ...data,
      tempUserId: tempUserId // Make sure tempUserId is included
    };
    
    // Track authentication success directly here for consistency
    Analytics.trackAuthSuccess(
      data?.isNewUser ? 'register' : 'login',
      {
        isNewUser: data?.isNewUser || false,
        userId: data?.user_id || data?.userId,
        tempUserId: tempUserId,
        source: isFromTrivia ? 'trivia' : isFromCompletionStats ? 'completion_stats' : 'modal',
        planType: planType,
        communityId: communityId || data?.communityId || data?.community_id
      }
    );
    
    // Handle navigation after successful authentication
    if (onSuccess) {
      onSuccess(enhancedData);
    }
    
    // Close modal
    onClose();
    
    // Handle navigation based on context
    if (!isFromTrivia) {
      const targetCommunityId = communityId || data?.communityId || data?.community_id;
      
      // Track navigation after authentication
      Analytics.trackAnswerSelection({
        questionType: 'navigation',
        action: 'post_auth_navigation',
        userId: data?.user_id || data?.userId,
        userType: 'registered',
        destination: targetCommunityId ? `/user-dashboard/${targetCommunityId}` : '/dashboard',
        isNewUser: data?.isNewUser || false
      });
      
      if (targetCommunityId) {
        navigate(`/user-dashboard/${targetCommunityId}`);
      } else {
        navigate('/dashboard'); // Fallback if no community ID
      }
    }
  };
  
  // Handle mode toggle between login and register
  const handleModeToggle = (fromMode, toMode) => {
    // Track mode toggle
    Analytics.trackAuthModeToggle(fromMode, toMode);
    
    // Call parent callback if provided
    if (onModeToggle) {
      onModeToggle(fromMode, toMode);
    }
  };
  
  // Handle authentication failure
  const handleAuthFailure = (method, error) => {
    // Track auth failure
    Analytics.trackAuthFailure(method, {
      errorType: error.type || 'unknown',
      message: error.message || 'Authentication failed',
      source: isFromTrivia ? 'trivia' : isFromCompletionStats ? 'completion_stats' : 'modal'
    });
    
    // Call parent callback if provided
    if (onAuthFailure) {
      onAuthFailure(method, error);
    }
  };
  
  if (!isOpen) return null;
  
  return (
    <AuthenticateModal
      isOpen={isOpen}
      onClose={onClose}
      isFromCompletionStats={isFromCompletionStats}
      tempUserId={tempUserId}
      onSuccess={handleAuthComplete}
      onModeToggle={handleModeToggle}
      onAuthFailure={handleAuthFailure}
      isFromTrivia={isFromTrivia}
      communityId={communityId}
      initialMode={initialMode}
    />
  );
};

export default AuthenticationFlow;