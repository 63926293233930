// components/CommunityTriviaSection/CommunityTriviaSection.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  HiChevronDown, 
  HiChevronUp, 
  HiOutlineCheckCircle, 
  HiOutlineClock, 
  HiPlay, 
  HiChevronRight, 
  HiUserGroup
} from 'react-icons/hi2';

import { getUserCommunities } from '../../services/communityService';
import { checkGroupCompletion } from '../../services/dashboardService';

const CommunityTriviaSection = ({ userId }) => {
  const navigate = useNavigate();
  const [communities, setCommunities] = useState([]);
  const [expandedCommunities, setExpandedCommunities] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAllGroups, setShowAllGroups] = useState({});

  useEffect(() => {
    if (!userId) return;
    
    const fetchUserCommunities = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch communities the user has joined
        const userCommunities = await getUserCommunities(userId);
        
        // Filter out default community (ID 389)
        const filteredCommunities = userCommunities.filter(comm => comm.id !== 389);
        
        if (filteredCommunities.length > 0) {
          // Initialize expanded state - expand first community by default
          const initialExpanded = filteredCommunities.reduce((acc, community, index) => {
            acc[community.id] = index === 0;
            return acc;
          }, {});
          
          setExpandedCommunities(initialExpanded);
          
          // Initialize show all groups state
          const initialShowAll = filteredCommunities.reduce((acc, community) => {
            acc[community.id] = false;
            return acc;
          }, {});
          setShowAllGroups(initialShowAll);
          
          // Fetch groups for each community using API directly
          const communitiesWithGroups = await Promise.all(
            filteredCommunities.map(async (community) => {
              try {
                // Direct API call to get community groups
                const response = await fetch(`/api/communities/${community.id}/groups?user_id=${userId}`);
                const groups = await response.json();
                
                // Mark completion status for each group
                const groupsWithStatus = await Promise.all(
                  groups.map(async (group) => {
                    try {
                      const completion = await checkGroupCompletion(group.id, userId);
                      return {
                        ...group,
                        isCompleted: completion.isCompleted
                      };
                    } catch (err) {
                      console.error(`Error checking completion for group ${group.id}:`, err);
                      return {
                        ...group,
                        isCompleted: false
                      };
                    }
                  })
                );
                
                // Sort groups by ID descending to get the most recent first
                const sortedGroups = groupsWithStatus.sort((a, b) => b.id - a.id);
                
                return {
                  ...community,
                  groups: sortedGroups,
                  totalGroups: sortedGroups.length
                };
              } catch (error) {
                console.error(`Error fetching groups for community ${community.id}:`, error);
                return {
                  ...community,
                  groups: [],
                  totalGroups: 0
                };
              }
            })
          );
          
          setCommunities(communitiesWithGroups);
        } else {
          setCommunities([]);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user communities:', err);
        setError('Failed to load your communities');
        setLoading(false);
      }
    };
    
    fetchUserCommunities();
  }, [userId]);
  
  const toggleCommunity = (communityId) => {
    setExpandedCommunities(prev => ({
      ...prev,
      [communityId]: !prev[communityId]
    }));
  };
  
  const toggleShowAllGroups = (communityId) => {
    setShowAllGroups(prev => ({
      ...prev,
      [communityId]: !prev[communityId]
    }));
  };
  
  const handleStartTrivia = (groupId, groupName, difficulty, questionCount, showSourceText = true) => {
    navigate('/trivia', { 
      state: { 
        modeId: groupId,
        modeName: groupName,
        difficulty: difficulty || 'mixed',
        language: 'english',
        questionCount: questionCount || 6,
        durationSeconds: 120,
        showSourceText: showSourceText,
        fromHome: false,
        userId: userId,
        groupId: groupId,
        isReflection: false
      }
    });
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-5 shadow-sm mb-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Your Parish Communities</h2>
        <div className="flex justify-center items-center p-6">
          <div className="animate-spin h-6 w-6 border-2 border-blue-600 border-t-transparent rounded-full"></div>
          <span className="ml-2 text-gray-600">Loading communities...</span>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-5 shadow-sm mb-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Your Parish Communities</h2>
        <div className="p-4 text-red-600 bg-red-50 rounded-lg">
          {error}
        </div>
      </div>
    );
  }
  
  if (communities.length === 0) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-5 shadow-sm mb-4">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Your Parish Communities</h2>
        <div className="p-6 text-center">
          <div className="h-12 w-12 mx-auto text-gray-400 mb-3">
            <HiUserGroup className="h-full w-full" />
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Communities Joined</h3>
          <p className="text-sm text-gray-600 mb-4">You haven't joined any parish communities yet. Communities can share special trivia content with their members.</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl border border-gray-200 p-4 sm:p-5 shadow-sm mb-4">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Your Parish Communities</h2>
      
      {communities.map((community) => {
        const isExpanded = expandedCommunities[community.id];
        const shouldShowAll = showAllGroups[community.id];
        
        // For collapsed view, show max 2 most recent groups
        const displayGroups = shouldShowAll ? community.groups : community.groups.slice(0, 2);
        const hasMoreGroups = community.groups.length > 2;
        
        return (
          <div key={community.id} className="mb-4 border border-gray-200 rounded-lg overflow-hidden">
            {/* Community Header */}
            <div 
              className="p-4 bg-gray-50 flex justify-between items-center cursor-pointer"
              onClick={() => toggleCommunity(community.id)}
            >
              <div className="flex items-center">
                <h3 className="font-medium text-gray-900">
                  {community.name}
                </h3>
                <span className="ml-2 text-xs text-gray-500 bg-gray-200 px-2 py-0.5 rounded-full">
                  {community.totalGroups} {community.totalGroups === 1 ? 'group' : 'groups'}
                </span>
              </div>
              <button className="text-gray-600 hover:text-gray-900">
                {isExpanded ? <HiChevronUp className="h-5 w-5" /> : <HiChevronDown className="h-5 w-5" />}
              </button>
            </div>
            
            {/* Community Body - only shown when expanded */}
            {isExpanded && (
              <div className="p-4">
                {displayGroups.length > 0 ? (
                  <div className="space-y-3">
                    {displayGroups.map((group) => (
                      <div key={group.id} className="border border-gray-200 rounded-lg p-3 hover:bg-gray-50 transition-colors">
                        <div className="flex justify-between items-start">
                          <div className="flex-1">
                            <h4 className="font-medium text-gray-900">{group.name}</h4>
                            <p className="text-sm text-gray-600 mt-1 truncate">{group.description || 'Parish trivia content'}</p>
                          </div>
                          
                          <div className="flex items-center space-x-1">
                            {group.isCompleted ? (
                              <div className="flex items-center text-green-600 bg-green-50 px-2 py-1 rounded-full text-xs">
                                <HiOutlineCheckCircle className="w-4 h-4 mr-1" />
                                <span>Completed</span>
                              </div>
                            ) : (
                              <div className="flex items-center text-blue-600 bg-blue-50 px-2 py-1 rounded-full text-xs">
                                <HiOutlineClock className="w-4 h-4 mr-1" />
                                <span>Not completed</span>
                              </div>
                            )}
                          </div>
                        </div>
                        
                        <div className="mt-3 flex justify-end">
                          <button 
                            onClick={() => handleStartTrivia(
                              group.id, 
                              group.name,
                              group.difficulty,
                              group.trivia_question_count,
                              group.include_source_text
                            )}
                            className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <HiPlay className="w-4 h-4 mr-1" />
                            {group.isCompleted ? 'Retry' : 'Start'}
                          </button>
                        </div>
                      </div>
                    ))}
                    
                    {/* "Show more" button */}
                    {hasMoreGroups && (
                      <div className="text-center mt-3">
                        <button 
                          onClick={() => toggleShowAllGroups(community.id)}
                          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
                        >
                          {shouldShowAll ? (
                            <>Show less</>
                          ) : (
                            <>Show all {community.totalGroups} groups <HiChevronRight className="ml-1 w-4 h-4" /></>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center py-4 text-gray-500">
                    No trivia groups available for this community
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CommunityTriviaSection;