import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { 
  UserCircle,
  CreditCard,
  CheckCircle,
  XCircle,
  ArrowRight,
  ArrowLeft,
  ShieldCheck,
  AlertCircle,
  ChevronDown,
  ChevronUp,
  BookOpen,
  MessageSquare,
  Save,
  Mail,
  Phone,
  MapPin
} from 'lucide-react';
import { API_ENDPOINTS } from '../../config/api';
import Header from '../../components/Header/Header';
import PasswordResetSection from '../../components/PasswordResetSection/PasswordResetSection';
import StripeCheckout from '../../components/StripeCheckout/StripeCheckout';
import dashboardService from '../../services/dashboardService';

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;
  const subscriptionRef = useRef(null);
  const checkoutRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!effectiveUserId) return;
      
      try {
        setLoading(true);
        
        // Fetch subscription status
        const subscriptionResponse = await axios.get(
          API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', effectiveUserId)
        );
        setSubscriptionStatus(subscriptionResponse.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load account information');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [effectiveUserId]);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!effectiveUserId) return;
      
      try {
        setLoading(true);
        const response = await axios.get(
          API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', effectiveUserId)
        );
        setSubscriptionStatus(response.data);
      } catch (err) {
        console.error('Error fetching subscription status:', err);
        setError('Failed to load subscription information');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [effectiveUserId]);

  // Handle the upgrade parameter and scroll to subscription section
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const isUpgrade = urlParams.get('upgrade') === 'true';
    
    // If redirected for upgrade, scroll to subscription section and show checkout
    if (isUpgrade && subscriptionRef.current) {
      // Automatically expand checkout when coming from upgrade prompt
      setShowCheckout(true);
      
      // Highlight the subscription section
      subscriptionRef.current.classList.add('highlight-animation');
      
      // Scroll to the subscription section
      setTimeout(() => {
        subscriptionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        
        // Remove highlight after animation completes
        setTimeout(() => {
          subscriptionRef.current.classList.remove('highlight-animation');
        }, 2000);
      }, 500);
      
      // Remove the query parameter from URL, keep the hash
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [location.search]);

  // Scroll to checkout when expanded
  useEffect(() => {
    if (showCheckout && checkoutRef.current) {
      setTimeout(() => {
        checkoutRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }, [showCheckout]);

  const handlePaymentComplete = async () => {
    setSuccessMessage('Your account has been upgraded to premium!');
    setShowSuccessModal(true);
    
    // Refresh subscription status
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', effectiveUserId)
      );
      setSubscriptionStatus(response.data);
    } catch (err) {
      console.error('Error refreshing subscription status:', err);
    }
    
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 2000);
  };

  const handleCancelSubscription = async () => {
    try {
      setCancelProcessing(true);
      const result = await axios.post(
        API_ENDPOINTS.CANCEL_SUBSCRIPTION.replace(':userId', effectiveUserId)
      );
      
      if (result.status === 200) {
        // Refresh subscription status
        const statusResponse = await axios.get(
          API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', effectiveUserId)
        );
        setSubscriptionStatus(statusResponse.data);
        setSuccessMessage('Your subscription has been cancelled.');
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 2000);
      }
    } catch (err) {
      console.error('Error cancelling subscription:', err);
      setError('Failed to cancel subscription');
    } finally {
      setCancelProcessing(false);
      setShowCancelConfirm(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const toggleCheckout = () => {
    setShowCheckout(!showCheckout);
  };

  const isPremium = subscriptionStatus?.subscription_status === 'premium';

  // Loading state
  if (loading && !subscriptionStatus) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin h-10 w-10 border-b-2 border-blue-600 rounded-full mx-auto mb-4" />
          <p className="text-gray-600">Loading account information...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-8 gap-4">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-gray-900">
              Account Details
            </h1>
          </div>
          
          <button 
            onClick={() => navigate('/dashboard')}
            className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="h-4 w-4 mr-1" />
            Back to Dashboard
          </button>
        </div>
        
        {/* User Profile Section */}
        {subscriptionStatus?.userInfo && (
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-lg font-semibold text-gray-900">Profile Information</h2>
                <p className="text-sm text-gray-500">Your account details</p>
              </div>
              <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                <UserCircle className="h-6 w-6 text-purple-600" />
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-500 mb-1">Name</p>
                <p className="font-medium text-gray-900">{subscriptionStatus.userInfo.fullName || "Not provided"}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 mb-1">Email</p>
                <p className="font-medium text-gray-900">{subscriptionStatus.userInfo.email}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Password Reset Section */}
        {userId && <PasswordResetSection userId={userId} />}
        
        {/* Subscription Section */}
        <div 
          ref={subscriptionRef}
          id="subscription" 
          className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8 transition-all duration-300"
        >
          <div className="flex items-center justify-between mb-4">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Subscription</h2>
              <p className="text-sm text-gray-500">Manage your premium access</p>
            </div>
            <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
              <CreditCard className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          
          {isPremium ? (
            <div>
              <div className="flex items-center p-4 bg-green-50 rounded-lg mb-4">
                <CheckCircle className="h-5 w-5 text-green-600 mr-2" />
                <div>
                  <p className="font-medium text-green-800">Premium Subscription Active</p>
                  <p className="text-sm text-green-700">You have full access to all premium features</p>
                </div>
              </div>
              
              {subscriptionStatus?.stripe && (
                <div className="p-4 bg-gray-50 rounded-lg mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Subscription Details</h3>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div className="text-gray-500">Status:</div>
                    <div className="font-medium text-gray-800 capitalize">
                      {subscriptionStatus.stripe.status || 'Active'}
                    </div>
                    
                    {subscriptionStatus.stripe.currentPeriodEnd && (
                      <>
                        <div className="text-gray-500">
                          {subscriptionStatus.stripe.cancelAtPeriodEnd 
                            ? 'Access Until:' 
                            : 'Next Billing Date:'}
                        </div>
                        <div className="font-medium text-gray-800">
                          {formatDate(new Date(subscriptionStatus.stripe.currentPeriodEnd * 1000))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              
              <div className="mt-4 p-4 bg-blue-50 rounded-lg mb-4">
                <h3 className="text-sm font-medium text-blue-700 mb-2">Premium Features</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className="h-4 w-4 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-sm text-blue-700">Complete access to all readings</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-4 w-4 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-sm text-blue-700">Daily mass access</span>
                  </li>
                </ul>
              </div>
              
              <div className="flex justify-end mt-4">
                {!showCancelConfirm ? (
                  <button
                    onClick={() => setShowCancelConfirm(true)}
                    className="px-4 py-2 text-sm font-medium text-red-600 bg-red-50 hover:bg-red-100 rounded-lg transition-colors"
                  >
                    Cancel Subscription
                  </button>
                ) : (
                  <div className="flex flex-col sm:flex-row gap-2">
                    <p className="text-sm text-gray-600 sm:mr-4 self-center">Are you sure you want to cancel?</p>
                    <button
                      onClick={() => setShowCancelConfirm(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                      disabled={cancelProcessing}
                    >
                      No, Keep Subscription
                    </button>
                    <button
                      onClick={handleCancelSubscription}
                      className="px-4 py-2 text-sm font-medium text-red-600 bg-red-50 hover:bg-red-100 rounded-lg transition-colors"
                      disabled={cancelProcessing}
                    >
                      {cancelProcessing ? (
                        <div className="flex items-center">
                          <div className="w-4 h-4 border-2 border-red-600 border-t-transparent rounded-full animate-spin mr-2"></div>
                          Processing...
                        </div>
                      ) : (
                        'Yes, Cancel'
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex items-center p-4 bg-blue-50 rounded-lg mb-4">
                <ShieldCheck className="h-5 w-5 text-blue-600 mr-2" />
                <div>
                  <p className="font-medium text-blue-800">Upgrade to Premium</p>
                  <p className="text-sm text-blue-700">Get access to all premium features for $5/month</p>
                </div>
              </div>
              
              <div className="mt-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
                <h3 className="text-md font-medium text-gray-900 mb-2">Premium Features</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-sm text-gray-700">Full access to all readings</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="text-sm text-gray-700">Daily mass access</span>
                  </li>
                </ul>
              </div>
              
              {/* Collapsible checkout button */}
              <div className="mt-6">
                {!showCheckout ? (
                  <button
                    onClick={toggleCheckout}
                    className="w-full py-3 flex items-center justify-center text-white bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors"
                  >
                    <span>Upgrade to Premium</span>
                    <ChevronDown className="ml-2 h-5 w-5" />
                  </button>
                ) : (
                  <div>
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="text-md font-medium text-gray-900">Complete Your Subscription</h3>
                      <button
                        onClick={toggleCheckout}
                        className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
                      >
                        <span>Hide Checkout</span>
                        <ChevronUp className="ml-1 h-4 w-4" />
                      </button>
                    </div>
                    
                    <div ref={checkoutRef} className="checkout-container">
                      <StripeCheckout 
                        planType="weekly" 
                        onPaymentComplete={handlePaymentComplete} 
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        
        {/* Customer Support Section */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Customer Support</h2>
              <p className="text-sm text-gray-500">We're here to help you</p>
            </div>
            <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
              <MessageSquare className="h-6 w-6 text-purple-600" />
            </div>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-start">
              <Mail className="h-5 w-5 text-gray-600 mr-3 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-700">Email</p>
                <a href="mailto:Adrian@examenAI.com" className="text-blue-600 hover:text-blue-800">
                  Adrian@examenAI.com
                </a>
              </div>
            </div>
            
            <div className="flex items-start">
              <Phone className="h-5 w-5 text-gray-600 mr-3 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-700">Phone</p>
                <a href="tel:4019354878" className="text-blue-600 hover:text-blue-800">
                  (401) 935-4878
                </a>
              </div>
            </div>
            
            <div className="flex items-start">
              <MapPin className="h-5 w-5 text-gray-600 mr-3 mt-0.5" />
              <div>
                <p className="text-sm font-medium text-gray-700">Address</p>
                <p className="text-gray-600">
                  500 Fremont Street Apt #A<br />
                  Menlo Park, CA 94025
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Success Modal */}
        {showSuccessModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
              <div className="text-center">
                <CheckCircle className="h-12 w-12 text-green-500 mx-auto mb-4" />
                <h2 className="text-xl font-semibold mb-2">Success!</h2>
                <p className="text-gray-600 mb-4">{successMessage}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* Error Notification */}
        {error && (
          <div className="fixed bottom-4 right-4 bg-red-50 border border-red-200 rounded-lg p-4 shadow-lg max-w-sm">
            <div className="flex items-start">
              <AlertCircle className="h-5 w-5 text-red-600 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-red-800">{error}</p>
                <button 
                  onClick={() => setError(null)}
                  className="text-xs text-red-600 mt-1 hover:text-red-800"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

// Add these styles to your CSS
/*
@keyframes highlight-pulse {
  0% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.5); }
  70% { box-shadow: 0 0 0 10px rgba(59, 130, 246, 0); }
  100% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); }
}

.highlight-animation {
  animation: highlight-pulse 2s 1;
  border-color: #3b82f6;
}
*/

export default Account;
