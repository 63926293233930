import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { X, Church, CheckCircle } from 'lucide-react';
import { joinParishWithMass } from '../../services/communityService';

/**
 * SimplifiedParishFinder - Component to join predefined parishes
 * Displays St. Raymond and Nativity parishes without search or mass time selection
 */
const ParishFinder = ({ isOpen, onClose, onParishJoined }) => {
  const { userId } = useSelector((state) => state.user);
  const [parishes, setParishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [joiningParish, setJoiningParish] = useState(null);
  
  // Load predefined parishes when modal opens
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setError(null);
      
      const predefinedParishes = [
        {
          id: 394,  // Updated to match your database ID
          name: "St. Raymond Catholic Church",
          location: "Menlo Park, CA",
          description: "St. Raymond is a welcoming Catholic faith community located in Menlo Park, CA",
          diocese: "Archdiocese of San Francisco"
        },
        {
          id: 395,  // Updated to match your database ID
          name: "Church of the Nativity",
          location: "Menlo Park, CA",
          description: "Church of the Nativity is a Catholic church located in Menlo Park, CA",
          diocese: "Archdiocese of San Francisco"
        }
      ];

      
      setParishes(predefinedParishes);
      setLoading(false);
    }
  }, [isOpen]);
  
  // Join a parish without mass time
  const handleJoinParish = async (parish) => {
    try {
      setJoiningParish(parish.id);
      setError(null);
      
      // Join the parish without specifying a mass time
      await joinParishWithMass(parish.id, userId, null);
      
      if (onParishJoined) {
        onParishJoined({
          ...parish
        });
      }
      
      // Close modal after successful join
      onClose();
    } catch (error) {
      console.error('Error joining parish:', error);
      setError(`Failed to join ${parish.name}. Please try again later.`);
      setJoiningParish(null);
    }
  };
  
  // Render parish card
  const renderParishCard = (parish) => {
    return (
      <div 
        key={parish.id}
        className="border border-gray-200 rounded-lg hover:border-gray-300 transition-colors overflow-hidden mb-4"
      >
        <div className="p-4 bg-white">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-base font-semibold text-gray-900">{parish.name}</h3>
              
              {parish.location && (
                <div className="mt-1 text-sm text-gray-600">
                  {parish.location}
                </div>
              )}
              
              {parish.diocese && (
                <div className="mt-1 text-sm text-gray-600">
                  {parish.diocese}
                </div>
              )}
            </div>
          </div>
          
          {parish.description && (
            <p className="mt-2 text-sm text-gray-600">{parish.description}</p>
          )}
          
          <button
            onClick={() => handleJoinParish(parish)}
            disabled={joiningParish === parish.id}
            className="w-full mt-4 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {joiningParish === parish.id ? (
              <span className="flex items-center justify-center">
                <span className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                Joining...
              </span>
            ) : (
              'Join This Parish'
            )}
          </button>
        </div>
      </div>
    );
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-xl w-full">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Join Your Parish</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        
        {/* Parish listing */}
        <div className="p-6 overflow-y-auto" style={{ maxHeight: '70vh' }}>
          {error && (
            <div className="mb-4 text-sm text-red-600 bg-red-50 p-2 rounded">
              {error}
            </div>
          )}
          
          {loading ? (
            <div className="text-center py-10">
              <div className="animate-spin h-8 w-8 border-4 border-blue-600 border-t-transparent rounded-full mx-auto mb-4"></div>
              <p className="text-gray-600">Loading parishes...</p>
            </div>
          ) : parishes.length > 0 ? (
            <div className="space-y-1">
              <p className="text-sm text-gray-600 mb-4">
                Select your parish to join your community:
              </p>
              
              {parishes.map(parish => renderParishCard(parish))}
            </div>
          ) : (
            <div className="text-center py-6">
              <Church className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-600">No parishes available. Please try again later.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParishFinder;