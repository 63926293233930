import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { LockIcon, CheckCircle, AlertCircle } from 'lucide-react';
import { API_ENDPOINTS } from '../../config/api';
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51OoY4hIY48BI77g22zg36hsU7fRGBlvsMd09IJ8PHEqsvWXsOqf973Rn3I75ZYDQcZgNYAzmFlIKlGO0Pvs22oXq00IB4OTGNf');

const StripeCheckout = ({ planType = 'weekly', onPaymentComplete }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;

  // Plan configuration
  const planConfig = {
    weekly: {
      name: 'Premium Subscription',
      price: 5,
      priceId: 'price_1R6wpnIY48BI77g2C01H9z9o',
      communityId: '389'
    },
  };

  // Get the selected plan details
  const selectedPlan = planConfig[planType] || planConfig.weekly;
  
  useEffect(() => {
    let isMounted = true;
    
    const createCheckoutSession = async () => {
      if (!effectiveUserId) {
        if (isMounted) {
          setError('User ID is required');
          setIsLoading(false);
        }
        return;
      }
      
      if (isMounted) {
        setIsLoading(true);
        setError(null);
      }
      
      try {
        const requestData = {
          userId: effectiveUserId,
          priceId: selectedPlan.priceId,
          returnUrl: `${window.location.origin}/account`,
          communityId: selectedPlan.communityId
        };

        const response = await axios.post(
          API_ENDPOINTS.CREATE_CHECKOUT_SESSION,
          requestData,
          { credentials: 'include' }
        );

        if (isMounted && response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
        }
      } catch (err) {
        console.error('Checkout error:', err);
        if (isMounted) {
          setError(err.message || 'Failed to initialize checkout');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    createCheckoutSession();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [effectiveUserId, selectedPlan.priceId, selectedPlan.communityId]);

  return (
    <div className="w-full stripe-checkout-container">
      {/* Subscription details header */}

      {isLoading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg mb-4">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p>{error}</p>
          </div>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 text-sm font-medium"
          >
            Try Again
          </button>
        </div>
      ) : clientSecret ? (
        <div className="w-full min-h-[500px] overflow-hidden border border-gray-200 rounded-lg">
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ 
              clientSecret,
              onComplete: onPaymentComplete,
            }}
          >
            <div className="w-full h-full overflow-auto">
              <EmbeddedCheckout />
            </div>
          </EmbeddedCheckoutProvider>
        </div>
      ) : (
        <div className="flex justify-center items-center py-8">
          <p className="text-gray-500">Unable to initialize checkout. Please try again.</p>
        </div>
      )}

      <div className="flex items-center justify-center mt-3 text-xs text-gray-500">
        <LockIcon size={12} className="mr-1 flex-shrink-0" />
        <span>Secure payment powered by Stripe</span>
      </div>
    </div>
  );
};

export default StripeCheckout;