// LearnMoreFeature.jsx (Updated)
import React, { useState, useEffect, useRef } from 'react';
import { API_ENDPOINTS } from '../../config/api';
import { 
  HiLightBulb, 
  HiChevronUp, 
  HiArrowPath, 
  HiInformationCircle,
  HiQuestionMarkCircle,
  HiMiniChevronDown
} from "react-icons/hi2";

const LearnMoreFeature = ({ questionContext }) => {
  // State for UI
  const [showLearnMoreOption, setShowLearnMoreOption] = useState(false);
  const [learnMoreQuery, setLearnMoreQuery] = useState('');
  const [learnMoreLoading, setLearnMoreLoading] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(null);
  
  // WebSocket reference
  const wsRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  
  // Input reference for focus
  const inputRef = useRef(null);
  
  // Setup and cleanup WebSocket connection
  useEffect(() => {
    // Only establish connection when user clicks to show the learn more input
    if (!showLearnMoreOption) return;
    
    // Create WebSocket connection
    const topic = questionContext?.topic || 'Gospel';
    const wsUrl = `${API_ENDPOINTS.INFO_SOCKET}?topic=${encodeURIComponent(topic)}`;
    console.log("Connecting to info socket:", wsUrl);
    
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;
    
    // Set up event handlers
    ws.onopen = () => {
      console.log("Info WebSocket connection opened");
      setIsConnected(true);
      
      // Initialize session
      ws.send(JSON.stringify({
        type: 'session.initialize',
        data: {
          timestamp: new Date().toISOString()
        }
      }));
      
      // Focus on the input when connection is established
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    
    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Info WebSocket received:", data);
        
        // Handle different message types
        if (data.type === 'response.text.delta') {
          // Handle streaming text
          setAdditionalInfo(prev => (prev || "") + (data.delta || ""));
        } else if (data.type === 'response.text.done') {
          // Handle completed text
          setAdditionalInfo(data.text || "");
          setLearnMoreLoading(false);
          setLearnMoreQuery(""); // Clear input after response
        } else if (data.type === 'response.content_part.done' && 
                  data.content_part && 
                  data.content_part.type === 'text') {
          setAdditionalInfo(data.content_part.text || "");
          setLearnMoreLoading(false);
          setLearnMoreQuery(""); // Clear input after response
        } else if (data.type === 'response.done') {
          setLearnMoreLoading(false);
          setLearnMoreQuery(""); // Clear input after response
        } else if (data.type === 'error') {
          console.error("WebSocket error:", data.error);
          setLearnMoreLoading(false);
          setAdditionalInfo("Sorry, I couldn't retrieve that information. Please try a different question.");
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
      }
    };
    
    ws.onerror = (error) => {
      console.error("Info WebSocket error:", error);
      setIsConnected(false);
      setLearnMoreLoading(false);
    };
    
    ws.onclose = () => {
      console.log("Info WebSocket connection closed");
      setIsConnected(false);
      setLearnMoreLoading(false);
    };
    
    // Cleanup on unmount or when input is hidden
    return () => {
      if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
        console.log("Closing Info WebSocket connection");
        ws.close();
      }
      setIsConnected(false);
    };
  }, [showLearnMoreOption, questionContext?.topic]);
  
  // Handle form submission
  const handleLearnMoreSubmit = (e) => {
    e?.preventDefault();
    if (!learnMoreQuery.trim() || learnMoreLoading || !isConnected) return;
    
    setLearnMoreLoading(true);
    setAdditionalInfo(null);
    
    // Format the request with context information
    const request = {
      type: "input_text",
      content: `BRIEF_INFO_REQUEST:
        
        QUESTION CONTEXT: ${questionContext?.question_text || ''}
        SOURCE TEXT: ${questionContext?.sourceText || ''}
        CORRECT ANSWER: ${questionContext?.correctAnswer || ''}
        USER QUERY: ${learnMoreQuery.trim()}
        
        Please provide a concise, focused response (2-3 sentences) specifically addressing this query.`
    };
    
    // Send the request if websocket is connected
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(request));
    } else {
      console.error("WebSocket not connected");
      setLearnMoreLoading(false);
      setAdditionalInfo("Connection error. Please try again.");
    }
  };
  
  return (
    <div className="mt-2 pl-5">
      {/* Updated toggle button with clearer text */}
      <button
        onClick={() => setShowLearnMoreOption(!showLearnMoreOption)}
        className="text-xs flex items-center gap-1.5 text-amber-700 hover:text-amber-900 transition-colors"
      >
        {showLearnMoreOption ? (
          <>
            <HiChevronUp className="w-3 h-3" />
            <span>Close</span>
          </>
        ) : (
          <>
            <HiLightBulb className="w-3 h-3" />
            <span>Ask a quick follow-up explanation question</span>
            <HiMiniChevronDown className="w-3 h-3 text-amber-500" />
          </>
        )}
      </button>
      
      {/* Always show a subtle hint of the input field when closed */}
      {!showLearnMoreOption && (
        <div className="mt-2 pl-1">
          <div className="h-8 bg-gray-50 rounded-md border border-gray-100 px-2 flex items-center text-gray-400 text-xs cursor-pointer"
               onClick={() => setShowLearnMoreOption(true)}>
            <HiQuestionMarkCircle className="w-3 h-3 mr-1.5 text-amber-500" />
            <span className="italic">Type your question here...</span>
          </div>
        </div>
      )}
      
      {showLearnMoreOption && (
        <form 
          onSubmit={handleLearnMoreSubmit}
          className="mt-2"
        >
          <div className="flex gap-2 items-center">
            <input
              ref={inputRef}
              type="text"
              value={learnMoreQuery}
              onChange={(e) => setLearnMoreQuery(e.target.value)}
              placeholder={additionalInfo ? "Ask another follow-up question..." : "E.g., What did Aquinas say about this?"}
              className="flex-1 text-xs p-1.5 border rounded bg-white focus:border-amber-500 focus:ring-1 focus:ring-amber-500 focus:outline-none"
              maxLength={200}
              disabled={!isConnected || learnMoreLoading}
            />
            <button
              type="submit"
              disabled={!learnMoreQuery.trim() || learnMoreLoading || !isConnected}
              className="bg-amber-600 text-white text-xs px-2 py-1 rounded hover:bg-amber-700 
                disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              {learnMoreLoading ? <HiArrowPath className="w-3 h-3 animate-spin" /> : "Ask"}
            </button>
          </div>
          
          {!isConnected && showLearnMoreOption && (
            <div className="mt-2 flex items-center gap-1.5 text-amber-700 text-xs">
              <HiInformationCircle className="w-3.5 h-3.5 flex-shrink-0" />
              <span>Connecting to service...</span>
            </div>
          )}
        </form>
      )}
      
      {learnMoreLoading && (
        <div className="mt-2 flex items-center gap-1.5 text-amber-700 text-xs">
          <HiArrowPath className="w-3 h-3 animate-spin" />
          <span>Getting answer...</span>
        </div>
      )}
      
      {additionalInfo && (
        <div className="mt-2 border-t border-amber-200 pt-2">
          <p className="text-xs text-gray-700">{additionalInfo}</p>
        </div>
      )}
    </div>
  );
};

export default LearnMoreFeature;