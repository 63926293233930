import React, { useState } from 'react';
import { HiLightBulb, HiChevronDown, HiChevronUp } from 'react-icons/hi2';

const HintButton = ({ hint, isDisabled = false }) => {
  const [showHint, setShowHint] = useState(false);
  
  if (!hint) return null;
  
  return (
    <div className="mt-1">
      <div className="flex justify-end">
        <button
          onClick={() => setShowHint(!showHint)}
          disabled={isDisabled}
          className={`flex items-center text-xs text-gray-500 hover:text-gray-700 transition-colors ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <HiLightBulb className="w-3.5 h-3.5 mr-1 text-gray-500" />
          <span>{showHint ? 'Hide hint' : 'Need a hint?'}</span>
          {showHint ? 
            <HiChevronUp className="ml-1 w-3 h-3" /> : 
            <HiChevronDown className="ml-1 w-3 h-3" />
          }
        </button>
      </div>
      
      {showHint && (
        <div className="mt-1.5 p-2 text-xs text-left text-gray-700 bg-gray-50 border border-gray-200 rounded-md">
          {hint}
        </div>
      )}
    </div>
  );
};

export default HintButton;