// pages/CommunityInvitationPage/CommunityInvitationPage.jsx

import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiOutlineExclamationCircle, HiOutlineUserGroup, HiOutlineCalendar } from "react-icons/hi";
import { HiCheck, HiPlay, HiBookOpen, HiCalendar, HiArrowRight, HiChevronRight, HiHeart, HiSparkles } from "react-icons/hi2";
import axios from 'axios';

import Header from '../../components/Header/Header';
import { getCommunityDetails, getUpcomingCommunityTrivia, initializeGroupTrivia } from '../../services/communityService';
import { initializeHomePageUser } from '../../store/actions/userActions';

// Create a simple loading spinner component inline
const SimpleLoadingSpinner = ({ size = "default" }) => {
  const sizeClass = size === "large" ? "h-12 w-12" : "h-6 w-6";
  return (
    <div className={`${sizeClass} border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin mx-auto`}></div>
  );
};

const CommunityInvitationPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;
  
  const communityId = searchParams.get('communityId');
  const groupId = searchParams.get('groupId');
  
  const [community, setCommunity] = useState(null);
  const [upcomingTrivia, setUpcomingTrivia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startTrivia, setStartTrivia] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [sundayDate, setSundayDate] = useState(null);
  const [contentType, setContentType] = useState("Gospel");
  
  // Helper function to parse topics - similar to UserDashboard
  const parseTopics = (topicsData) => {
    // If it's null or undefined, return empty array
    if (!topicsData) return [];
    
    // If it's already an array, return it
    if (Array.isArray(topicsData)) return topicsData;
    
    // If it's a string, try to parse it as JSON
    if (typeof topicsData === 'string') {
      try {
        const parsed = JSON.parse(topicsData);
        return Array.isArray(parsed) ? parsed : [topicsData];
      } catch (e) {
        // If it can't be parsed as JSON, treat it as a single topic
        return [topicsData];
      }
    }
    
    // Default: return as-is
    return [topicsData];
  };
  
  // First, initialize a temp user if needed
  useEffect(() => {
    // Same implementation as before
    const setupUser = async () => {
      try {
        if (!userId && !tempUserId) {
          console.log("No user ID found in invitation page, initializing temp user");
          await dispatch(initializeHomePageUser());
          console.log("After initialization, tempUserId:", tempUserId);
        } else {
          console.log("User already exists in invitation page, user ID:", userId, "temp ID:", tempUserId);
        }
      } catch (err) {
        console.error('Error setting up user in invitation page:', err);
      } finally {
        setInitializing(false);
      }
    };
    
    setupUser();
  }, [dispatch, userId, tempUserId]);
  
  useEffect(() => {
    // Same data fetching logic as before
    const fetchData = async () => {
      if (initializing) return;
      
      if (!communityId) {
        setError('No community ID provided');
        setLoading(false);
        return;
      }
      
      // ... rest of your existing fetchData implementation
      try {
        // Get community details with the effective user ID (which should now exist)
        const currentEffectiveUserId = userId || tempUserId;
        console.log("Fetching community details with user ID:", currentEffectiveUserId);
        
        const communityData = await getCommunityDetails(communityId, true, currentEffectiveUserId);
        setCommunity(communityData);
        
        // Get today's date in Pacific Time
        const now = new Date();
        const pacificTime = new Date(now.toLocaleString("en-US", {timeZone: "America/Los_Angeles"}));
        const today = pacificTime.getDay(); // 0 = Sunday
        
        // Calculate the upcoming Sunday date
        const daysUntilSunday = today === 0 ? 0 : 7 - today;
        const upcomingSunday = new Date(pacificTime);
        upcomingSunday.setDate(pacificTime.getDate() + daysUntilSunday);
        
        // Format as readable date for display
        const sundayDateFormatted = upcomingSunday.toLocaleDateString('en-US', {
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        });
        setSundayDate(sundayDateFormatted);
        
        // Format as YYYY-MM-DD for API
        const sundayDateStr = upcomingSunday.toISOString().split('T')[0];
        
        // First check for homily for this community
        console.log("Checking for homily for community:", communityId, "and date:", sundayDateStr);
        let homilyFound = false;
        
        try {
          const homilyResponse = await axios.get(
            `/api/communities/readings/homily`,
            { 
              params: { 
                date: sundayDateStr,
                community_id: communityId,
                user_id: currentEffectiveUserId
              } 
            }
          );
          
          if (homilyResponse.data?.success && homilyResponse.data?.group) {
            // Homily found - process and use it
            homilyFound = true;
            const homily = homilyResponse.data.group;
            
            // Parse topics
            const parsedTopics = parseTopics(homily.topics);
            
            // Set content type to Homily
            setContentType("Homily");
            
            // Set homily data with proper name and description
            setUpcomingTrivia({
              ...homily,
              id: homily.id,
              name: homily.name || `Sunday Homily`,
              description: homily.description || (parsedTopics.length > 0 ? parsedTopics[0] : "Reflect on the homily and its meaning in our daily lives"),
              topics: parsedTopics,
              trivia_question_count: homily.trivia_question_count || 4
            });
            
            console.log("Using parish homily:", homily.name);
          } else {
            console.log("No homily found for this parish, will fall back to Gospel");
          }
        } catch (err) {
          console.error("Error fetching parish homily:", err);
        }
        
        // If no homily found, fall back to Gospel reading
        if (!homilyFound) {
          try {
            console.log("Fetching Gospel reading for Sunday:", sundayDateStr);
            const gospelResponse = await axios.get(
              `/api/communities/users/${currentEffectiveUserId}/current-gospel`,
              { params: { date: sundayDateStr } }
            );
            
            console.log("Gospel response data:", gospelResponse.data);
            
            if (gospelResponse.data?.success && gospelResponse.data?.group) {
              const gospel = gospelResponse.data.group;
              
              // Handle the default fallback case - check if it's the generic fallback
              if (gospel.id === 1855 || !gospel.name || gospel.name === "Gospel Examen") {
                console.log("Got default fallback, using hardcoded April 6 Gospel instead");
                
                // Use hardcoded values for April 6 Gospel (Raising Lazarus)
                setContentType("Gospel");
                setUpcomingTrivia({
                  id: 1927, // From the database for April 6
                  name: "Gospel - Raising Lazarus",
                  description: "Jesus brings friend back to life",
                  topics: ["Finding Purpose in Suffering", "Faith During Doubt", "Community Support"],
                  trivia_question_count: 4,
                  step_title: "Gospel",
                  community_id: 389,
                  include_source_text: true,
                  difficulty: "mixed",
                  scripture_book: "John",
                  scripture_chapter: "11",
                  scripture_verses: "1-45"
                });
              } else {
                // Use the real gospel data
                const parsedTopics = parseTopics(gospel.topics);
                
                // Set content type to Gospel
                setContentType("Gospel");
                
                // Set gospel data with proper name and description
                setUpcomingTrivia({
                  ...gospel,
                  id: gospel.id,
                  name: gospel.name || 'Sunday Gospel',
                  description: gospel.description || (parsedTopics.length > 0 ? parsedTopics[0] : "Reflect on the Gospel reading and its meaning in our daily lives"),
                  topics: parsedTopics,
                  trivia_question_count: gospel.trivia_question_count || 4
                });
                
                console.log("Using Sunday Gospel:", gospel.name);
              }
            } else {
              console.log("No Gospel group found for Sunday, using hardcoded fallback");
              
              // Use hardcoded fallback for the Raising Lazarus gospel
              setContentType("Gospel");
              setUpcomingTrivia({
                id: 1927, // From the database for April 6
                name: "Gospel - Raising Lazarus",
                description: "Jesus brings friend back to life",
                topics: ["Finding Purpose in Suffering", "Faith During Doubt", "Community Support"],
                trivia_question_count: 4,
                step_title: "Gospel",
                community_id: 389,
                include_source_text: true,
                difficulty: "mixed",
                scripture_book: "John",
                scripture_chapter: "11",
                scripture_verses: "1-45"
              });
            }
          } catch (err) {
            console.error("Error fetching Gospel reading:", err);
            
            // Set a default if we encounter an error - use hardcoded April 6 data
            setContentType("Gospel");
            setUpcomingTrivia({
              id: 1927, // From the database for April 6
              name: "Gospel - Raising Lazarus",
              description: "Jesus brings friend back to life",
              topics: ["Finding Purpose in Suffering", "Faith During Doubt", "Community Support"],
              trivia_question_count: 4,
              step_title: "Gospel",
              community_id: 389,
              include_source_text: true,
              difficulty: "mixed",
              scripture_book: "John",
              scripture_chapter: "11",
              scripture_verses: "1-45"
            });
          }
        }
        
        // If a specific groupId was provided in the URL, try to use that directly
        if (groupId && (!upcomingTrivia || !upcomingTrivia.id)) {
          try {
            console.log("Trying to use specified groupId:", groupId);
            const groupResponse = await axios.get(
              `/api/communities/groups/${groupId}`,
              { params: { user_id: currentEffectiveUserId } }
            );
            
            if (groupResponse.data) {
              const group = groupResponse.data;
              const parsedTopics = parseTopics(group.topics);
              
              // Determine content type based on step_title
              const groupContentType = group.step_title === 'Homily' ? 'Homily' : 'Gospel';
              setContentType(groupContentType);
              
              setUpcomingTrivia({
                ...group,
                id: group.id,
                name: group.name || (groupContentType === 'Homily' ? 'Sunday Homily' : 'Sunday Gospel'),
                description: group.description || (parsedTopics.length > 0 ? parsedTopics[0] : `Reflect on the ${groupContentType.toLowerCase()} and its meaning in our daily lives`),
                topics: parsedTopics,
                trivia_question_count: group.trivia_question_count || 4
              });
            }
          } catch (err) {
            console.error("Error fetching specified group:", err);
          }
        }
        
      } catch (err) {
        console.error('Error fetching invitation data:', err);
        setError('Failed to load community information');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [communityId, groupId, userId, tempUserId, initializing]);
  
  // Handle start trivia button
  const handleStartTrivia = async () => {
    // Same implementation as before
    try {
      setStartTrivia(true);
      console.log("Starting trivia session...");
      
      // Current effective user ID
      const currentEffectiveUserId = userId || tempUserId;
      console.log("User ID:", currentEffectiveUserId);
      
      // First check if we already have a trivia content loaded
      let groupToUse = null;
      
      if (upcomingTrivia && upcomingTrivia.id) {
        console.log("Using pre-loaded trivia content:", upcomingTrivia.name);
        groupToUse = upcomingTrivia;
      } else {
        // If not, get the appropriate content based on content type
        console.log("No pre-loaded content with ID, fetching appropriate content");
        
        // Get today's date in Pacific Time and calculate upcoming Sunday
        const now = new Date();
        const today = now.getDay(); // 0 = Sunday
        const daysUntilSunday = today === 0 ? 0 : 7 - today;
        const upcomingSunday = new Date(now);
        upcomingSunday.setDate(now.getDate() + daysUntilSunday);
        const sundayDateStr = upcomingSunday.toISOString().split('T')[0];
        
        if (contentType === "Homily") {
          // Try to get homily for this community
          try {
            const homilyResponse = await axios.get(
              `/api/communities/readings/homily`,
              { 
                params: { 
                  date: sundayDateStr,
                  community_id: communityId,
                  user_id: currentEffectiveUserId
                } 
              }
            );
            
            if (homilyResponse.data?.success && homilyResponse.data?.group) {
              console.log("Found homily for community:", homilyResponse.data.group);
              groupToUse = homilyResponse.data.group;
            }
          } catch (err) {
            console.error("Error fetching homily:", err);
          }
        }
        
        // If no group yet (no homily found or content type is Gospel), get the Gospel
        if (!groupToUse) {
          // If a specific group ID was provided in URL, try to use that
          if (groupId) {
            console.log("Trying to use specified group ID:", groupId);
            groupToUse = { id: groupId };
          } else {
            // No specific ID, fall back to default Sunday Gospel
            console.log("Falling back to Sunday Gospel reading");
            try {
              const gospelResponse = await axios.get(
                `/api/communities/users/${currentEffectiveUserId}/current-gospel`,
                { params: { date: sundayDateStr } }
              );
              
              if (gospelResponse.data?.success && gospelResponse.data?.group) {
                const gospel = gospelResponse.data.group;
                // Check if it's the default fallback
                if (gospel.id === 1855) {
                  console.log("Got default fallback from API, using hardcoded April 6 gospel");
                  groupToUse = {
                    id: 1927, // From the database for April 6
                    name: "Gospel - Raising Lazarus",
                    description: "Jesus brings friend back to life",
                    topics: ["Finding Purpose in Suffering", "Faith During Doubt", "Community Support"],
                    step_title: "Gospel",
                    community_id: 389,
                    include_source_text: true,
                    difficulty: "mixed",
                    trivia_question_count: 4,
                    scripture_book: "John",
                    scripture_chapter: "11",
                    scripture_verses: "1-45"
                  };
                } else {
                  console.log("Found Gospel reading:", gospel);
                  groupToUse = gospel;
                }
              }
            } catch (err) {
              console.error("Error fetching Gospel reading:", err);
            }
          }
        }
      }
      
      // At this point, if we still don't have a group to use, use hardcoded fallback
      if (!groupToUse || !groupToUse.id) {
        console.log("No valid group found, using hardcoded fallback for April 6 Gospel");
        groupToUse = {
          id: 1927, // From the database for April 6 Gospel
          name: "Gospel - Raising Lazarus",
          description: "Jesus brings friend back to life",
          topics: ["Finding Purpose in Suffering", "Faith During Doubt", "Community Support"],
          step_title: "Gospel",
          community_id: 389,
          include_source_text: true,
          difficulty: "mixed",
          trivia_question_count: 4,
          scripture_book: "John",
          scripture_chapter: "11",
          scripture_verses: "1-45"
        };
      }
      
      // Now initialize the trivia with the group we found
      console.log("Initializing trivia for group:", groupToUse.id);
      const result = await initializeGroupTrivia(groupToUse.id, currentEffectiveUserId);
      console.log("Trivia initialization result:", result);
      
      // Prepare navigation state with all required parameters
      const triviaState = {
        modeId: result.group_id || groupToUse.id,
        modeName: result.config?.name || groupToUse.name || 'Sunday Reflection',
        difficulty: result.config?.difficulty || groupToUse.difficulty || 'mixed',
        language: 'english',
        questionCount: result.config?.questionCount || groupToUse.trivia_question_count || 4,
        durationSeconds: 120,
        showSourceText: groupToUse.include_source_text !== false,
        fromHome: false,
        userId: currentEffectiveUserId,
        groupId: result.group_id || groupToUse.id,
        isReflection: false,
        fromCommunity: communityId,
        readingType: groupToUse.step_title || contentType
      };
      
      console.log("Navigating to trivia with state:", triviaState);
      
      // Navigate to the trivia page
      navigate('/trivia', { state: triviaState });
      
    } catch (err) {
      console.error('Error starting trivia:', err);
      setError('Failed to start trivia session');
      setStartTrivia(false);
    }
  };

  if (loading || initializing) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
        <Header />
        <div className="max-w-3xl mx-auto px-4 py-12 text-center">
          <div className="bg-white rounded-xl shadow-md p-8">
            <SimpleLoadingSpinner size="large" />
            <p className="mt-6 text-gray-600 font-medium">Loading your Sunday Gospel experience...</p>
            <p className="text-gray-500 text-sm mt-2">Preparing this Sunday's readings just for you</p>
          </div>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
        <Header />
        <div className="max-w-3xl mx-auto px-4 py-12">
          <div className="bg-white rounded-lg shadow-lg p-8 text-center">
            <div className="bg-red-100 text-red-800 rounded-full w-16 h-16 mx-auto flex items-center justify-center mb-4">
              <HiOutlineExclamationCircle className="w-8 h-8" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-3">Unable to Load Gospel Content</h2>
            <p className="text-gray-600 mb-6 max-w-md mx-auto">{error}</p>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition shadow-md"
            >
              Return to Home
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  if (!community) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
        <Header />
        <div className="max-w-3xl mx-auto px-4 py-12 text-center">
          <div className="bg-white rounded-xl shadow-md p-8">
            <div className="bg-amber-100 text-amber-800 rounded-full w-16 h-16 mx-auto flex items-center justify-center mb-4">
              <HiOutlineExclamationCircle className="w-8 h-8" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Community Not Found</h2>
            <p className="text-gray-600 mb-6">We couldn't find the community you're looking for.</p>
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 shadow-md transition"
            >
              Return to Home
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white pb-12">
      <Header />
      
      <main className="max-w-3xl mx-auto px-4 pt-6 pb-12">
        {/* Welcome Banner - Enhanced with better gradients and visual hierarchy */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-8 border border-indigo-100 transform transition hover:shadow-xl">
          <div className="bg-gradient-to-r from-indigo-600 to-indigo-800 px-6 py-6 text-white">
            <div className="flex items-center">
              <div className="flex-shrink-0 bg-white/20 rounded-full p-3 mr-4 shadow-inner">
                <HiOutlineUserGroup className="w-7 h-7" />
              </div>
              <div>
                <h1 className="text-2xl md:text-3xl font-bold">
                  {community.name} Sunday Gospel
                </h1>
                <p className="text-indigo-100 mt-1">
                  Your Weekly Journey Through Sunday's {contentType === "Homily" ? "Homily" : "Gospel"}
                </p>
              </div>
            </div>
          </div>
          
          <div className="p-6 md:p-8">
            {/* Updated welcome message with clearer call to action */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-gray-900 mb-3 flex items-center">
                <HiHeart className="text-rose-500 w-5 h-5 mr-2" />
                Become A Scripture Superstar & Apply Mass Teachings to Daily Life
              </h2>
              <p className="text-gray-700 leading-relaxed">
                Interactive trivia to easily learn and apply Sunday's Gospel teachings to your day. 
                Understand the reading's lessons, see how Saints interpret its significance, and 
                be more engaged at Mass. This 5-minute, 4-question interactive Examen helps you connect 
                Scripture to everyday challenges.
              </p>
            </div>
            
            {/* Sunday Gospel Section - Enhanced with better visualization */}
            <div className="bg-gradient-to-br from-indigo-50 to-blue-50 rounded-xl p-6 mb-8 border border-indigo-100 shadow-sm">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <div className="bg-indigo-100 rounded-full p-3 shadow-sm">
                    <HiCalendar className="w-6 h-6 text-indigo-600" />
                  </div>
                </div>
                <div className="ml-5 flex-1">
                  <div className="flex items-center mb-3">
                    <h3 className="font-bold text-xl text-gray-900">
                      {contentType === "Homily" 
                        ? `Sunday Homily` 
                        : `Sunday Gospel`}
                    </h3>
                    <span className="ml-3 px-3 py-1 bg-indigo-100 text-indigo-800 rounded-full text-xs font-medium">
                      {sundayDate}
                    </span>
                  </div>
                  
                  <h4 className="text-indigo-700 font-semibold text-lg mb-3">
                    {upcomingTrivia?.name || (contentType === "Homily" ? "Sunday Homily" : "Sunday Gospel")}
                  </h4>
                  
                  <div className="bg-white rounded-lg p-5 shadow-sm border border-indigo-100">
                    <p className="text-gray-700 font-medium mb-3">
                      {upcomingTrivia?.description || "Reflect on this week's readings and their meaning in our daily lives"}
                    </p>

                    {upcomingTrivia?.scripture_book && (
                      <div className="flex items-center mt-4 text-sm">
                        <HiBookOpen className="text-indigo-500 w-5 h-5 mr-2" />
                        <div>
                          <span className="font-semibold text-gray-700">Scripture Reading:</span>
                          <span className="ml-2 text-indigo-700 font-medium">{upcomingTrivia.scripture_book} {upcomingTrivia.scripture_chapter}:{upcomingTrivia.scripture_verses}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            
            {/* Begin Trivia Button with improved labeling */}
            <div className="text-center">
              <div className="max-w-lg mx-auto mb-5">
                <div className="flex items-center justify-center -space-x-2 mb-4">
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className="w-8 h-8 rounded-full bg-indigo-500 flex items-center justify-center text-white text-xs font-bold border-2 border-white shadow-sm">
                      {i+1}
                    </div>
                  ))}
                </div>
                <p className="text-gray-700 mb-4">
                  Your 4-question Examen takes just 5 minutes to complete and will help you become a Scripture Superstar.
                </p>
              </div>
              
              <button
                onClick={handleStartTrivia}
                disabled={startTrivia}
                className="px-10 py-4 bg-gradient-to-r from-indigo-600 to-indigo-700 text-white rounded-xl font-semibold hover:from-indigo-700 hover:to-indigo-800 shadow-lg transition transform hover:scale-105 disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {startTrivia ? (
                  <div className="flex items-center justify-center">
                    <span className="w-5 h-5 mr-3 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span className="text-lg">Preparing Your Examen...</span>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <HiPlay className="w-6 h-6 mr-3" />
                    <span className="text-lg">Begin Your 5-minute Interactive Examen</span>
                    <HiArrowRight className="w-6 h-6 ml-3" />
                  </div>
                )}
              </button>
              
              <p className="mt-4 text-gray-500 text-sm">
                No account needed. Begin your Sunday Gospel experience now.
              </p>
            </div>
          </div>
        </div>
        
        {/* Simplified benefits section styled more like the homepage */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100 hover:shadow-lg transition p-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-3 flex items-center">
            <HiSparkles className="text-indigo-500 w-5 h-5 mr-2" />
            Transform Your Mass Experience
          </h3>
          <p className="text-gray-700 mb-4">
            Apply Sunday Gospel teachings to everyday challenges and become a Scripture Superstar in the process.
          </p>

          <div className="grid md:grid-cols-2 gap-4 mt-4">
            <div className="flex items-start">
              <div className="bg-green-100 p-1.5 rounded-full mr-2 mt-0.5">
                <HiCheck className="w-4 h-4 text-green-600" />
              </div>
              <span className="text-gray-700">Understand the official Catholic Mass readings (USCCB)</span>
            </div>
            <div className="flex items-start">
              <div className="bg-green-100 p-1.5 rounded-full mr-2 mt-0.5">
                <HiCheck className="w-4 h-4 text-green-600" />
              </div>
              <span className="text-gray-700">Learn how Saints interpret Scripture teachings</span>
            </div>
            <div className="flex items-start">
              <div className="bg-green-100 p-1.5 rounded-full mr-2 mt-0.5">
                <HiCheck className="w-4 h-4 text-green-600" />
              </div>
              <span className="text-gray-700">Be more engaged at Mass with enhanced understanding</span>
            </div>
            <div className="flex items-start">
              <div className="bg-green-100 p-1.5 rounded-full mr-2 mt-0.5">
                <HiCheck className="w-4 h-4 text-green-600" />
              </div>
              <span className="text-gray-700">Apply Gospel wisdom to everyday life situations</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CommunityInvitationPage;



