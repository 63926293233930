// File: src/components/GoogleAnalytics/GoogleAnalytics.js

/* global gtag */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Tracking IDs - Update these with your actual IDs
const GA_TRACKING_ID = 'G-4EWL8M8PLF'; // Your Google Analytics ID
const ADS_TRACKING_ID = 'AW-16928381570'; // Your Google Ads ID

// Home page button conversion labels
export const HOME_BUTTON_CONVERSION_LABELS = {
  // Home page button clicks - Update these with your actual conversion labels
  HOME_START_EXAMEN: 'HOME_EXAMEN_PLACEHOLDER', // Home page "Start Free Examen" / "Start Examen" button
  HOME_START_READING: 'HOME_READING_PLACEHOLDER', // Home page "Start Reading" button 
  HOME_START_TRIVIA: 'HOME_TRIVIA_PLACEHOLDER', // Home page "Start with Trivia" button
  HOME_START_REFLECTION: 'HOME_REFLECT_PLACEHOLDER', // Home page "Start Reflecting" button
};

const GoogleAnalytics = () => {
  const location = useLocation();

  // Load Google Analytics and Google Ads scripts
  useEffect(() => {
    if (!window.gtag) {
      // Create and load the Google Tag Manager script
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      
      script.onload = () => {
        // Initialize the dataLayer and gtag function
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;
        
        // Initialize gtag with current date
        gtag('js', new Date());
        
        // Configure both Google Analytics and Google Ads
        gtag('config', GA_TRACKING_ID);
        gtag('config', ADS_TRACKING_ID);
        
        console.log('Google Analytics and Ads scripts loaded successfully');
      };

      script.onerror = () => {
        console.error('Failed to load Google Analytics script');
      };

      document.head.appendChild(script);

      return () => {
        // Clean up
        if (document.head.contains(script)) {
          document.head.removeChild(script);
        }
      };
    }
  }, []);

  // Track page views
  useEffect(() => {
    if (window.gtag) {
      // Send page view to Google Analytics
      window.gtag('config', GA_TRACKING_ID, { 
        page_path: location.pathname + location.search 
      });
      
      // Send page view to Google Ads
      window.gtag('config', ADS_TRACKING_ID, { 
        page_path: location.pathname + location.search 
      });
      
      console.log(`Page view recorded for: ${location.pathname + location.search}`);
    }
  }, [location]);

  return null;
};

// Helper function to track home page button conversions
export const trackHomeButtonClick = (buttonType, additionalParams = {}) => {
  if (!window.gtag) {
    console.error('Google Analytics not initialized for button tracking');
    return;
  }

  let conversionLabel;
  
  // Map button type to conversion label
  switch (buttonType) {
    case 'startExamen':
      conversionLabel = HOME_BUTTON_CONVERSION_LABELS.HOME_START_EXAMEN;
      break;
    case 'startReading':
      conversionLabel = HOME_BUTTON_CONVERSION_LABELS.HOME_START_READING;
      break;
    case 'startTrivia':
      conversionLabel = HOME_BUTTON_CONVERSION_LABELS.HOME_START_TRIVIA;
      break;
    case 'startReflection':
      conversionLabel = HOME_BUTTON_CONVERSION_LABELS.HOME_START_REFLECTION;
      break;
    default:
      console.warn(`Unknown button type: ${buttonType}`);
      return;
  }
  
  // Track in Google Analytics as a regular event
  window.gtag('event', 'button_click', {
    'event_category': 'home_page',
    'event_label': buttonType,
    ...additionalParams
  });
  
  // Track as a conversion for Google Ads
  trackConversion(conversionLabel, 0, additionalParams);
  
  console.log(`Home button click tracked: ${buttonType}`, additionalParams);
};

// Helper function to track conversions with optional values and custom parameters
export const trackConversion = (conversionLabel, value = 0, additionalParams = {}) => {
  if (!window.gtag) {
    console.error('Google Ads tracking unavailable');
    return;
  }
  
  const params = {
    'send_to': `${ADS_TRACKING_ID}/${conversionLabel}`,
    ...additionalParams
  };
  
  // Only add value if greater than 0
  if (value > 0) {
    params.value = value;
    params.currency = 'USD';
  }
  
  window.gtag('event', 'conversion', params);
  console.log(`Conversion tracked: ${conversionLabel}`, params);
};

export default GoogleAnalytics;