// src/services/authenticateService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";

const authenticateService = {
  login: async (credentials) => {
    try {
      const response = await axios.post(API_ENDPOINTS.LOGIN, credentials);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Login failed';
    }
  },

  register: async (userData) => {
    try {
      const response = await axios.post(API_ENDPOINTS.SIGNUP, userData);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Registration failed';
    }
  },

  getUserInfo: async (email) => {
    try {
      const response = await axios.post(API_ENDPOINTS.USER_INFORMATION, { email });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch user information';
    }
  },

  requestPasswordReset: async (email) => {
    try {
      const response = await axios.post(API_ENDPOINTS.REQUEST_PASSWORD_RESET, { email });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to request password reset';
    }
  },

  resetPassword: async (token, password) => {
    try {
      const response = await axios.post(API_ENDPOINTS.RESET_PASSWORD, { token, password });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to reset password';
    }
  },

  changePassword: async (userId, currentPassword, newPassword) => {
    try {
      const response = await axios.post(API_ENDPOINTS.CHANGE_PASSWORD, {
        userId,
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to change password';
    }
  },

  joinCommunity: async (communityId, userId) => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.JOIN_COMMUNITY.replace(':communityId', communityId),
        { user_id: userId }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to join community';
    }
  },

  joinAfterSignup: async (communityId, userId) => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.JOIN_AFTER_SIGNUP.replace(':communityId', communityId),
        { user_id: userId }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to join community';
    }
  },

  updateParishAffiliation: async (userId, parishCode) => {
    try {
      const response = await axios.post(API_ENDPOINTS.PARISH_AFFILIATION, {
        userId,
        parishCode
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to update parish affiliation';
    }
  },

  getCommunityInfo: async (communityId) => {
    try {
      const response = await axios.get(API_ENDPOINTS.COMMUNITY_INFO.replace(':communityId', communityId));
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch community info';
    }
  },


  createTrialSubscription: async (userId, communityId = '389') => {
    try {
      console.log(`Creating trial subscription for user ID: ${userId}, community ID: ${communityId}`);
      
      if (!userId) {
        console.warn('No userId provided for trial creation, skipping API call');
        return { success: false, error: 'No user ID provided' };
      }
      
      // Convert userId to string if it's not already
      const userIdStr = String(userId);
      
      // Log the API endpoint for debugging
      const endpoint = API_ENDPOINTS.CREATE_TRIAL.replace(':userId', userIdStr);
      console.log(`Trial endpoint: ${endpoint}`);
      
      const response = await axios.post(
        endpoint,
        { communityId }
      );
      
      // The fallback code that added daily users to weekly community has been removed
      
      console.log('Trial subscription created successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating trial subscription:', error);
      console.error('Error details:', error.response?.data);
      return { success: false, error: error.response?.data?.error || 'Failed to create trial subscription' };
    }
  },



  getTrialStatus: async (userId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_TRIAL_STATUS.replace(':userId', userId)
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching trial status:', error);
      throw error.response?.data?.error || 'Failed to fetch trial status';
    }
  },

  checkTrialExpiration: async (userId) => {
    try {
      const trialStatus = await authenticateService.getTrialStatus(userId);
      return {
        ...trialStatus,
        isExpired: trialStatus.isTrialing === false && trialStatus.isExpired === true
      };
    } catch (error) {
      console.error('Error checking trial expiration:', error);
      return { isExpired: false, error: error.message };
    }
  },

  getSubscriptionStatus: async (userId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', userId)
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      throw error.response?.data?.error || 'Failed to fetch subscription status';
    }
  }
};

export default authenticateService;