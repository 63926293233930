import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/actions/userActions';
import { ChevronDown, User, LogOut, Settings, Menu, X } from 'lucide-react';
import AuthenticationFlow from '../AuthenticateModal/AuthenticationFlow';
import Analytics from '../../services/analytics'; // Import analytics service

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, tempUserId, role } = useSelector((state) => state.user);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [selectedPlanType, setSelectedPlanType] = useState('weekly'); // Default to weekly
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [userCommunityId, setUserCommunityId] = useState(null);
  
  const isRealUser = userId && !(userId >= 10000 && userId <= 99999);
  

  // Add simplified useEffect hook to set community ID directly
  useEffect(() => {
    // Always set to default community ID 389
    setUserCommunityId(389);
  }, [userId, tempUserId]); // Change dependency to userId and tempUserId

  
  const handleLogout = () => {
    // Track logout event
    Analytics.trackAuthSuccess('logout', {
      userId: userId,
      userType: 'registered',
      source: window.location.pathname
    });
    
    dispatch(logout());
    navigate('/');
    setShowSettingsDropdown(false);
    setShowMobileMenu(false);
  };
  
  const handleAuthClick = (mode, planType = 'weekly') => {
    // Track auth modal opening from header
    Analytics.trackAuthModalOpen('header', {
      initialMode: mode,
      planType: planType,
      currentPath: window.location.pathname,
      isFromTrivia: false
    });
    
    setAuthMode(mode);
    setSelectedPlanType(planType);
    setShowAuthModal(true);
    setShowMobileMenu(false);
  };
  
  const handleDashboardClick = (e) => {
    e.preventDefault();
    setShowMobileMenu(false);
    
    // Track dashboard navigation
    Analytics.trackDashboardView({
      userId: userId,
      userType: 'registered',
      source: 'header',
      navigationMethod: 'direct'
    });
    
    // Force a complete page refresh to reset all state
    if (userCommunityId) {
      window.location.href = `/dashboard/${userCommunityId}`;
    } else {
      window.location.href = '/dashboard';
    }
  };

  // Authentication success handler
  const handleAuthSuccess = (userData) => {
    // Track successful authentication
    Analytics.trackAuthSuccess(
      userData?.isNewUser ? 'register' : 'login', 
      {
        isNewUser: userData?.isNewUser || false,
        userId: userData?.user_id || userData?.userId,
        tempUserId: tempUserId,
        source: 'header',
        conversionPath: window.location.pathname
      }
    );
    
    // Navigate to appropriate page after successful authentication
    if (userCommunityId) {
      navigate(`/user-dashboard/${userCommunityId}`);
    } else {
      navigate('/dashboard');
    }
  };
  
  // Handle authentication mode toggle
  const handleAuthModeToggle = (fromMode, toMode) => {
    // Track auth mode toggle
    Analytics.trackAuthModeToggle(fromMode, toMode);
  };
  
  // Handle authentication failure
  const handleAuthFailure = (method, error) => {
    // Track auth failure
    Analytics.trackAuthFailure(method, {
      errorType: error.type || 'unknown',
      message: error.message || 'Authentication failed',
      source: 'header'
    });
  };
  
  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSettingsDropdown(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    // Track mobile menu toggle
    Analytics.trackAnswerSelection({
      questionType: 'ui_interaction',
      action: showMobileMenu ? 'close_mobile_menu' : 'open_mobile_menu',
      userId: userId || tempUserId,
      userType: isRealUser ? 'registered' : 'anonymous',
      source: 'header'
    });
    
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <header className="bg-white shadow-sm border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-3 sm:px-6">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center space-x-2">
              <Link 
                to="/" 
                className="flex items-center space-x-2"
                onClick={() => {
                  // Track logo click
                  Analytics.trackAnswerSelection({
                    questionType: 'navigation',
                    action: 'logo_click',
                    userId: userId || tempUserId,
                    userType: isRealUser ? 'registered' : 'anonymous',
                    destination: '/'
                  });
                }}
              >
                <img src="/logo.png" alt="Examen.ai Logo" className="h-7 w-auto sm:h-9" />
                <span className="text-lg sm:text-xl font-bold bg-gradient-to-r from-indigo-600 to-indigo-700 bg-clip-text text-transparent whitespace-nowrap">
                  ExamenAI
                </span>
              </Link>
            </div>
            
            {/* Mobile menu button - Updated to toggle the menu */}
            <div className="sm:hidden">
              <button 
                onClick={toggleMobileMenu}
                className="text-gray-500 hover:text-indigo-600 p-2 rounded-md"
                aria-label={showMobileMenu ? "Close menu" : "Open menu"}
              >
                {showMobileMenu ? (
                  <X className="h-5 w-5" />
                ) : (
                  <Menu className="h-5 w-5" />
                )}
              </button>
            </div>
            
            {/* Desktop navigation */}
            <nav className="hidden sm:flex items-center space-x-6">
              {isRealUser ? (
                <>
                  <a 
                    href="/dashboard" 
                    onClick={handleDashboardClick}
                    className="text-gray-600 hover:text-indigo-600 transition-colors text-sm font-medium whitespace-nowrap"
                  >
                    Dashboard
                  </a>
                  {role === 'admin' && (
                    <Link 
                      to="/admin" 
                      className="text-gray-600 hover:text-indigo-600 transition-colors text-sm font-medium whitespace-nowrap"
                      onClick={() => {
                        // Track admin navigation
                        Analytics.trackAnswerSelection({
                          questionType: 'navigation',
                          action: 'admin_click',
                          userId: userId,
                          userType: 'registered',
                          destination: '/admin'
                        });
                      }}
                    >
                      Admin
                    </Link>
                  )}
                  {/* Settings Dropdown */}
                  <div className="relative" ref={dropdownRef}>
                    <button 
                      onClick={() => {
                        // Track settings dropdown toggle
                        Analytics.trackAnswerSelection({
                          questionType: 'ui_interaction',
                          action: showSettingsDropdown ? 'close_settings' : 'open_settings',
                          userId: userId,
                          userType: 'registered'
                        });
                        
                        setShowSettingsDropdown(!showSettingsDropdown);
                      }}
                      className="flex items-center text-gray-600 hover:text-indigo-600 transition-colors text-sm font-medium whitespace-nowrap"
                    >
                      <Settings className="h-4 w-4 mr-1" />
                      Settings
                      <ChevronDown className={`h-4 w-4 ml-1 transition-transform ${showSettingsDropdown ? 'rotate-180' : ''}`} />
                    </button>
                    
                    {showSettingsDropdown && (
                      <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 border border-gray-200">
                        <Link 
                          to="/account" 
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-indigo-600 transition-colors"
                          onClick={() => {
                            // Track account navigation
                            Analytics.trackAnswerSelection({
                              questionType: 'navigation',
                              action: 'account_settings_click',
                              userId: userId,
                              userType: 'registered',
                              destination: '/account'
                            });
                            
                            setShowSettingsDropdown(false);
                          }}
                        >
                          <User className="h-4 w-4 mr-2" />
                          Account
                        </Link>
                        <button 
                          onClick={handleLogout}
                          className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-indigo-600 transition-colors"
                        >
                          <LogOut className="h-4 w-4 mr-2" />
                          Sign Out
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <button
                    onClick={() => handleAuthClick('login')}
                    className="text-gray-600 hover:text-indigo-600 transition-colors text-sm font-medium whitespace-nowrap"
                  >
                    Sign In
                  </button>
                  <button
                    onClick={() => handleAuthClick('register')}
                    className="px-4 py-2 text-sm font-medium rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition-colors whitespace-nowrap"
                  >
                    Register
                  </button>
                </>
              )}
            </nav>
          </div>
        </div>
        
        {/* Mobile menu - Improved with smooth transition */}
        <div 
          ref={mobileMenuRef} 
          className={`sm:hidden bg-white border-t border-gray-200 shadow-md overflow-hidden transition-all duration-300 ease-in-out ${
            showMobileMenu 
              ? 'max-h-96 opacity-100' 
              : 'max-h-0 opacity-0 pointer-events-none'
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            {isRealUser ? (
              <>
                <a 
                  href="/dashboard" 
                  onClick={handleDashboardClick}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                >
                  Dashboard
                </a>
                {role === 'admin' && (
                  <Link 
                    to="/admin" 
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                    onClick={() => {
                      // Track admin navigation from mobile menu
                      Analytics.trackAnswerSelection({
                        questionType: 'navigation',
                        action: 'admin_click_mobile',
                        userId: userId,
                        userType: 'registered',
                        destination: '/admin'
                      });
                      
                      setShowMobileMenu(false);
                    }}
                  >
                    Admin
                  </Link>
                )}
                <Link 
                  to="/account" 
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                  onClick={() => {
                    // Track account navigation from mobile menu
                    Analytics.trackAnswerSelection({
                      questionType: 'navigation',
                      action: 'account_settings_click_mobile',
                      userId: userId,
                      userType: 'registered',
                      destination: '/account'
                    });
                    
                    setShowMobileMenu(false);
                  }}
                >
                  Account
                </Link>
                <button 
                  onClick={handleLogout}
                  className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => handleAuthClick('login')}
                  className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-indigo-600 hover:bg-gray-50"
                >
                  Sign In
                </button>
                <button
                  onClick={() => handleAuthClick('register')}
                  className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Register
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      
      {/* AuthenticationFlow with analytics callbacks */}
      <AuthenticationFlow
        isOpen={showAuthModal}
        onClose={() => {
          // Track auth modal close
          Analytics.trackAuthModalOpen('header_close', {
            action: 'close',
            fromButton: 'close_button',
            authMode: authMode
          });
          
          setShowAuthModal(false);
        }}
        initialMode={authMode}
        onSuccess={handleAuthSuccess}
        onModeToggle={handleAuthModeToggle}
        onAuthFailure={handleAuthFailure}
        planType={selectedPlanType}
        tempUserId={tempUserId}
      />
    </>
  );
};

export default Header;