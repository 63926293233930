// quizService.js
import { API_ENDPOINTS } from "../config/api";


export const initializeWebSocket = async (
  effectiveUserId,
  modeId,
  modeName,
  callbacks,
  isReflection = false // Add this parameter
) => {
  try {
    // Build WebSocket URL with query parameters
    const queryParams = new URLSearchParams();
    if (modeId) queryParams.append('group_id', modeId);
    if (effectiveUserId) queryParams.append('user_id', effectiveUserId);
    if (modeName) queryParams.append('mode', modeName);
    if (isReflection) queryParams.append('is_reflection', 'true'); // Add reflection flag to query params

    const wsUrl = `${API_ENDPOINTS.TEXT_SOCKET}${
      queryParams.toString() ? `?${queryParams.toString()}` : ''
    }`;

    console.log("Attempting WebSocket connection to:", wsUrl);
    const ws = new WebSocket(wsUrl);
    
    // Set up WebSocket event handlers
    ws.onmessage = (event) => {
      console.log("Received WebSocket message:", JSON.parse(event.data));
      callbacks.onMessage(event);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      callbacks.onError(error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      callbacks.onClose();
    };

    ws.onopen = () => {
      console.log("WebSocket connection opened");
      callbacks.onOpen?.();
      
      // Send session initialization message
      const initMessage = {
        type: 'session.initialize',
        data: {
          userId: effectiveUserId,
          groupId: modeId,
          timestamp: new Date().toISOString(),
          isReflection: isReflection // Include reflection flag
        }
      };
      console.log("Sending initialization message:", initMessage);
      ws.send(JSON.stringify(initMessage));

      // Add slight delay before sending begin trivia
      setTimeout(() => {
        // Use different initialization message for reflection vs regular trivia
        const triviaMessage = {
          type: "input_text",
          content: isReflection 
            ? "I am initializing the session. Please provide the first question. This is not an answer submission, provide question number 1 please."
            : "I am initializing the session. Please begin the trivia by providing the first question. This is not an answer submission. Now provide me question number 1 please."
        };
        console.log("Sending begin trivia message:", triviaMessage);
        ws.send(JSON.stringify(triviaMessage));
      }, 500); // 500ms delay
    };

    // Add connection timeout
    const connectionTimeout = setTimeout(() => {
      if (ws.readyState !== WebSocket.OPEN) {
        console.error('WebSocket connection timeout');
        ws.close();
        callbacks.onError?.('Connection timeout - please try again');
      }
    }, 10000);

    ws.addEventListener('open', () => {
      clearTimeout(connectionTimeout);
    });

    return ws;
  } catch (err) {
    console.error('WebSocket initialization error:', err);
    throw err;
  }
};


// Last-resort iterative trimming fallback function
const iterativeTrimParse = (str) => {
  let candidate = str.trim();
  while (candidate.length > 0) {
    try {
      return JSON.parse(candidate);
    } catch (err) {
      // Remove one trailing character and try again
      candidate = candidate.slice(0, -1).trim();
    }
  }
  return null;
};

const tryParseJson = (str) => {
  if (!str) return null;
  
  if (typeof str === 'object') return str;
  
  try {
    const firstParse = JSON.parse(str);
    if (typeof firstParse === 'string') {
      try {
        if (firstParse.includes('interaction_type')) {
          const startBrace = firstParse.indexOf('{');
          const endBrace = firstParse.lastIndexOf('}');
          if (startBrace !== -1 && endBrace !== -1) {
            const cleanJson = firstParse.slice(startBrace, endBrace + 1);
            return JSON.parse(cleanJson);
          }
        }
        if (firstParse.startsWith('{') || firstParse.startsWith('[')) {
          const lastChar = firstParse.startsWith('{') ? '}' : ']';
          const lastBrace = firstParse.lastIndexOf(lastChar);
          if (lastBrace > -1) {
            const validJson = firstParse.substring(0, lastBrace + 1);
            return JSON.parse(validJson);
          }
          return JSON.parse(firstParse);
        }
      } catch (parseError) {
        return firstParse;
      }
    }
    return firstParse;
  } catch (e) {
    // NEW CHECK: Remove extra trailing braces until the counts are balanced.
    if (typeof str === 'string') {
      let candidate = str.trim();
      // Count the number of "{" and "}"
      const countBraces = (s, char) => (s.match(new RegExp(`\\${char}`, 'g')) || []).length;
      while (countBraces(candidate, '{') < countBraces(candidate, '}')) {
        candidate = candidate.replace(/}$/, '').trim();
      }
      try {
        return JSON.parse(candidate);
      } catch (braceTrimError) {
        // If still fails, continue to next fallback.
      }
    }
    
    // NEW CHECK 2: Use a regex to collapse extra trailing closing braces.
    if (typeof str === 'string') {
      // This regex replaces one or more closing braces at the end with a single "}"
      let candidate = str.trim().replace(/(})+$/, '}');
      try {
        return JSON.parse(candidate);
      } catch (regexTrimError) {
        // Continue to next fallback if regex trimming fails.
      }
    }
    
    // Specific check: if the string ends with an extra "}", remove it and try again.
    if (typeof str === 'string' && str.endsWith("}}") && !str.endsWith("}}}")) {
      const candidate = str.slice(0, -1);
      try {
        return JSON.parse(candidate);
      } catch (trimError) {
        // Continue to next fallback if this still fails.
      }
    }
    
    // EXTRA FALLBACK: Use regex to extract a JSON substring.
    const regexMatch = str.match(/({[\s\S]*})/);
    if (regexMatch && regexMatch[1]) {
      try {
        return JSON.parse(regexMatch[1]);
      } catch (regexError) {
        // Continue to next fallback.
      }
    }
    
    try {
      const cleaned = str
        .trim()
        .replace(/^["']+|["']+$/g, '')
        .replace(/\\\"/g, '"')
        .replace(/}{2,}/g, '}');
      const cleanedResult = JSON.parse(cleaned);
      if (cleanedResult) return cleanedResult;
      return tryParseJsonFallback(str);
    } catch (e2) {
      try {
        if (typeof str === 'string') {
          if (str.includes('interaction_type')) {
            const startBrace = str.indexOf('{');
            const endBrace = str.lastIndexOf('}');
            if (startBrace !== -1 && endBrace !== -1) {
              const validJson = str.substring(startBrace, endBrace + 1);
              const result = JSON.parse(validJson);
              if (result) return result;
            }
          }
          const lastBrace = str.lastIndexOf('}');
          if (lastBrace > -1) {
            const firstBrace = str.indexOf('{');
            if (firstBrace !== -1) {
              const validJson = str.substring(firstBrace, lastBrace + 1);
              const result = JSON.parse(validJson);
              if (result) return result;
            }
          }
        }
        return tryParseJsonFallback(str);
      } catch (e3) {
        return iterativeTrimParse(str);
      }
    }
  }
};

// Fallback function for special cases
const tryParseJsonFallback = (str) => {
  try {
    // Handle case where str is already an object
    if (typeof str === 'object') {
      return str;
    }
    
    // Handle websocket trivia messages first
    if (typeof str === 'string' && str.includes('interaction_type')) {
      const startBrace = str.indexOf('{');
      const endBrace = str.lastIndexOf('}');
      if (startBrace !== -1 && endBrace !== -1) {
        const cleanJson = str.slice(startBrace, endBrace + 1);
        return JSON.parse(cleanJson);
      }
    }
    
    // Special handling for escaped characters
    const preprocessed = str
      .replace(/\\'/g, "'")
      .replace(/\\"/g, '"')
      .replace(/\\&/g, "&")
      .replace(/\\r/g, '\r')
      .replace(/\\n/g, '\n')
      .replace(/\\t/g, '\t')
      .replace(/\\\\/g, '\\');
    
    // Handle double-encoded JSON strings
    const parsed = JSON.parse(preprocessed);
    if (typeof parsed === 'string' && (parsed.startsWith('{') || parsed.startsWith('['))) {
      const lastChar = parsed.startsWith('{') ? '}' : ']';
      const lastBrace = parsed.lastIndexOf(lastChar);
      if (lastBrace > -1) {
        const validJson = parsed.substring(0, lastBrace + 1);
        return JSON.parse(validJson);
      }
      return JSON.parse(parsed);
    }
    
    return parsed;
  } catch (e) {
    throw e; // Let the main function handle the error logging
  }
};


export const handleWebSocketMessage = (event, {
  setCurrentQuestion,
  setSelectedOption,
  setAnswerResponse,
  setTriviaProgress,
  setLoading,
  setError,
  setInitialLoad,
  setIsNextLoading,
  setReflectionLoading 
}) => {
  try {
    console.log('=== WebSocket Message Handler Start ===');
    console.log('Raw event data:', event.data);
    
    const data = JSON.parse(event.data);
    console.log('Parsed WebSocket data:', data);
    console.log('Message type:', data.type);

    // Handle completed function calls in output_item.done messages
    if (data.type === 'response.output_item.done' && 
        data.item?.type === 'function_call' && 
        data.item?.arguments) {
      try {
        const args = tryParseJson(data.item.arguments);
        if (!args) {
          console.error('Failed to parse function arguments');
          console.log('Setting error notification for failed parse');
          setLoading?.(false);
          event.target.hasActiveResponse = false;
          return;
        }
        if (args?.interaction_type === 'trivia_response') {
          console.log('Processing trivia response from output item:', args);
          handleTriviaResponse(args.trivia_response, {
            setAnswerResponse,
            setTriviaProgress,
            setCurrentQuestion,
            setSelectedOption,
            setLoading,
            event
          });
          return;
        }
      } catch (parseErr) {
        console.error('Output item parsing error:', parseErr);
        console.log('Setting error notification for parsing error');
        setLoading?.(false);
        event.target.hasActiveResponse = false;
        return;
      }
    }
    
    // Early checks for streaming events
    if (data.type === 'response.text.delta' || 
        data.type === 'response.text.done' ||
        data.type === 'response.content_part.done' ||
        (data.type === 'response.output_item.done' && data.item?.type !== 'function_call') || 
        data.type === 'response.output_item.added') {
      console.log('Ignoring text/output streaming event');
      return;
    }

    // Track active response state with more robust error handling
    if (data.type === 'response.created') {
      event.target.hasActiveResponse = true;
      console.log('Response started, setting activeResponse to true');
    } else if (data.type === 'response.done' || data.type === 'error') {
      event.target.hasActiveResponse = false;
      console.log('Response completed or error occurred, setting activeResponse to false');
      setLoading?.(false);
    }
    
    // Debug available handlers
    console.log('Handler availability:', {
      hasSetCurrentQuestion: !!setCurrentQuestion,
      hasSetSelectedOption: !!setSelectedOption,
      hasSetAnswerResponse: !!setAnswerResponse,
      hasSetTriviaProgress: !!setTriviaProgress,
      hasSetLoading: !!setLoading,
      hasSetError: !!setError
    });

    switch (data.type) {
      case 'session.created':
      case 'session.updated':
        console.log('Session event received:', {
          type: data.type,
          sessionData: data.session || 'No session data'
        });
        break;

      case 'response.function_call_arguments.delta':
        // Safely ignore delta updates
        console.log('Function call delta received:', data.delta);
        break;

      case 'response.function_call_arguments.done':
        try {
          console.log('Function call received:', {
            rawArguments: data.arguments,
            callId: data.call_id
          });

          const args = tryParseJson(data.arguments);
          if (!args) {
            console.error('Failed to parse function arguments');
            console.log('Setting error notification for parse failure');
            setLoading?.(false);
            event.target.hasActiveResponse = false;
            return;
          }

          console.log('Successfully parsed arguments:', args);
          console.log('Interaction type:', args.interaction_type);


          if (args.interaction_type === 'trivia_question') {
            console.log('Processing trivia question:', args.trivia_content);
            const { trivia_content } = args;
            
            // Set initial progress stats from question data
            if (setTriviaProgress) {
              setTriviaProgress(prev => ({
                ...prev,
                total_questions: trivia_content.total_questions
              }));
            }
          



            console.log('Question data to set:', {
              question_text: trivia_content.question_text,
              sourceText: trivia_content.source_text,
              hint: trivia_content.hint,
              questionType: trivia_content.question_type,
              topic: trivia_content.topic,
              difficulty: trivia_content.difficulty,
              hasOptions: !!trivia_content.options
            });
            
            if (setCurrentQuestion) {
              setCurrentQuestion({
                question_text: trivia_content.question_text,
                sourceText: trivia_content.source_text,
                hint: trivia_content.hint,
                questionType: trivia_content.question_type,
                topic: trivia_content.topic,
                difficulty: trivia_content.difficulty,
                options: trivia_content.options || [],
                isReflection: trivia_content.is_reflection || false,
                reflectionPrompt: trivia_content.reflection_prompt || null                
              });
            
              if (setInitialLoad) {
                setInitialLoad(prev => prev ? false : prev); 
              }

              setSelectedOption && setSelectedOption(null);
              setAnswerResponse && setAnswerResponse(null);
              setLoading && setLoading(false);
              setIsNextLoading && setIsNextLoading(false);
            }
          
          } else if (args.interaction_type === 'trivia_response') {
            handleTriviaResponse(args.trivia_response, {
              setAnswerResponse,
              setTriviaProgress,
              setCurrentQuestion,
              setSelectedOption,
              setLoading,
              setReflectionLoading,
              event
            });

          }
        } catch (err) {
          console.error('Function call parsing error:', err);
          console.error('Error details:', {
            message: err.message,
            stack: err.stack,
            rawArguments: data.arguments,
            position: err instanceof SyntaxError ? err.message.match(/position (\d+)/)?.[1] : null
          });
          
          // Always show error notification for any parsing related errors
          console.log('Setting error notification for parsing error');
          
          setLoading && setLoading(false);
          event.target.hasActiveResponse = false;
        }
        break;

      case 'error':
        console.error('WebSocket API error:', {
          error: data.error,
          message: data.error?.message,
          details: data.error?.details || 'No additional details'
        });
        setError && setError(data.error?.message || 'An error occurred during the quiz');
        setLoading && setLoading(false);
        event.target.hasActiveResponse = false;
        break;

      default:
        console.log('Unhandled message type:', data.type);
        break;
    }
  } catch (err) {
    console.error('WebSocket message handling error:', {
      name: err.name,
      message: err.message,
      stack: err.stack,
      data: event?.data
    });

    // Always show error notification for JSON parsing errors
    console.log('Setting error notification for JSON error');
    
    if (setLoading) {
      setLoading(false);
    }
    event.target.hasActiveResponse = false;
  } finally {
    console.log('=== WebSocket Message Handler End ===');
  }
};

// Helper function to handle trivia responses
const handleTriviaResponse = (trivia_response, {
  setAnswerResponse,
  setTriviaProgress,
  setCurrentQuestion,
  setSelectedOption,
  setLoading,
  setReflectionLoading,
  event
}) => {
  console.log('Processing trivia response:', trivia_response);
  
  // Create a working copy
  let safeResponse = {...trivia_response};
  
  // Check if progress_stats is missing but might exist in original data
  if (!safeResponse.progress_stats || typeof safeResponse.progress_stats !== 'object') {
    console.log('progress_stats missing, attempting targeted extraction');
    
    try {
      // Try to get raw data from event if available
      let rawData = null;
      if (event && event.data) {
        rawData = event.data;
      } else if (event && event.target && event.target._lastMessage) {
        rawData = event.target._lastMessage;
      }
      
      if (rawData) {
        // Try to find and extract just the progress_stats object
        if (typeof rawData === 'string') {
          const progressStatsMatch = rawData.match(/"progress_stats"\s*:\s*({[^}]*})/);
          if (progressStatsMatch && progressStatsMatch[1]) {
            try {
              // Try to parse just the progress_stats portion
              const fixedJson = progressStatsMatch[1]
                .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)\s*:/g, '$1"$2":') // Ensure property names are quoted
                .replace(/'/g, '"'); // Replace single quotes with double quotes
              
              const extractedStats = JSON.parse(fixedJson);
              console.log('Extracted progress_stats:', extractedStats);
              safeResponse.progress_stats = extractedStats;
            } catch (parseErr) {
              console.error('Failed to parse extracted progress_stats:', parseErr);
            }
          }
        }
      }
    } catch (extractionErr) {
      console.error('Error during targeted extraction:', extractionErr);
    }
    
    // If extraction failed, use default values
    if (!safeResponse.progress_stats || typeof safeResponse.progress_stats !== 'object') {
      console.log('Creating default progress_stats as fallback');
      safeResponse.progress_stats = {
        total_questions: safeResponse.total_questions || 4,
        current_question: safeResponse.question_number || 0,
        total_points: 0,
        correct_answers: 0,
        is_session_complete: false,
        max_difficulty_reached: safeResponse.difficulty || 'advanced',
        session_summary: 'skip'
      };
    }
  }
  
  // Store the life applications from this response in the WebSocket connection for future reference
  const currentLifeApplications = safeResponse.life_applications || null;
  if (event && event.target && currentLifeApplications) {
    event.target.currentLifeApplications = currentLifeApplications;
    console.log('Stored current life applications in connection:', currentLifeApplications);
  }
  
  // Handle session completion - modified to not auto-forward
  if (safeResponse.progress_stats.is_session_complete) {
    console.log('Session complete, showing final answer without auto-forwarding');
    
    if (setAnswerResponse) {
      setAnswerResponse({
        isCorrect: safeResponse.is_correct,
        correct_answer: safeResponse.correct_answer,
        teaching_text: safeResponse.teaching_text,
        explanation: safeResponse.explanation,
        score: safeResponse.score,
        scoringBreakdown: safeResponse.scoring_breakdown,
        topic: safeResponse.topic,
        difficulty: safeResponse.difficulty,
        encouragement: safeResponse.encouragement || null,
        spiritualContext: safeResponse.spiritual_context || null,
        isReflection: safeResponse.is_reflection || false,
        isFinalQuestion: true, // Add flag to indicate this is the final question
        lifeApplications: currentLifeApplications
      });
    }
    
    // Update everything EXCEPT is_session_complete
    if (setTriviaProgress) {
      setTriviaProgress(prev => ({
        ...prev,
        total_questions: safeResponse.progress_stats.total_questions,
        current_question: safeResponse.progress_stats.current_question,
        total_points: safeResponse.progress_stats.total_points,
        correct_answers: safeResponse.progress_stats.correct_answers || 0,
        max_difficulty_reached: safeResponse.progress_stats.max_difficulty_reached,
        session_summary: safeResponse.progress_stats.session_summary,
        current_topic: safeResponse.topic,
        current_difficulty: safeResponse.difficulty,
        currentLifeApplications: currentLifeApplications // Store life applications in triviaProgress
        // is_session_complete remains unchanged
      }));
    }
    
    setLoading && setLoading(false);
    setReflectionLoading && setReflectionLoading(false);
    event.target.hasActiveResponse = false;
    return;
  }
  
  // Regular response processing
  if (setAnswerResponse) {
    setAnswerResponse({
      isCorrect: safeResponse.is_correct,
      correct_answer: safeResponse.correct_answer,
      explanation: safeResponse.explanation,
      teaching_text: safeResponse.teaching_text,
      score: safeResponse.score,
      scoringBreakdown: safeResponse.scoring_breakdown,
      topic: safeResponse.topic,
      difficulty: safeResponse.difficulty,
      encouragement: safeResponse.encouragement || null,
      spiritualContext: safeResponse.spiritual_context || null,
      isReflection: safeResponse.is_reflection || false,
      isFinalQuestion: false, // Ensure this is false for non-final questions
      lifeApplications: currentLifeApplications // Add this line to include life applications
    });
  }
  
  if (setTriviaProgress) {
    setTriviaProgress({
      total_questions: safeResponse.progress_stats.total_questions,
      current_question: safeResponse.progress_stats.current_question,
      total_points: safeResponse.progress_stats.total_points,
      is_session_complete: safeResponse.progress_stats.is_session_complete,
      max_difficulty_reached: safeResponse.progress_stats.max_difficulty_reached,
      session_summary: safeResponse.progress_stats.session_summary,
      current_topic: safeResponse.topic,
      current_difficulty: safeResponse.difficulty,
      correct_answers: safeResponse.progress_stats.correct_answers || 0,
      currentLifeApplications: currentLifeApplications // Store life applications in triviaProgress
    });
  }
  
  setLoading && setLoading(false);
  setReflectionLoading && setReflectionLoading(false);
  event.target.hasActiveResponse = false;
};

export const handleOptionSelect = (option, wsConnection, setLoading) => {
  console.log('handleOptionSelect called:', {
    option,
    wsState: wsConnection?.readyState,
    hasActiveResponse: wsConnection?.hasActiveResponse
  });

  // Validate option
  if (!option || typeof option !== 'string') {
    console.error('Invalid option provided to handleOptionSelect');
    return;
  }

  if (wsConnection?.readyState === WebSocket.OPEN) {
    // Check for active response and queue if needed
    if (wsConnection.hasActiveResponse) {
      console.log('Active response in progress, queuing option selection');
      setTimeout(() => {
        handleOptionSelect(option, wsConnection, setLoading);
      }, 1000);
      return;
    }
    

    const message = {
      type: "input_text",
      content: option
    };

    if (wsConnection?.readyState === WebSocket.OPEN && !wsConnection.hasActiveResponse) {
      // Immediately set loading state to true to trigger loading UI
      setLoading?.(true);

      try {
        console.log('Attempting to send message:', message);
        wsConnection.send(JSON.stringify(message));
        wsConnection.hasActiveResponse = true;
        console.log('Message sent successfully');
      } catch (err) {
        console.error('Error sending message:', err);
        wsConnection.hasActiveResponse = false;
        setLoading?.(false);

        // Retry once after a short delay if there was an error
        setTimeout(() => {
          try {
            wsConnection.send(JSON.stringify(message));
            wsConnection.hasActiveResponse = true;
            setLoading?.(true);
          } catch (retryErr) {
            console.error('Retry failed:', retryErr);
            wsConnection.hasActiveResponse = false;
            setLoading?.(false);
          }
        }, 500);
      }
    } else {
      console.log('Cannot send message - connection not ready or response in progress');
      setTimeout(() => {
        handleOptionSelect(option, wsConnection, setLoading);
      }, 1000);
    }
  } else {
    console.error('WebSocket not in OPEN state:', {
      readyState: wsConnection?.readyState,
      connectionState: wsConnection ? 'exists' : 'null'
    });
    
    // Optional: Queue the message to retry when connection is ready
    if (wsConnection) {
      setTimeout(() => {
        handleOptionSelect(option, wsConnection, setLoading);
      }, 1000);
    }
  }
};


// Add this utility function at the top of quizService.js
const getNextDifficulty = (currentStats) => {
  const { lastDifficulty, counts, totalQuestions } = currentStats;
  const targetPerDifficulty = Math.floor(totalQuestions / 3);
  
  // Filter out difficulties that have reached their target count
  const availableDifficulties = ['foundational', 'enriching', 'advanced'].filter(diff => 
    counts[diff] < (diff === 'advanced' ? Math.ceil(totalQuestions / 3) : targetPerDifficulty)
  );
  
  // If last difficulty exists, remove it from options to prevent repetition
  const validOptions = availableDifficulties.filter(diff => diff !== lastDifficulty);
  
  // If no valid options (shouldn't happen), reset and use all difficulties
  if (validOptions.length === 0) {
    return availableDifficulties[0] || 'advanced';
  }
  
  // Randomly select from valid options
  return validOptions[Math.floor(Math.random() * validOptions.length)];
};


export const handleNextQuestion = (wsConnection, setLoading, difficulty = 'advanced', triviaProgress = null, isReflection = false) => {
  setLoading?.(true); // Immediately show loading indicator

  // Extract the current topic if available
  const currentTopic = triviaProgress?.current_topic || '';
  
  // Get current life applications from either triviaProgress or the WebSocket connection
  const currentLifeApplications = triviaProgress?.currentLifeApplications || wsConnection?.currentLifeApplications || '';
  
  // Get the last selected life application if any
  const lastSelectedApplication = wsConnection?.lastSelectedApplication || '';
  
  // Base topic reminder
  const topicReminder = currentTopic ? `Remember this question should focus on the topic "${currentTopic}". ` : '';
  
  // Life application reminder - add in parentheses
  let applicationNote = '';
  if (lastSelectedApplication) {
    // If a life application was previously selected, add a note to provide more specific situations
    applicationNote = `(Please provide three more specific personal situations related to the "${lastSelectedApplication}" life application) `;
  } else if (currentLifeApplications) {
    // If no application was selected but we have current applications, list them
    applicationNote = `(Current life applications: ${currentLifeApplications}) `;
  }
  
  // Combined reminder
  const fullReminder = topicReminder + applicationNote;

  if (wsConnection?.readyState === WebSocket.OPEN) {
    // Check for active response
    if (wsConnection.hasActiveResponse) {
      console.log('Active response in progress, queuing next question');
      setLoading?.(true);
      setTimeout(() => {
        handleNextQuestion(wsConnection, setLoading, difficulty, triviaProgress, isReflection);
      }, 1000);
      return;
    }

    // Calculate next question number - add 1 to current_question to get next number
    const nextQuestionNumber = (triviaProgress?.current_question ?? 0) + 1;
    
    const progressSummary = triviaProgress ? 
     `You have correctly answered ${triviaProgress.correct_answers} out of ${triviaProgress.current_question} questions for a total of ${triviaProgress.total_points} points so far.` : 
     '';
    
    // Reflection-specific prompt
    if (isReflection) {
      const reflectionPrompt = `Now provide me question number ${nextQuestionNumber} please (out of a total of 2 questions). ${fullReminder}Remember to use the store_trivia_question function and include a meaningful reflection_prompt that invites personal application of faith to the user's life situations. You must only communicate through function calls - specifically store_trivia_question. Now provide me question number ${nextQuestionNumber} please.`;

      if (wsConnection?.readyState === WebSocket.OPEN && !wsConnection.hasActiveResponse) {
        try {
          wsConnection.send(JSON.stringify({
            type: "input_text",
            content: reflectionPrompt
          }));
          wsConnection.hasActiveResponse = true;
          setLoading?.(true);
        } catch (err) {
          console.error('Error sending next reflection:', err);
          wsConnection.hasActiveResponse = false;
          setLoading?.(false);
          
          // Retry once after a short delay
          setTimeout(() => {
            if (!wsConnection.hasActiveResponse) {
              try {
                wsConnection.send(JSON.stringify({
                  type: "input_text",
                  content: reflectionPrompt
                }));
                wsConnection.hasActiveResponse = true;
                setLoading?.(true);
              } catch (retryErr) {
                console.error('Retry failed:', retryErr);
                wsConnection.hasActiveResponse = false;
                setLoading?.(false);
              }
            }
          }, 500);
        }
      } else {
        console.log('Cannot send next reflection - connection not ready or response in progress');
        setTimeout(() => {
          handleNextQuestion(wsConnection, setLoading, difficulty, triviaProgress, isReflection);
        }, 1000);
      }
      return;
    }
     
    const nextQuestionPrompts = {          

      'advanced': `Now provide me question number ${nextQuestionNumber} please. ${progressSummary} ${fullReminder}
        SPIRITUAL REFLECTION MANDATE: ALL answer options MUST represent legitimate Catholic positions that any parish priest would recognize as valid orthodox teaching. Use clear, everyday language that focuses on extracting practical spiritual wisdom rather than technical theological terminology. The challenge should come from the depth of spiritual insight required, not from difficult vocabulary.
        
        THEOLOGICAL COMPLEXITY IN LIFE APPLICATIONS: When presenting options related to life situations, create morally complex scenarios where multiple legitimate Catholic values appear to be in tension (such as prioritizing religious obligations vs. immediate neighbor needs, balancing justice with mercy, or weighing individual spiritual growth against communal responsibilities). Each option should present a theologically valid approach that a faithful Catholic might reasonably hold, with only subtle distinctions in emphasis or priority determining which is most aligned with the source text.
        
        ACCESSIBILITY WITH MEANING: Create questions that use straightforward language similar to what would be heard in a Sunday homily. All options should sound valid and spiritually insightful, with thoughtful distinctions in spiritual emphasis separating them. This creates meaningful reflection opportunities while keeping language accessible to anyone in the pews.
        
        ANSWER ROTATION REQUIREMENT: The correct answer must systematically rotate between A, B, C, and D with even distribution (25% each). NEVER use the same letter as the correct answer in consecutive questions. Track which letter was used for the previous question's correct answer and deliberately choose a different letter for the next question. Option D has been significantly underrepresented and must be included in this rotation.
        
        PATTERN AVOIDANCE: The correct answer must NEVER follow a predictable pattern in terms of length, language complexity, or position. Randomize these aspects completely. Deliberately vary whether the correct answer is the shortest, longest, or middle-length option. Users should never be able to identify the correct answer without genuine spiritual understanding.
        
        SOURCE-BASED DISCERNMENT: Questions 1-2 use Scripture as source text related to the topic. Questions 3-4 use Church Fathers or Saints' writings related to the topic. This pattern ensures balanced spiritual presentation across authoritative sources.
        
        LIFE APPLICATION FOCUS: Every option must represent a position that is valid Catholic teaching, expressed in clear, everyday language - the distinction being alignment with the source text's specific spiritual message. This ensures questions invite thoughtful reflection on how the teaching applies to real-life situations and prayerful discernment expressed in accessible language.
        
        CRITICAL REQUIREMENT: ALL multiple choice options MUST be theologically sound Catholic positions with the correct answer distinguished by alignment with the source text—never by complexity of language, length, or predictable patterns—ensuring answers rotate systematically (especially using option D frequently) to create spiritually meaningful questions that test faith understanding rather than pattern recognition. MAKE SURE TO OFTEN INCLUDE D AS A CORRECT ANSWER! 
        
        Now provide me question number ${nextQuestionNumber} please.
      `,
    
      'enriching': `Now provide me question number ${nextQuestionNumber} please. ${progressSummary} ${fullReminder}
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        All answer options must be theologically sound Catholic teachings with subtle differences that require significant theological formation to discern. The questions should be substantially more challenging than typical Catholic trivia and demand thoughtful theological analysis.
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`,

      'foundational': `Now provide me question number ${nextQuestionNumber} please. ${progressSummary} ${fullReminder}
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        All answer options must be theologically sound Catholic teachings with meaningful differences that require solid theological knowledge to discern. The questions should be genuinely challenging even for regularly practicing Catholics with good formation.
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`,

      'mixed': `Now provide me question number ${nextQuestionNumber} please. ${progressSummary} ${fullReminder}
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        Match difficulty appropriately: foundational (solid theological knowledge required), enriching (significant theological formation necessary), advanced (sophisticated theological synthesis across multiple domains needed).
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`
    };


    // Mixed difficulty handling (existing code for regular questions)
    if (difficulty === 'mixed') {
      // Initialize difficulty tracker if needed
      if (!wsConnection.difficultyTracker) {
        wsConnection.difficultyTracker = {
          lastDifficulty: null,
          counts: { foundational: 0, enriching: 0, advanced: 0 },
          totalQuestions: triviaProgress?.total_questions || 0
        };
      }

      // Update counts based on last question if available
      if (triviaProgress?.current_difficulty) {
        wsConnection.difficultyTracker.counts[triviaProgress.current_difficulty]++;
        wsConnection.difficultyTracker.lastDifficulty = triviaProgress.current_difficulty;
      }

      // Get next difficulty
      const nextDifficulty = getNextDifficulty(wsConnection.difficultyTracker);
      
      console.log('Mixed Difficulty Stats:', {
        nextQuestionNumber,
        lastDifficulty: wsConnection.difficultyTracker.lastDifficulty,
        currentCounts: wsConnection.difficultyTracker.counts,
        selectedDifficulty: nextDifficulty
      });

      if (wsConnection?.readyState === WebSocket.OPEN && !wsConnection.hasActiveResponse) {
        try {
          wsConnection.send(JSON.stringify({
            type: "input_text",
            content: nextQuestionPrompts[nextDifficulty]
          }));
          wsConnection.hasActiveResponse = true;
          setLoading?.(true);
        } catch (err) {
          console.error('Error sending next question:', err);
          wsConnection.hasActiveResponse = false;
          setLoading?.(false);
          
          // Retry once after a short delay
          setTimeout(() => {
            if (!wsConnection.hasActiveResponse) {
              try {
                wsConnection.send(JSON.stringify({
                  type: "input_text",
                  content: nextQuestionPrompts[nextDifficulty]
                }));
                wsConnection.hasActiveResponse = true;
                setLoading?.(true);
              } catch (retryErr) {
                console.error('Retry failed:', retryErr);
                wsConnection.hasActiveResponse = false;
                setLoading?.(false);
              }
            }
          }, 500);
        }
      } else {
        console.log('Cannot send next question - connection not ready or response in progress');
        setTimeout(() => {
          handleNextQuestion(wsConnection, setLoading, difficulty, triviaProgress, isReflection);
        }, 1000);
      }
    } else {
      // Normal single-difficulty mode
      if (wsConnection?.readyState === WebSocket.OPEN && !wsConnection.hasActiveResponse) {
        try {
          wsConnection.send(JSON.stringify({
            type: "input_text",
            content: nextQuestionPrompts[difficulty] || nextQuestionPrompts['advanced']
          }));
          wsConnection.hasActiveResponse = true;
          setLoading?.(true);
        } catch (err) {
          console.error('Error sending next question:', err);
          wsConnection.hasActiveResponse = false;
          setLoading?.(false);
          
          // Retry once after a short delay
          setTimeout(() => {
            if (!wsConnection.hasActiveResponse) {
              try {
                wsConnection.send(JSON.stringify({
                  type: "input_text",
                  content: nextQuestionPrompts[difficulty] || nextQuestionPrompts['advanced']
                }));
                wsConnection.hasActiveResponse = true;
                setLoading?.(true);
              } catch (retryErr) {
                console.error('Retry failed:', retryErr);
                wsConnection.hasActiveResponse = false;
                setLoading?.(false);
              }
            }
          }, 500);
        }
      } else {
        console.log('Cannot send next question - connection not ready or response in progress');
        setTimeout(() => {
          handleNextQuestion(wsConnection, setLoading, difficulty, triviaProgress, isReflection);
        }, 1000);
      }
    }
  } else {
    console.error('WebSocket is not in OPEN state');
    setTimeout(() => {
      handleNextQuestion(wsConnection, setLoading, difficulty, triviaProgress, isReflection);
    }, 1000);
  }
};


export const handleLifeApplicationSelection = (application, wsConnection, setLoading, triviaProgress, difficulty = 'advanced') => {
  setLoading?.(true);

  // Store the selected life application in the WebSocket connection for future reference
  if (wsConnection) {
    wsConnection.lastSelectedApplication = application;
    console.log(`Stored selected life application "${application}" for next question`);
  }

  if (wsConnection?.readyState === WebSocket.OPEN) {
    // Check for active response
    if (wsConnection.hasActiveResponse) {
      console.log('Active response in progress, queuing personalized question');
      setTimeout(() => {
        handleLifeApplicationSelection(application, wsConnection, setLoading, triviaProgress, difficulty);
      }, 1000);
      return;
    }

    // Calculate next question number
    const nextQuestionNumber = (triviaProgress?.current_question ?? 0) + 1;
    
    // Get the current topic if available
    const currentTopic = triviaProgress?.current_topic || '';
    const topicReminder = currentTopic ? `Remember this question should focus on the topic "${currentTopic}". ` : '';
    
    const nextQuestionPrompts = {                

      'advanced': `Now provide me question number ${nextQuestionNumber} please. 
        ${topicReminder}(The user selected "${application}" life situation - please provide three more specific personal applications related to this situation in your response)
        
        SPIRITUAL REFLECTION MANDATE: ALL answer options MUST represent legitimate Catholic positions that any parish priest would recognize as valid orthodox teaching. Use clear, everyday language that focuses on extracting practical spiritual wisdom rather than technical theological terminology. The challenge should come from the depth of spiritual insight required, not from difficult vocabulary.
        
        THEOLOGICAL COMPLEXITY IN LIFE APPLICATIONS: Create options that present morally complex scenarios specifically related to "${application}" where multiple legitimate Catholic values appear to be in tension. Each option should present a theologically valid approach that a faithful Catholic might reasonably hold when navigating this specific life situation, with only subtle distinctions in emphasis or priority determining which is most aligned with the source text.
        
        ACCESSIBILITY WITH MEANING: Create questions that use straightforward language similar to what would be heard in a Sunday homily. All options should sound valid and spiritually insightful, with thoughtful distinctions in spiritual emphasis separating them. This creates meaningful reflection opportunities while keeping language accessible to anyone in the pews.
        
        ANSWER ROTATION REQUIREMENT: The correct answer must systematically rotate between A, B, C, and D with even distribution (25% each). NEVER use the same letter as the correct answer in consecutive questions. Track which letter was used for the previous question's correct answer and deliberately choose a different letter for the next question. Option D has been significantly underrepresented and must be included in this rotation.
        
        PATTERN AVOIDANCE: The correct answer must NEVER follow a predictable pattern in terms of length, language complexity, or position. Randomize these aspects completely. Deliberately vary whether the correct answer is the shortest, longest, or middle-length option. Users should never be able to identify the correct answer without genuine spiritual understanding.
        
        SOURCE-BASED DISCERNMENT: Questions 1-2 use Scripture as source text related to the topic. Questions 3-4 use Church Fathers or Saints' writings related to the topic. This pattern ensures balanced spiritual presentation across authoritative sources.
        
        LIFE APPLICATION FOCUS: Every option must represent a position that is valid Catholic teaching, expressed in clear, everyday language - the distinction being alignment with the source text's specific spiritual message. This ensures questions invite thoughtful reflection on how the teaching applies to real-life situations and prayerful discernment expressed in accessible language.
        
        CRITICAL REQUIREMENT: ALL multiple choice options MUST be theologically sound Catholic positions with the correct answer distinguished by alignment with the source text—never by complexity of language, length, or predictable patterns—ensuring answers rotate systematically (especially using option D frequently) to create spiritually meaningful questions that test faith understanding rather than pattern recognition. MAKE SURE TO OFTEN INCLUDE D AS A CORRECT ANSWER! 
        
        Now provide me question number ${nextQuestionNumber} please.
      `,

      'enriching': `Now provide me question number ${nextQuestionNumber} please. 
        ${topicReminder}(The user selected "${application}" life situation - please provide three more specific personal applications related to this situation in your response)
        
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        All answer options must be theologically sound Catholic teachings with subtle differences that require significant theological formation to discern. The questions should be substantially more challenging than typical Catholic trivia and demand thoughtful theological analysis.
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`,

      'foundational': `Now provide me question number ${nextQuestionNumber} please. 
        ${topicReminder}(The user selected "${application}" life situation - please provide three more specific personal applications related to this situation in your response)
        
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        All answer options must be theologically sound Catholic teachings with meaningful differences that require solid theological knowledge to discern. The questions should be genuinely challenging even for regularly practicing Catholics with good formation.
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`,

      'mixed': `Now provide me question number ${nextQuestionNumber} please. 
        ${topicReminder}(The user selected "${application}" life situation - please provide three more specific personal applications related to this situation in your response)
        
        IMPORTANT: Thoroughly randomize the correct answer to prevent pattern detection. The correct answer should vary in length (sometimes shortest, sometimes longest, sometimes middle), position (evenly distribute between A/B/C/D without consecutive repeats), and language style (sometimes simpler, sometimes more technical). Make the correct answer length vary strategically: frequently make it the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest option.
        
        For this question specifically, ${nextQuestionNumber % 2 === 0 ? "use an interpretation from a Saint, Pope, Theologian, Author, Catechism of the Catholic Church, Vatican Council teaching, or papal encyclical about the Gospel's message" : "use direct text from the Gospel reading for critical theological analysis"}. 
        
        Match difficulty appropriately: foundational (solid theological knowledge required), enriching (significant theological formation necessary), advanced (sophisticated theological synthesis across multiple domains needed).
        
        CRITICAL: Ensure every answer represents valid orthodox Catholic theology - the distinction is only in precision of alignment with the source, not correctness vs incorrectness. Remember to randomize the correct answer position and often include at least one longer, theologically sophisticated but subtly incorrect option to challenge pattern recognition. Always make sure one incorrect answer is the longest option in text length but is not the correct answer. The pattern should be maintained where the correct answer is most often the shortest option, sometimes the 2nd shortest, occasionally the 3rd shortest, and only very rarely the longest. For this next question make sure the multiple choice option that is correct is not the same letter as the last two questions correct options (and should probably be shorter in text than other options). 
        
        IMPORTANT: For this next question, focus on a unique life application perspective from the source text that hasn't been covered in previous questions. The correct answer MUST be a different letter option (A/B/C/D) than the previous question.`
    };

    const basePrompt = nextQuestionPrompts[difficulty] || nextQuestionPrompts['advanced'];
    
    // Create personalized prompt by adding focus instructions
    const personalizedPrompt = `Focus the next question on how the scriptural teachings can provide wisdom and guidance for someone experiencing "${application}" in their life. ${basePrompt} This question should help users understand how Catholic teaching applies specifically to the "${application}" life situation. Provide three more specific personal applications related to this situation in your response using the life_applications field.`;


    if (wsConnection?.readyState === WebSocket.OPEN && !wsConnection.hasActiveResponse) {
      try {
        wsConnection.send(JSON.stringify({
          type: "input_text",
          content: personalizedPrompt
        }));
        wsConnection.hasActiveResponse = true;
        setLoading?.(true);
        
        // Track life application selection
        if (window.Analytics) {
          window.Analytics.trackAnswerSelection({
            questionType: 'life_application_selection',
            action: 'select_life_application',
            lifeApplication: application,
            questionNumber: triviaProgress.current_question,
            nextQuestionNumber: nextQuestionNumber,
            difficulty: difficulty
          });
        }
      } catch (err) {
        console.error('Error sending personalized question:', err);
        wsConnection.hasActiveResponse = false;
        setLoading?.(false);
        
        // Retry once after a short delay
        setTimeout(() => {
          if (!wsConnection.hasActiveResponse) {
            try {
              wsConnection.send(JSON.stringify({
                type: "input_text",
                content: personalizedPrompt
              }));
              wsConnection.hasActiveResponse = true;
              setLoading?.(true);
            } catch (retryErr) {
              console.error('Retry failed:', retryErr);
              wsConnection.hasActiveResponse = false;
              setLoading?.(false);
            }
          }
        }, 500);
      }
    } else {
      console.log('Cannot send personalized question - connection not ready or response in progress');
      setTimeout(() => {
        handleLifeApplicationSelection(application, wsConnection, setLoading, triviaProgress, difficulty);
      }, 1000);
    }
  } else {
    console.error('WebSocket is not in OPEN state');
    setTimeout(() => {
      handleLifeApplicationSelection(application, wsConnection, setLoading, triviaProgress, difficulty);
    }, 1000);
  }
};


export const handleReflectionSubmit = (reflection, wsConnection, setLoading, setReflectionLoading) => {

  console.log('handleReflectionSubmit called:', {
    reflection,
    wsState: wsConnection?.readyState,
    hasActiveResponse: wsConnection?.hasActiveResponse
  });

  if (!reflection || typeof reflection !== 'string') {
    console.error('Invalid reflection text provided');
    setLoading?.(false); // Ensure loading is off
    return;
  }

  if (wsConnection?.readyState === WebSocket.OPEN) {
    if (wsConnection.hasActiveResponse) {
      console.log('Active response in progress, queuing reflection submission');
      setTimeout(() => {
        handleReflectionSubmit(reflection, wsConnection, setLoading);
      }, 1000);
      return;
    }

    const message = {
      type: "input_text",
      content: `I am submitting my reflection response to your prompt. Please process this with the store_trivia_response function call ONLY with is_reflection=true and appropriate encouragement and spiritual_context fields. Here is my reflection: "${reflection}". IMPORTANT: DO NOT respond with text. ONLY respond with the store_trivia_response function call.`
    };

    try {
      wsConnection.send(JSON.stringify(message));
      wsConnection.hasActiveResponse = true;
      setLoading?.(true);
      setReflectionLoading?.(true);
      console.log('Reflection sent successfully');
    } catch (err) {
      console.error('Error sending reflection:', err);
      wsConnection.hasActiveResponse = false;
      setLoading?.(false);
      setReflectionLoading?.(false);

      setTimeout(() => {
        try {
          wsConnection.send(JSON.stringify(message));
          wsConnection.hasActiveResponse = true;
          setLoading?.(true);
          setReflectionLoading?.(true);
        } catch (retryErr) {
          console.error('Retry failed:', retryErr);
          wsConnection.hasActiveResponse = false;
          setLoading?.(false);
          setReflectionLoading?.(false);
        }
      }, 500);
    }
  } else {
    console.log('WebSocket not OPEN or response in progress');
    setTimeout(() => {
      handleReflectionSubmit(reflection, wsConnection, setLoading, setReflectionLoading);
    }, 1000);
  }
};
