import React, { useState, useEffect } from 'react';
import { HiClock } from 'react-icons/hi2';

const Timer = ({ duration, onTimeExpire, isActive }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  
  // Reset timer when question changes or isActive changes
  useEffect(() => {
    if (isActive) {
      setTimeLeft(duration);
    }
  }, [duration, isActive]);

  useEffect(() => {
    let timer;
    
    if (isActive && timeLeft > 0) {
      const startTime = Date.now();
      const endTime = startTime + (timeLeft * 1000);
      
      timer = setInterval(() => {
        const remaining = Math.ceil((endTime - Date.now()) / 1000);
        
        if (remaining <= 0) {
          clearInterval(timer);
          setTimeLeft(0);
          onTimeExpire();
        } else {
          setTimeLeft(remaining);
        }
      }, 100);
    }
    
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timeLeft, onTimeExpire, isActive]);

  // Get background color based on time left - keeps white theme
  const getTimerBgColor = () => {
    if (timeLeft <= 5) return 'bg-white/20';
    if (timeLeft <= 15) return 'bg-white/20';
    return 'bg-white/20';
  };

  return (
    <div className={`flex items-center gap-1 px-2 py-1 rounded-md ${getTimerBgColor()} transition-colors duration-300 whitespace-nowrap flex-shrink-0`}>
      <HiClock className="w-3 h-3 text-white" />
      <span className="font-medium text-xs text-white">
        {timeLeft}s
      </span>
    </div>
  );
};

export default Timer;