import React, { useState } from 'react';
import { 
  CheckCircle, 
  AlertCircle, 
  Lock, 
  Eye, 
  EyeOff, 
  KeyRound,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import authenticateService from '../../services/authenticateService';

const PasswordResetSection = ({ userId }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [touched, setTouched] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const handleBlur = (field) => {
    setTouched(prev => ({
      ...prev,
      [field]: true
    }));
  };

  const validateCurrentPassword = () => {
    if (!currentPassword) return 'Current password is required';
    return '';
  };

  const validateNewPassword = () => {
    if (!newPassword) return 'New password is required';
    if (newPassword.length < 6) return 'Password must be at least 6 characters';
    if (newPassword === currentPassword) return 'New password must be different from current password';
    return '';
  };

  const validateConfirmPassword = () => {
    if (!confirmPassword) return 'Please confirm your new password';
    if (confirmPassword !== newPassword) return 'Passwords do not match';
    return '';
  };

  const getValidationError = (field) => {
    if (!touched[field]) return '';
    
    switch (field) {
      case 'currentPassword':
        return validateCurrentPassword();
      case 'newPassword':
        return validateNewPassword();
      case 'confirmPassword':
        return validateConfirmPassword();
      default:
        return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Mark all fields as touched to validate everything
    setTouched({
      currentPassword: true,
      newPassword: true,
      confirmPassword: true
    });
    
    // Run validations
    const currentPwError = validateCurrentPassword();
    const newPwError = validateNewPassword();
    const confirmPwError = validateConfirmPassword();
    
    if (currentPwError || newPwError || confirmPwError) {
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      await authenticateService.changePassword(userId, currentPassword, newPassword);
      setSuccess(true);
      
      // Reset form after successful password change
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      
      // Reset touched state
      setTouched({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      });
      
      // Collapse the form after success
      setTimeout(() => {
        setSuccess(false);
        setExpanded(false);
      }, 3000);
    } catch (err) {
      console.error('Password change error:', err);
      setError(err.message || 'Failed to change password. Please check your current password and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
    
    // If collapsing, reset the form
    if (expanded) {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTouched({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      });
      setError('');
      setSuccess(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-8">
      <div 
        className="flex items-center justify-between cursor-pointer" 
        onClick={handleToggleExpand}
      >
        <div className="flex items-center">
          <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center mr-4">
            <KeyRound className="h-6 w-6 text-indigo-600" />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Password Management</h2>
            <p className="text-sm text-gray-500">
              {expanded ? "Update your password" : "Change your account password"}
            </p>
          </div>
        </div>
        <div className="flex items-center">
          {success && !expanded && (
            <span className="mr-3 text-sm text-green-600 flex items-center">
              <CheckCircle className="h-4 w-4 mr-1" />
              Password updated
            </span>
          )}
          {expanded ? (
            <ChevronUp className="h-5 w-5 text-gray-400" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-400" />
          )}
        </div>
      </div>

      {expanded && (
        <>
          {success && (
            <div className="mt-6 mb-4 bg-green-50 rounded-lg p-4">
              <div className="flex">
                <CheckCircle className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                <div>
                  <p className="text-sm font-medium text-green-800">
                    Password successfully updated
                  </p>
                  <p className="text-sm text-green-700 mt-1">
                    Your password has been changed. Next time you log in, use your new password.
                  </p>
                </div>
              </div>
            </div>
          )}

          {error && (
            <div className="mt-6 mb-4 bg-red-50 rounded-lg p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2 flex-shrink-0" />
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="mt-6 space-y-5">
            <div>
              <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Current Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="currentPassword"
                  name="currentPassword"
                  type={showCurrentPassword ? "text" : "password"}
                  autoComplete="current-password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  onBlur={() => handleBlur('currentPassword')}
                  className={`block w-full pl-10 pr-10 py-2 border ${
                    getValidationError('currentPassword') ? 'border-red-300' : 'border-gray-300'
                  } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCurrentPassword(!showCurrentPassword);
                  }}
                >
                  {showCurrentPassword ? (
                    <EyeOff className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <Eye className="h-5 w-5" aria-hidden="true" />
                  )}
                </button>
              </div>
              {getValidationError('currentPassword') && (
                <p className="mt-2 text-sm text-red-600">{getValidationError('currentPassword')}</p>
              )}
            </div>

            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="newPassword"
                  name="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onBlur={() => handleBlur('newPassword')}
                  className={`block w-full pl-10 pr-10 py-2 border ${
                    getValidationError('newPassword') ? 'border-red-300' : 'border-gray-300'
                  } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm`}
                  placeholder="••••••••"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNewPassword(!showNewPassword);
                  }}
                >
                  {showNewPassword ? (
                    <EyeOff className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <Eye className="h-5 w-5" aria-hidden="true" />
                  )}
                </button>
              </div>
              {getValidationError('newPassword') && (
                <p className="mt-2 text-sm text-red-600">{getValidationError('newPassword')}</p>
              )}
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirm New Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={() => handleBlur('confirmPassword')}
                  className={`block w-full pl-10 py-2 border ${
                    getValidationError('confirmPassword') ? 'border-red-300' : 'border-gray-300'
                  } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm`}
                  placeholder="••••••••"
                />
              </div>
              {getValidationError('confirmPassword') && (
                <p className="mt-2 text-sm text-red-600">{getValidationError('confirmPassword')}</p>
              )}
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="button"
                onClick={handleToggleExpand}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className={`flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  loading ? 'opacity-70 cursor-not-allowed' : ''
                }`}
              >
                {loading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                ) : null}
                {loading ? 'Updating...' : 'Update Password'}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default PasswordResetSection;