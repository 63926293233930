// services/statsService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";

/**
 * Get primary stats for a group/assessment including user performance and group averages
 */

// Add to statsService.js
export const getGroupAdminStats = async (groupId) => {
  try {
    const url = API_ENDPOINTS.GET_GROUP_ADMIN_STATS.replace(':groupId', groupId);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching admin stats:', error);
    throw error;
  }
};


export const getGroupComparisonStats = async (groupId, userId) => {
  try {
    const url = API_ENDPOINTS.GET_GROUP_COMPARISON_STATS.replace(':groupId', groupId);
    const response = await axios.get(url, {
      params: { user_id: userId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching group comparison stats:', error);
    throw error;
  }
};



export const getPrimaryAssessmentStats = async (groupId, userId) => {
  try {
    const url = API_ENDPOINTS.GET_PRIMARY_STATS.replace(':groupId', groupId);
    const response = await axios.get(url, {
      params: { user_id: userId }
    });
    // The backend now provides difficultyBreakdown and groupDifficultyStats
    return response.data;
  } catch (error) {
    console.error('Error fetching primary stats:', error);
    throw error;
  }
};

// Helper function to calculate difficulty scores
const calculateDifficultyScore = (questions, difficulty) => {
  const difficultyQuestions = questions.filter(q => q.difficulty === difficulty);
  if (!difficultyQuestions.length) return 0;
  
  const correct = difficultyQuestions.filter(q => q.is_correct).length;
  return (correct / difficultyQuestions.length) * 100;
};





/**
 * Get group-level aggregate statistics across all completed sessions
 */
export const getGroupAggregateStats = async (groupId) => {
  try {
    const url = API_ENDPOINTS.GET_GROUP_AGGREGATE_STATS.replace(':groupId', groupId);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching group aggregate stats:', error);
    throw error;
  }
};

/**
 * Get list of all participants and their basic stats in a group
 */
export const getGroupParticipants = async (groupId) => {
  try {
    const url = API_ENDPOINTS.GET_GROUP_PARTICIPANTS.replace(':groupId', groupId);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching group participants:', error);
    throw error;
  }
};

/**
 * Get detailed stats for specific user including question-level breakdown
 */
export const getUserDetailedStats = async (groupId, userId) => {
  try {
    const url = API_ENDPOINTS.GET_USER_DETAILED_STATS
      .replace(':groupId', groupId)
      .replace(':userId', userId);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching user detailed stats:', error);
    throw error;
  }
};

/**
 * Format score for display with % symbol and one decimal place
 */
export const formatScore = (score) => {
  if (!score && score !== 0) return 'N/A';
  return `${Number(score).toFixed(1)}%`;
};

/**
 * Get appropriate color class based on score value
 */
export const getScoreColorClass = (score) => {
  if (!score && score !== 0) return 'text-gray-500';
  if (score >= 90) return 'text-green-600';
  if (score >= 75) return 'text-blue-600';
  if (score >= 60) return 'text-yellow-600';
  return 'text-red-600';
};

/**
 * Format duration for display
 */
export const formatDuration = (minutes) => {
  if (!minutes) return 'N/A';
  if (minutes < 60) return `${minutes}m`;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
};

/**
 * Format date for display
 */
export const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

/**
 * Get appropriate icon class based on performance
 */
export const getPerformanceIcon = (score) => {
  if (score >= 90) return 'trophy';
  if (score >= 75) return 'star';
  if (score >= 60) return 'check-circle';
  return 'x-circle';
};

export default {
  getPrimaryAssessmentStats,
  getGroupAggregateStats,
  getGroupParticipants,
  getUserDetailedStats,
  formatScore,
  getScoreColorClass,
  formatDuration,
  formatDate,
  getPerformanceIcon,
  getGroupComparisonStats
};