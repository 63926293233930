import React, { useState, useEffect } from 'react';
import { BookOpen, Lock, Mic, CheckCircle, Clock, ChevronRight } from 'lucide-react';
import Analytics from '../../services/analytics';

/**
 * ReadingSelector - Modified to display readings vertically
 * With improved handling of group names, topics, and unavailable readings
 * Updated to exclude homily when requested
 */
const ReadingSelector = ({
  userId,
  communityId = 1,
  selectedDate = new Date(),
  onReadingChange,
  hasPremium = false,
  onPremiumPrompt,
  availableReadings = [],
  currentGospelGroup = null,
  onStartTrivia,
  onContinueTrivia,
  className = '',
  excludeHomily = false // New prop to exclude homily
}) => {
  // Define reading types - now expanded to include display properties
  // Filter out homily if excludeHomily is true
  const [readingTypes, setReadingTypes] = useState([
    { id: 'gospel', name: 'Today\'s Gospel', stepTitle: 'Gospel', icon: BookOpen, available: true, selected: true },
    { id: 'first', name: 'First Reading', stepTitle: 'FirstReading', icon: BookOpen, available: hasPremium, selected: false },
    { id: 'second', name: 'Second Reading', stepTitle: 'SecondReading', icon: BookOpen, available: hasPremium, selected: false },
    ...(!excludeHomily ? [{ id: 'homily', name: 'Sunday Homily', stepTitle: 'Homily', icon: Mic, available: true, selected: false }] : [])
  ]);
  
  // Update reading availability based on premium status and selected date
  useEffect(() => {
    const isSunday = selectedDate.getDay() === 0; // 0 is Sunday
    
    setReadingTypes(prev => prev.map(type => {
      if (hasPremium) {
        // All readings available for premium users on all days
        return { ...type, available: true };
      } else if ((type.id === 'gospel' || type.id === 'homily' || type.id === 'first' || type.id === 'second') && isSunday) {
        // Gospel, Homily, First and Second readings available for freemium on Sundays only
        return { ...type, available: true };
      } else {
        // Everything else locked for freemium
        return { ...type, available: false };
      }
    }));
  }, [hasPremium, selectedDate]);


  // Update selection state based on currentGospelGroup
  useEffect(() => {
    if (currentGospelGroup) {
      const stepTitleMap = {
        'Gospel': 'gospel',
        'FirstReading': 'first',
        'SecondReading': 'second',
        'Homily': 'homily'
      };
      
      const selectedId = stepTitleMap[currentGospelGroup.step_title] || 'gospel';
      
      setReadingTypes(prev => prev.map(type => ({
        ...type,
        selected: type.id === selectedId
      })));
    }
  }, [currentGospelGroup]);

  // NEW EFFECT: Process reading data when availableReadings changes
  useEffect(() => {
    if (availableReadings && availableReadings.length > 0) {
      // Process each reading type with its corresponding data
      setReadingTypes(prev => prev.map(type => {
        const stepTitleMap = {
          'gospel': 'Gospel',
          'first': 'FirstReading',
          'second': 'SecondReading',
          'homily': 'Homily'
        };
        
        const stepTitle = stepTitleMap[type.id];
        // Find this reading in availableReadings
        const readingData = availableReadings.find(r => r.step_title === stepTitle);
        
        if (readingData) {
          // Make sure we have proper topics format
          let topics = [];
          if (readingData.topics) {
            if (typeof readingData.topics === 'string') {
              try {
                topics = JSON.parse(readingData.topics);
              } catch (e) {
                topics = [readingData.topics];
              }
            } else if (Array.isArray(readingData.topics)) {
              topics = readingData.topics;
            }
          }
          
          // Store the readingData with the reading type
          return {
            ...type,
            readingData: {
              ...readingData,
              topics: topics,
              // Ensure description is present
              description: readingData.description || (topics.length > 0 ? topics[0] : '')
            }
          };
        }
        
        return type;
      }));
    }
  }, [availableReadings]);


  // Handle reading selection
  const handleReadingSelect = (readingId) => {
    // Find the selected reading
    const selectedReading = readingTypes.find(r => r.id === readingId);
    
    // Add direct Sunday check for freemium
    const isSunday = selectedDate.getDay() === 0;
    const isSundayReading = !hasPremium && isSunday && 
      (readingId === 'gospel' || readingId === 'homily' || 
       readingId === 'first' || readingId === 'second');
    
    // If not available (premium locked) and not a Sunday reading for freemium
    if (!selectedReading.available && !isSundayReading) {
      // Track premium prompt trigger
      Analytics.trackAnswerSelection({
        questionType: 'premium_prompt',
        action: 'reading_locked_click',
        userId,
        readingType: readingId
      });
      
      // Trigger premium prompt
      if (onPremiumPrompt) {
        onPremiumPrompt();
      }
      return;
    }
    
    // Update selected state
    const updatedReadings = readingTypes.map(type => ({
      ...type,
      selected: type.id === readingId
    }));
    
    setReadingTypes(updatedReadings);
    
    // Call parent handler with the reading information
    if (onReadingChange) {
      // Convert from id (first) to ReadingType (First)
      const readingType = readingId.charAt(0).toUpperCase() + readingId.slice(1);
      
      onReadingChange({
        readingType: readingType,
        date: selectedDate,
        communityId: communityId
      });
    }
    
    // Track reading selection
    Analytics.trackAnswerSelection({
      questionType: 'reading_selection',
      action: 'reading_type_changed',
      userId,
      readingType: readingId,
      hasPremium
    });
  };


  // Find the reading data from availableReadings
  const getReadingData = (stepTitle) => {
    if (!availableReadings || !availableReadings.length) return null;
    return availableReadings.find(r => r.step_title === stepTitle);
  };

  // Get the actual reading title from the group name or generate an appropriate one
  const getReadingTitle = (readingId, defaultName) => {
    const stepTitleMap = {
      'gospel': 'Gospel',
      'first': 'FirstReading',
      'second': 'SecondReading',
      'homily': 'Homily'
    };
    
    const stepTitle = stepTitleMap[readingId];
    
    // First check for cached data in reading type
    const reading = readingTypes.find(r => r.id === readingId);
    if (reading && reading.readingData && reading.readingData.name) {
      return reading.readingData.name;
    }
    
    // If this is the current selected reading, use the group name
    if (currentGospelGroup && currentGospelGroup.step_title === stepTitle) {
      return currentGospelGroup.name || defaultName;
    }
    
    // Otherwise check available readings
    const availableReading = availableReadings?.find(r => r.step_title === stepTitle);
    return availableReading?.name || defaultName;
  };

  // Description text for readings - use topics if available
  const getReadingDescription = (readingId) => {
    const stepTitleMap = {
      'gospel': 'Gospel',
      'first': 'FirstReading',
      'second': 'SecondReading',
      'homily': 'Homily'
    };
    
    const stepTitle = stepTitleMap[readingId];
    
    // First check for cached data in reading type
    const reading = readingTypes.find(r => r.id === readingId);
    if (reading && reading.readingData) {
      // Use cached description if available
      if (reading.readingData.description) {
        return reading.readingData.description;
      }
      
      // Use cached topics if available
      if (reading.readingData.topics && reading.readingData.topics.length > 0) {
        return reading.readingData.topics[0];
      }
    }
    
    // If this is the current selected reading and it's a placeholder
    if (currentGospelGroup && 
        currentGospelGroup.step_title === stepTitle && 
        currentGospelGroup.isPlaceholder) {
      return currentGospelGroup.noReadingMessage || `No ${readingId === 'homily' ? 'Homily' : stepTitle} available for this date.`;
    }
    
    // If this is the current selected reading, check for topics
    if (currentGospelGroup && currentGospelGroup.step_title === stepTitle) {
      // Use topics if available
      if (currentGospelGroup.topics && 
          Array.isArray(currentGospelGroup.topics) && 
          currentGospelGroup.topics.length > 0) {
        return currentGospelGroup.topics[0];
      }
      
      // Use description if available
      if (currentGospelGroup.description) {
        return currentGospelGroup.description;
      }
    }
    
    // Check available readings for this reading type
    const availableReading = availableReadings?.find(r => r.step_title === stepTitle);
    
    if (availableReading) {
      // Check if reading has topics
      if (availableReading.topics && Array.isArray(availableReading.topics) && availableReading.topics.length > 0) {
        return availableReading.topics[0];
      }
      
      // Use description if available
      if (availableReading.description) {
        return availableReading.description;
      }
    }
    
    // Special case for homily
    if (readingId === 'homily') {
      // Check if parish is selected
      if (!communityId || communityId === 389) {
        return "Please select a parish to view homily content.";
      }
      
      // Calculate upcoming Sunday for display
      const now = new Date();
      const today = now.getDay(); // 0 = Sunday
      const daysUntilSunday = today === 0 ? 0 : 7 - today;
      const upcomingSunday = new Date(now);
      upcomingSunday.setDate(now.getDate() + daysUntilSunday);
      const sundayDisplay = upcomingSunday.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
      });
      
      return `Homily for upcoming Sunday (${sundayDisplay}).`;
    }
    
    // Default fallback description
    return `${
      readingId === 'first' ? 'Test your knowledge with multiple-choice questions about the First Reading' :
      readingId === 'second' ? 'No Second Reading Today' :
      readingId === 'homily' ? 'Reflect on the homily from your parish' :
      'Test your knowledge with multiple-choice questions about the Gospel'
    }`;

  };
  
  // Check if a reading is completed
  const isReadingCompleted = (readingType) => {
    // First check for cached data
    const reading = readingTypes.find(r => r.id === readingType);
    if (reading && reading.readingData && reading.readingData.is_completed) {
      return true;
    }
    
    const stepTitleMap = {
      'gospel': 'Gospel',
      'first': 'FirstReading',
      'second': 'SecondReading',
      'homily': 'Homily'
    };
    
    const stepTitle = stepTitleMap[readingType];
    
    // Check if current reading is selected and completed
    if (currentGospelGroup && currentGospelGroup.step_title === stepTitle) {
      return currentGospelGroup.isCompleted;
    }
    
    // Otherwise, check available readings
    const availableReading = availableReadings?.find(r => r.step_title === stepTitle);
    return availableReading?.is_completed || false;
  };


  // Check if a reading is unavailable (placeholder)
  const isReadingUnavailable = (readingId) => {
    const stepTitleMap = {
      'gospel': 'Gospel',
      'first': 'FirstReading',
      'second': 'SecondReading',
      'homily': 'Homily'
    };
    
    const stepTitle = stepTitleMap[readingId];
    
    // If this is the current selected reading, check if it's a placeholder
    if (currentGospelGroup && 
        currentGospelGroup.step_title === stepTitle && 
        currentGospelGroup.isPlaceholder) {
      return true;
    }
    
    // For homily, first check if parish is selected
    if (readingId === 'homily') {
      if (!communityId || communityId === 389) {
        return true;
      }
      
      // Now check date logic: allow homily only for current/upcoming Sunday or dates before it
      const now = new Date();
      const today = now.getDay(); // 0 = Sunday
      
      // Calculate upcoming Sunday date
      const daysUntilSunday = today === 0 ? 0 : 7 - today;
      const upcomingSunday = new Date(now);
      upcomingSunday.setDate(now.getDate() + daysUntilSunday);
      
      // Reset hours to start of day for clean comparison
      upcomingSunday.setHours(0, 0, 0, 0);
      
      // Create a clean date copy for comparison
      const selectedDateCopy = new Date(selectedDate);
      selectedDateCopy.setHours(0, 0, 0, 0);
      
      // If selected date is after the upcoming Sunday, homily is unavailable
      if (selectedDateCopy > upcomingSunday) {
        return true;
      }
    }
    
    return false;
  };


  // Render each reading as a vertical item
  return (
    <div className={`space-y-3 ${className}`}>
      {readingTypes.map(reading => {
        const Icon = reading.icon;
        const isReadingSelected = reading.selected && currentGospelGroup;
        const isLocked = !reading.available;
        const isCompleted = isReadingCompleted(reading.id);
        const isUnavailable = isReadingUnavailable(reading.id);
        const readingData = reading.readingData || getReadingData(reading.stepTitle);
        
        // Get citation if available
        const citation = readingData?.citation || '';
        
        // Get actual reading title - prioritize cached data
        const readingTitle = readingData?.name || getReadingTitle(reading.id, reading.name);
        
        // Get description - prioritize cached data
        const descriptionText = readingData?.description || getReadingDescription(reading.id);
        
        // If we have a current gospel group and this is selected
        if (isReadingSelected && currentGospelGroup) {
          // If it's a placeholder (no reading available)
          if (currentGospelGroup.isPlaceholder) {
            return (
              <div key={reading.id} className="border border-gray-200 rounded-lg overflow-hidden">
                <div className="relative border-l-4 border-gray-500 p-4 bg-white">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 bg-gray-100 rounded-lg p-2 mr-3">
                      <Icon className="h-6 w-6 text-gray-500" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900">{readingTitle}</h3>
                      {citation && (
                        <p className="text-sm text-gray-500 mb-1">{citation}</p>
                      )}
                      <p className="text-sm text-gray-600">
                        {currentGospelGroup.noReadingMessage || "No reading available for today's Mass."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          
          // Normal trivia component for selected reading
          return (
            <div key={reading.id} className="border border-gray-200 rounded-lg overflow-hidden">
              <div className="relative border-l-4 border-blue-500 p-4 bg-white">
                <div className="flex justify-between items-center">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 bg-blue-100 rounded-lg p-2 mr-3">
                      <Icon className="h-6 w-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900">{readingTitle}</h3>
                      {citation && (
                        <p className="text-sm text-gray-500 mb-1">{citation}</p>
                      )}
                      <p className="text-sm text-gray-600">{descriptionText}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center ml-2">
                    {isCompleted && (
                      <div className="flex items-center text-green-600 mr-4">
                        <CheckCircle className="h-5 w-5 mr-1" />
                        <span className="text-sm font-medium">Completed</span>
                      </div>
                    )}
                    
                    {!isUnavailable && (
                      <button
                        onClick={onStartTrivia}
                        className={`px-4 py-2 text-sm font-medium rounded-md ${
                          isCompleted 
                            ? 'bg-green-50 text-green-700 hover:bg-green-100' 
                            : 'bg-blue-600 text-white hover:bg-blue-700'
                        }`}
                      >
                        {isCompleted ? 'Retake' : 'Start'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
              

        if (reading.available) {
          return (
            <div 
              key={reading.id}
              onClick={() => handleReadingSelect(reading.id)}
              className="border border-gray-200 rounded-lg overflow-hidden cursor-pointer hover:border-blue-300 hover:shadow-md transition-all duration-200"
            >
              <div className="relative border-l-4 border-gray-300 p-4 bg-white">
                <div className="flex justify-between items-center">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 bg-gray-100 rounded-lg p-2 mr-3">
                      <Icon className="h-6 w-6 text-gray-600" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 flex items-center">
                        {readingTitle}
                      </h3>
                      {citation && (
                        <p className="text-sm text-gray-500 mb-1">{citation}</p>
                      )}
                      <p className="text-sm text-gray-600">{descriptionText}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    {isCompleted && (
                      <div className="flex items-center text-green-600 mr-3">
                        <CheckCircle className="h-5 w-5 mr-1" />
                        <span className="text-sm font-medium">Completed</span>
                      </div>
                    )}
                    <div className="text-gray-400 hover:text-blue-600">
                      <ChevronRight className="h-5 w-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }


        return (
          <div key={reading.id} className="border border-gray-200 rounded-lg overflow-hidden">
            <div className="relative border-l-4 border-gray-400 p-4 bg-white">
              <div className="flex items-start justify-between">
                <div className="flex items-start">
                  <div className="flex-shrink-0 bg-gray-100 rounded-lg p-2 mr-3">
                    <Icon className="h-6 w-6 text-gray-500" />
                  </div>
                  <div>
                    <div className="flex items-center">
                      <h3 className="text-base font-medium text-gray-900">{readingTitle}</h3>
                      <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Premium
                      </span>
                    </div>
                    {citation && (
                      <p className="text-sm text-gray-500 mb-1">{citation}</p>
                    )}
                    <p className="text-sm text-gray-600">{descriptionText}</p>
                  </div>
                </div>
                <button
                  onClick={onPremiumPrompt}
                  className="px-4 py-2 text-sm font-medium rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200 flex items-center"
                >
                  <Lock className="h-4 w-4 mr-1.5" />
                  Unlock
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReadingSelector;
