import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Church, Plus, Clock, X } from 'lucide-react';
import { createCommunity } from '../../services/communityService';

/**
 * Component to create a new parish/community for admin users
 */
const CreateCommunitySection = ({ userId }) => {
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  
  // Community form state
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    location: '',
    diocese: '',
    state: '',
    city: '',
    massTimes: [
      { dayOfWeek: 'Sunday', startTime: '10:00 AM', endTime: '11:00 AM', isVigil: false }
    ]
  });
  
  const handleCreateCommunity = async () => {
    if (!formData.name || !formData.city || !formData.state) {
      setError('Parish name, city and state are required');
      return;
    }

    try {
      setIsCreating(true);
      setError(null);
      
      const response = await createCommunity({
        name: formData.name,
        description: formData.description,
        location: formData.location,
        diocese: formData.diocese,
        city: formData.city,
        state: formData.state,
        type: 'Parish',
        massTimes: formData.massTimes,
        admin_user_id: userId
      });
      
      if (response && response.success && response.community_id) {
        // Close modal and navigate to admin dashboard for the new community
        setShowCreateModal(false);
        navigate(`/community-admin/${response.community_id}`);
      } else {
        setError('Failed to create parish');
      }
    } catch (err) {
      console.error('Error creating community:', err);
      setError(err.response?.data?.error || 'Failed to create parish');
    } finally {
      setIsCreating(false);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleAddMassTime = () => {
    setFormData({
      ...formData,
      massTimes: [
        ...formData.massTimes,
        { dayOfWeek: 'Sunday', startTime: '10:00 AM', endTime: '11:00 AM', isVigil: false }
      ]
    });
  };
  
  const handleRemoveMassTime = (index) => {
    const updatedMassTimes = [...formData.massTimes];
    updatedMassTimes.splice(index, 1);
    setFormData({
      ...formData,
      massTimes: updatedMassTimes
    });
  };
  
  const handleMassTimeChange = (index, field, value) => {
    const updatedMassTimes = [...formData.massTimes];
    updatedMassTimes[index] = {
      ...updatedMassTimes[index],
      [field]: value
    };
    setFormData({
      ...formData,
      massTimes: updatedMassTimes
    });
  };
  
  if (!showCreateModal) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden mb-4">
        <div className="px-4 py-3 border-b border-gray-200 bg-gradient-to-r from-indigo-50 to-purple-50">
          <div className="flex items-center">
            <Church className="h-5 w-5 text-indigo-600 mr-2" />
            <h2 className="text-gray-900 font-medium">
              Parish Administration
            </h2>
          </div>
        </div>
        
        <div className="p-4">
          <div className="text-center">
            <button
              onClick={() => setShowCreateModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              <Plus className="h-4 w-4 mr-1.5" />
              Create New Parish
            </button>
            <p className="mt-2 text-xs text-gray-500">
              Create and manage your own parish community
            </p>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <>
      <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden mb-4">
        <div className="px-4 py-3 border-b border-gray-200 bg-gradient-to-r from-indigo-50 to-purple-50">
          <div className="flex items-center">
            <Church className="h-5 w-5 text-indigo-600 mr-2" />
            <h2 className="text-gray-900 font-medium">
              Parish Administration
            </h2>
          </div>
        </div>
        
        <div className="p-4">
          <div className="text-center">
            <button
              onClick={() => setShowCreateModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
            >
              <Plus className="h-4 w-4 mr-1.5" />
              Create New Parish
            </button>
            <p className="mt-2 text-xs text-gray-500">
              Create and manage your own parish community
            </p>
          </div>
        </div>
      </div>
      
      {/* Modal as a direct DOM element instead of component */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="p-6 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900">Create New Parish</h2>
              <button 
                className="text-gray-400 hover:text-gray-500"
                onClick={() => setShowCreateModal(false)}
              >
                <span className="sr-only">Close</span>
                <X className="h-6 w-6" />
              </button>
            </div>
          </div>
          
          <div className="p-6">
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md text-sm">
                {error}
              </div>
            )}
            
            <div className="space-y-4">
              <div>
                <label htmlFor="parish-name" className="block text-sm font-medium text-gray-700 mb-1">
                  Parish Name*
                </label>
                <input
                  id="parish-name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="St. John's Catholic Church"
                />
              </div>
              
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="A brief description of your parish"
                  rows={3}
                />
              </div>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
                    City*
                  </label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    placeholder="San Francisco"
                  />
                </div>
                <div>
                  <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">
                    State*
                  </label>
                  <input
                    id="state"
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    placeholder="CA"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="diocese" className="block text-sm font-medium text-gray-700 mb-1">
                  Diocese
                </label>
                <input
                  id="diocese"
                  type="text"
                  name="diocese"
                  value={formData.diocese}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Diocese of San Francisco"
                />
              </div>
              
              <div>
                <div className="flex justify-between items-center mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Mass Times
                  </label>
                  <button
                    type="button"
                    onClick={handleAddMassTime}
                    className="inline-flex items-center text-xs font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <Plus className="h-3.5 w-3.5 mr-1" />
                    Add Mass Time
                  </button>
                </div>
                
                {formData.massTimes.map((mass, index) => (
                  <div key={index} className="border border-gray-200 rounded-md p-3 mb-3">
                    <div className="flex justify-between mb-2">
                      <span className="text-sm font-medium text-gray-700 flex items-center">
                        <Clock className="h-4 w-4 mr-1 text-gray-400" />
                        Mass #{index + 1}
                      </span>
                      {formData.massTimes.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveMassTime(index)}
                          className="text-xs text-red-600 hover:text-red-800"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div>
                        <label className="block text-xs text-gray-500 mb-1">Day</label>
                        <select
                          value={mass.dayOfWeek}
                          onChange={(e) => handleMassTimeChange(index, 'dayOfWeek', e.target.value)}
                          className="w-full px-2 py-1.5 text-sm border border-gray-300 rounded-md"
                        >
                          <option value="Sunday">Sunday</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday">Saturday</option>
                          <option value="Saturday Vigil">Saturday Vigil</option>
                        </select>
                      </div>
                      
                      <div>
                        <label className="block text-xs text-gray-500 mb-1">Start Time</label>
                        <select
                          value={mass.startTime}
                          onChange={(e) => handleMassTimeChange(index, 'startTime', e.target.value)}
                          className="w-full px-2 py-1.5 text-sm border border-gray-300 rounded-md"
                        >
                          <option value="7:00 AM">7:00 AM</option>
                          <option value="8:00 AM">8:00 AM</option>
                          <option value="9:00 AM">9:00 AM</option>
                          <option value="10:00 AM">10:00 AM</option>
                          <option value="11:00 AM">11:00 AM</option>
                          <option value="12:00 PM">12:00 PM</option>
                          <option value="5:00 PM">5:00 PM</option>
                          <option value="6:00 PM">6:00 PM</option>
                          <option value="7:00 PM">7:00 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="px-6 py-4 border-t border-gray-200 flex justify-end">
            <button
              type="button"
              onClick={() => setShowCreateModal(false)}
              className="mr-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 rounded-md"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleCreateCommunity}
              disabled={isCreating}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md disabled:opacity-50"
            >
              {isCreating ? 'Creating...' : 'Create Parish'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCommunitySection;