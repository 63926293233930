import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { X, Clock, CheckCircle, Info } from 'lucide-react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import { setUserActiveParish } from '../../services/dashboardService';

/**
 * MassTimeSelector - Component to change mass time for an already joined parish
 * Updated to focus only on Sunday and Saturday Vigil masses
 */
const MassTimeSelector = ({ isOpen, onClose, parish, onMassTimeChanged }) => {
  const { userId } = useSelector((state) => state.user);
  const [massTimes, setMassTimes] = useState([]);
  const [weekendMassTimes, setWeekendMassTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMassTimeId, setSelectedMassTimeId] = useState(parish?.selectedMass?.id || null);
  const [saving, setSaving] = useState(false);
  
  // Fetch mass times for the parish
  useEffect(() => {
    const fetchMassTimes = async () => {
      if (!isOpen || !parish) return;
      
      try {
        setLoading(true);
        
        // Get mass times for this parish
        const response = await axios.get(
          API_ENDPOINTS.GET_COMMUNITY_MASS_TIMES.replace(':communityId', parish.id)
        );
        
        if (response.data.massTimes) {
          const allMassTimes = response.data.massTimes;
          
          // Filter for only weekend masses (Sunday and Saturday Vigil)
          const weekendOnly = allMassTimes.filter(mass => 
            mass.dayOfWeek === 'Sunday' || mass.dayOfWeek === 'Saturday Vigil'
          );
          
          setMassTimes(allMassTimes);
          setWeekendMassTimes(weekendOnly);
          
          // If no mass time is selected yet, use the current one from the parish
          if (!selectedMassTimeId && parish.selectedMass) {
            setSelectedMassTimeId(parish.selectedMass.id);
          }
        }
      } catch (error) {
        console.error('Error fetching mass times:', error);
        setError('Failed to load mass times for this parish');
      } finally {
        setLoading(false);
      }
    };
    
    fetchMassTimes();
  }, [isOpen, parish, selectedMassTimeId]);
  
  // Save selected mass time
  const handleSaveMassTime = async () => {
    if (!selectedMassTimeId) {
      setError('Please select a mass time');
      return;
    }
    
    try {
      setSaving(true);
      
      // Update the active parish mass time
      await setUserActiveParish(userId, parish.id, selectedMassTimeId);
      
      // Find the selected mass time details
      const selectedMassTime = massTimes.find(mt => mt.id === selectedMassTimeId);
      
      if (onMassTimeChanged) {
        onMassTimeChanged({
          ...parish,
          selectedMass: selectedMassTime
        });
      }
      
      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error updating mass time:', error);
      setError('Failed to update mass time. Please try again.');
      setSaving(false);
    }
  };
  
  // Group mass times by day
  const groupMassTimes = (massTimes) => {
    const groups = {};
    
    // Create groups for Sunday and Saturday Vigil only
    massTimes.forEach(massTime => {
      const day = massTime.dayOfWeek;
      if (day === 'Sunday' || day === 'Saturday Vigil') {
        if (!groups[day]) {
          groups[day] = [];
        }
        groups[day].push(massTime);
      }
    });
    
    // Sort each group by time
    Object.keys(groups).forEach(day => {
      groups[day].sort((a, b) => {
        // Convert time strings to Date objects for comparison
        const timeA = new Date(`1970-01-01 ${a.startTime}`);
        const timeB = new Date(`1970-01-01 ${b.startTime}`);
        return timeA - timeB;
      });
    });
    
    // Order by Saturday Vigil first, then Sunday
    const result = [];
    if (groups['Saturday Vigil']) {
      result.push({ day: 'Saturday Vigil', times: groups['Saturday Vigil'] });
    }
    if (groups['Sunday']) {
      result.push({ day: 'Sunday', times: groups['Sunday'] });
    }
    
    return result;
  };
  
  if (!isOpen || !parish) return null;
  
  const groupedWeekendMassTimes = groupMassTimes(weekendMassTimes);
  
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Change Weekend Mass Time</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        
        {/* Content */}
        <div className="p-4">
          <h3 className="text-base font-medium text-gray-900 mb-2">{parish.name}</h3>
          <p className="text-sm text-gray-600 mb-4">
            Select your preferred weekend mass time:
          </p>
          
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin h-8 w-8 border-4 border-blue-600 border-t-transparent rounded-full mx-auto mb-4"></div>
              <p className="text-gray-600">Loading mass times...</p>
            </div>
          ) : error ? (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg text-sm mb-4">
              {error}
            </div>
          ) : weekendMassTimes.length === 0 ? (
            <div className="text-center py-6 bg-amber-50 rounded-lg">
              <p className="text-amber-700">No weekend masses available for this parish.</p>
            </div>
          ) : (
            <div className="space-y-4">
              {groupedWeekendMassTimes.map(({ day, times }) => (
                <div key={day} className="mt-2">
                  <h5 className="text-xs font-semibold text-gray-700 uppercase mb-2">
                    {day}
                  </h5>
                  <div className="grid grid-cols-2 gap-2">
                    {times.map(massTime => (
                      <button
                        key={massTime.id}
                        onClick={() => setSelectedMassTimeId(massTime.id)}
                        className={`px-3 py-2 text-sm text-left border rounded-md flex items-center ${
                          selectedMassTimeId === massTime.id
                            ? 'bg-blue-50 border-blue-300 text-blue-700'
                            : 'bg-white border-gray-200 text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <Clock className="h-4 w-4 mr-2 text-gray-500" />
                        <span>{massTime.startTime}</span>
                        {selectedMassTimeId === massTime.id && (
                          <CheckCircle className="h-4 w-4 ml-2 text-blue-600" />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
              
              <div className="mt-2 p-3 bg-blue-50 rounded-md">
                <div className="flex items-start">
                  <Info className="h-4 w-4 text-blue-500 mt-0.5 mr-2 flex-shrink-0" />
                  <p className="text-xs text-blue-700">
                    Only weekend masses (Sunday and Saturday Vigil) are displayed because homilies are typically specific to these masses.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Footer */}
        <div className="p-4 border-t border-gray-200 bg-gray-50 rounded-b-lg">
          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveMassTime}
              disabled={loading || saving || !selectedMassTimeId || weekendMassTimes.length === 0}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {saving ? (
                <span className="flex items-center">
                  <span className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
                  Saving...
                </span>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MassTimeSelector;