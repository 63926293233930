// services/dashboardService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";





/**
 * Gets the current gospel group based on user subscription status and date
 * @param {string|number} userId - The user ID or temp user ID
 * @param {string} [subscriptionOverride] - Optional override for subscription status
 * @param {Date} [selectedDate] - Optional specific date to get readings for
 * @returns {Promise<Object>} - The appropriate gospel group
 */
export const getCurrentGospelGroup = async (userId, subscriptionOverride, selectedDate) => {
  try {
    console.log(`\n=== Getting Current Gospel Group ===`);
    console.log(`User ID: ${userId}`);
        
    // Format date parameter to ensure it's in Pacific Time
    let dateParam = '';
    if (selectedDate instanceof Date) {
      // Create date formatter in Pacific time
      const options = { 
        timeZone: 'America/Los_Angeles',
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      };
      
      // Format date in Pacific time
      const pacificDate = selectedDate.toLocaleDateString('en-US', options);
      // Convert from MM/DD/YYYY to YYYY-MM-DD
      const [month, day, year] = pacificDate.split('/');
      dateParam = `${year}-${month}-${day}`;
      
      console.log(`Target date parameter (Pacific): ${dateParam}`);
    }



    // Build query parameters
    const params = {
      ...(dateParam && { date: dateParam }),
      ...(subscriptionOverride && { subscription_status: subscriptionOverride })
    };
    
    // Call the API to get the appropriate group
    const response = await axios.get(
      API_ENDPOINTS.GET_CURRENT_GOSPEL_GROUP.replace(':userId', userId),
      { params }
    );
    
    if (response.status === 200 && response.data.success && response.data.group) {
      console.log(`Found gospel group: ${response.data.group.name} (ID: ${response.data.group.id})`);
      
      // Check if the group is completed by this user
      const completionStatus = await checkGroupCompletion(response.data.group.id, userId);
      return {
        ...response.data.group,
        isCompleted: completionStatus.isCompleted
      };
    }
    
    // If no specific group found, use a default ID
    console.log(`No specific gospel group found, using default fallback`);
    return {
      id: 1855, // Default fallback group ID
      name: 'Gospel Examen',
      difficulty: 'advanced',
      include_source_text: true,
      trivia_question_count: 4,
      isCompleted: false
    };
  } catch (error) {
    console.error('Error fetching current gospel group:', error);
    // Return a default fallback in case of error
    return {
      id: 1855, // Default fallback group ID
      name: 'Gospel Examen',
      difficulty: 'advanced',
      include_source_text: true,
      trivia_question_count: 4,
      isCompleted: false
    };
  }
};

/**
 * Get all available readings for a date with completion status
 * @param {string} date - The date in YYYY-MM-DD format
 * @param {number|string} userId - The user ID
 * @returns {Promise<Object>} - Available readings and progress info
 */
export const getAvailableReadings = async (date, userId) => {
  try {
    console.log(`\n=== Getting Available Readings ===`);
    console.log(`Target date: ${date}, User ID: ${userId}`);
    
    const response = await axios.get(
      API_ENDPOINTS.GET_AVAILABLE_READINGS,
      { 
        params: { 
          date: date,
          user_id: userId
        } 
      }
    );
    
    if (response.status === 200 && response.data.success) {
      return {
        readings: response.data.readings || [],
        totalReadings: response.data.total_readings || 0,
        completedReadings: response.data.completed_readings || 0,
        progress: response.data.progress || 0,
        hasPremium: response.data.has_premium || false
      };
    }
    
    return {
      readings: [],
      totalReadings: 0,
      completedReadings: 0,
      progress: 0,
      hasPremium: false
    };
  } catch (error) {
    console.error('Error fetching available readings:', error);
    return {
      readings: [],
      totalReadings: 0,
      completedReadings: 0,
      progress: 0,
      hasPremium: false
    };
  }
};


/**
 * Check if the user has premium access and get subscription type
 * @param {number|string} userId - The user ID
 * @returns {Promise<Object>} - Returns subscription info including type and premium status
 */
export const checkPremiumStatus = async (userId) => {
  try {
    console.log(`\n=== Checking Premium Status ===`);
    console.log(`User ID: ${userId}`);
    
    const response = await axios.get(
      API_ENDPOINTS.GET_SUBSCRIPTION_STATUS.replace(':userId', userId)
    );
    
    if (response.status === 200) {
      const data = response.data;
      
      // Get subscription status
      const subscriptionStatus = data.subscription_status || 'freemium';
      
      // User has premium access if the subscription status is 'premium' or 'admin'
      const hasPremium = subscriptionStatus === 'premium' || subscriptionStatus === 'admin';
      
      return {
        subscriptionStatus: subscriptionStatus,
        hasPremium: hasPremium
      };
    }
    
    return {
      subscriptionStatus: 'freemium',
      hasPremium: false
    };
  } catch (error) {
    console.error('Error checking premium status:', error);
    return {
      subscriptionStatus: 'freemium',
      hasPremium: false
    };
  }
};


/**
 * Get user's trivia statistics
 * @param {string|number} userId - The user ID
 * @returns {Promise<Object>} - User's trivia statistics
 */
export const getUserTriviaStats = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_TRIVIA_STATS.replace(':userId', userId)
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching trivia stats:', error);
    return {
      success: false,
      total_completed: 0,
      current_streak: 0,
      last_completion: null
    };
  }
};

/**
 * Check if a group has been completed by a user
 * @param {number|string} groupId - The group ID to check
 * @param {number|string} userId - The user ID
 * @returns {Promise<Object>} - Completion status and details
 */
export const checkGroupCompletion = async (groupId, userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.CHECK_GROUP_COMPLETION.replace(':groupId', groupId),
      { params: { user_id: userId } }
    );
    
    if (response.status === 200 && response.data.success) {
      return {
        isCompleted: response.data.isCompleted,
        completedDate: response.data.completedDate,
        score: response.data.score
      };
    }
    
    return { isCompleted: false };
  } catch (error) {
    console.error('Error checking group completion:', error);
    return { isCompleted: false };
  }
};




export const getUserCommunities = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_MEMBER_COMMUNITIES.replace(':userId', userId)
    );
    
    return response.data || [];
  } catch (error) {
    console.error('Error fetching user communities:', error);
    return [];
  }
};

export const getCommunityGroups = async (communityId, userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_GROUPS.replace(':communityId', communityId),
      { params: { user_id: userId } }
    );
    
    return response.data || [];
  } catch (error) {
    console.error(`Error fetching groups for community ${communityId}:`, error);
    return [];
  }
};



/**
 * Get user's active parish community
 * @param {string|number} userId - The user ID
 * @returns {Promise<Object>} - The user's active parish community
 */
export const getUserActiveParish = async (userId) => {
  try {
    // Call the API to get the user's most recently selected parish
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_ACTIVE_PARISH.replace(':userId', userId)
    );
    
    if (response.status === 200 && response.data.success) {
      return response.data.community;
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching user active parish:', error);
    return null;
  }
};

/**
 * Update user's active parish community
 * @param {string|number} userId - The user ID
 * @param {string|number} communityId - The community ID to set as active
 * @returns {Promise<boolean>} - Success status
 */
export const setUserActiveParish = async (userId, communityId) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.SET_USER_ACTIVE_PARISH.replace(':userId', userId),
      { community_id: communityId }
    );
    
    return response.status === 200 && response.data.success;
  } catch (error) {
    console.error('Error setting user active parish:', error);
    return false;
  }
};


/**
 * Get homily reading for a specific date and community
 * Always gets the upcoming Sunday homily, regardless of selected date
 * @param {Date|string} date - The date parameter (ignored - always uses upcoming Sunday)
 * @param {string|number} communityId - The community ID
 * @param {string|number} userId - The user ID
 * @returns {Promise<Object>} - The homily reading group
 */
export const getHomily = async (date, communityId, userId) => {
  try {
    // Always calculate the upcoming Sunday, completely ignoring the date parameter
    const now = new Date();
    const today = now.getDay(); // 0 = Sunday
    const daysUntilSunday = today === 0 ? 0 : 7 - today;
    
    // Calculate the upcoming Sunday
    const upcomingSunday = new Date(now);
    upcomingSunday.setDate(now.getDate() + daysUntilSunday);
      

    // Format in Pacific time as YYYY-MM-DD
    const options = { 
      timeZone: 'America/Los_Angeles',
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit' 
    };
    const pacificDate = upcomingSunday.toLocaleDateString('en-US', options);
    // Convert from MM/DD/YYYY to YYYY-MM-DD
    const [month, day, year] = pacificDate.split('/');
    const sundayDateStr = `${year}-${month}-${day}`;


    console.log(`Requesting homily for date: ${sundayDateStr} (always upcoming Sunday)`);
    
    // Always use the upcoming Sunday date for homily requests
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_HOMILY,
      { 
        params: { 
          date: sundayDateStr, // Always use upcoming Sunday
          community_id: communityId,
          user_id: userId
        } 
      }
    );
    
    if (response.status === 200 && response.data.success) {
      // If there's a homily, add a note that it's for the upcoming Sunday
      if (response.data.group) {
        const sundayDateDisplay = upcomingSunday.toLocaleDateString('en-US', {
          weekday: 'long',
          month: 'short',
          day: 'numeric'
        });
        
        // The API already checks and returns is_completed so we preserve it
        return {
          ...response.data.group,
          name: `Sunday Homily - ${sundayDateDisplay}`,
          date: sundayDateStr,
          is_upcoming_sunday: true, // Flag to indicate this is for upcoming Sunday
          upcoming_sunday_date: sundayDateStr,
          upcoming_sunday_display: sundayDateDisplay,
          // Make sure we're preserving the completion status from the API
          isCompleted: response.data.group.is_completed || false
        };
      }
      return response.data.group;
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching homily for community:', error);
    return null;
  }
};


export const updatePersonalContext = async (userId, personalContext) => {
  try {
    console.log(`\n=== Updating Personal Context ===`);
    console.log(`User ID: ${userId}`);
    
    const response = await axios.post(
      API_ENDPOINTS.UPDATE_PERSONAL_CONTEXT.replace(':userId', userId),
      { personalContext }
    );
    
    if (response.status === 200 && response.data.success) {
      console.log(`Successfully updated personal context for user ${userId}`);
      return {
        success: true,
        message: response.data.message || 'Personal context updated successfully'
      };
    }
    
    return {
      success: false,
      message: 'Failed to update personal context'
    };
  } catch (error) {
    console.error('Error updating personal context:', error);
    return {
      success: false,
      message: 'Error updating personal context'
    };
  }
};


/**
 * Get user's personal context for tailored scripture examens
 * @param {string|number} userId - The user ID
 * @returns {Promise<Object>} - The user's personal context data
 */
export const getPersonalContext = async (userId) => {
  try {
    console.log(`\n=== Fetching Personal Context ===`);
    console.log(`User ID: ${userId}`);
    
    const response = await axios.get(
      API_ENDPOINTS.GET_PERSONAL_CONTEXT.replace(':userId', userId)
    );
    
    if (response.status === 200 && response.data.success) {
      return {
        success: true,
        personalContext: response.data.personalContext || ''
      };
    }
    
    return {
      success: false,
      personalContext: ''
    };
  } catch (error) {
    console.error('Error fetching personal context:', error);
    return {
      success: false,
      personalContext: ''
    };
  }
};




/**
 * Get user's scripture journey data
 * @param {string|number} userId - The user ID
 * @returns {Promise<Object>} - Scripture journey data
 */
export const getScriptureJourney = async (userId) => {
  try {
    console.log(`\n=== Getting Scripture Journey ===`);
    console.log(`User ID: ${userId}`);
    
    const response = await axios.get(
      API_ENDPOINTS.GET_SCRIPTURE_JOURNEY.replace(':userId', userId)
    );
    
    if (response.status === 200 && response.data.success) {
      return response.data.scriptureJourney;
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching scripture journey:', error);
    return null;
  }
};

/**
 * Update reading completion status
 * @param {string|number} userId - The user ID
 * @param {string|number} groupId - The group ID to mark as completed
 * @returns {Promise<boolean>} - Success status
 */
export const updateReadingCompletion = async (userId, groupId) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.UPDATE_READING_COMPLETION.replace(':userId', userId),
      { groupId }
    );
    
    return response.status === 200 && response.data.success;
  } catch (error) {
    console.error('Error updating reading completion:', error);
    return false;
  }
};

/**
 * Get scripture coverage statistics
 * @returns {Promise<Object>} - Coverage statistics
 */
export const getScriptureCoverageStats = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_SCRIPTURE_COVERAGE_STATS);
    
    if (response.status === 200 && response.data.success) {
      return response.data.coverageStats;
    }
    
    return null;
  } catch (error) {
    console.error('Error fetching coverage stats:', error);
    return null;
  }
};

export default {
  getHomily,
  getUserActiveParish,
  setUserActiveParish,
  getCurrentGospelGroup,
  getUserTriviaStats,
  checkGroupCompletion,
  getAvailableReadings,
  checkPremiumStatus,
  getUserCommunities,
  getCommunityGroups,
  updatePersonalContext,
  getPersonalContext,
  getScriptureJourney,
  updateReadingCompletion,
  getScriptureCoverageStats,

  

};
