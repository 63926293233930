import React, { useState, useEffect } from 'react';
import { HiExclamationCircle } from 'react-icons/hi2';

const ErrorNotification = ({ isVisible, onComplete }) => {
  useEffect(() => {
    if (isVisible) {
      // After 2 seconds, trigger the refresh
      const timer = setTimeout(() => {
        onComplete();
        window.location.reload();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onComplete]);

  if (!isVisible) return null;

  return (
    <div className="fixed top-4 right-4 w-96 bg-amber-50 border border-amber-200 rounded-lg shadow-lg p-4 animate-fade-in">
      <div className="flex items-center gap-2">
        <HiExclamationCircle className="w-5 h-5 text-amber-500" />
        <p className="text-amber-800">
          Oops! We had to reset your trivia due to an AI glitch (this happens about 1 out of every 10 first questions -- you must be lucky!)... let's get started correctly now.
        </p>
      </div>
    </div>
  );
};

export default ErrorNotification;