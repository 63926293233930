import React from 'react';
import { Calendar } from 'lucide-react';
/**
 * UpcomingGospelSection Component - Enhanced mobile UI/UX
 * Shows information about the next week's examen
 */
const UpcomingGospelSection = ({ 
  communityId,
  isVisible = true, 
  examenInfo = null 
}) => {
  if (!isVisible || !examenInfo) return null;
  
  // Use examenInfo properties for display
  const title = examenInfo.name || "Next Week's Examen";
  const subtitle = examenInfo.subtitle || "";
  const daysLeft = examenInfo.daysUntilAvailable || 7;
  
  return (
    <div className="bg-white rounded-xl border border-gray-200 overflow-hidden mb-4 sm:mb-6 shadow-sm">
      {/* Header - Improved layout for small screens */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between p-4 sm:p-5 border-b gap-2">
        <h2 className="text-base sm:text-lg font-bold text-gray-900">{title}</h2>
        <span className="self-start sm:self-auto inline-flex text-xs bg-gray-100 text-gray-600 px-2.5 py-1 rounded-full font-medium items-center">
          <span className="w-1.5 h-1.5 bg-indigo-500 rounded-full mr-1.5 inline-block"></span>
          Available in {daysLeft} {daysLeft === 1 ? 'day' : 'days'}
        </span>
      </div>
      
      {/* Content - Improved spacing and responsiveness */}
      <div className="p-4 sm:p-5 flex items-center">
        <div className="bg-indigo-100 w-10 h-10 sm:w-12 sm:h-12 rounded-full flex items-center justify-center mr-3 sm:mr-4 border border-indigo-200 flex-shrink-0">
          <Calendar className="h-5 w-5 sm:h-6 sm:w-6 text-indigo-600" />
        </div>
        <div className="min-w-0">
          <h3 className="font-medium text-gray-900 text-sm sm:text-base truncate">{title}</h3>
          {subtitle && <p className="text-xs sm:text-sm text-gray-500 mt-0.5">{subtitle}</p>}
        </div>
      </div>
      
      {/* Gospel preview - Simplified with shorter text */}
      {examenInfo.gospelTitle && examenInfo.gospelExcerpt && (
        <div className="px-4 sm:px-5 pb-4 sm:pb-5">
          <div className="bg-gray-50 border border-gray-200 rounded-lg p-3 sm:p-4">
            <h4 className="font-medium text-gray-900 text-xs sm:text-sm">{examenInfo.gospelTitle}</h4>
            <p className="text-gray-700 text-xs sm:text-sm mt-1.5 sm:mt-2 leading-relaxed line-clamp-2">
              {examenInfo.gospelExcerpt}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default UpcomingGospelSection;