// components/InviteCommunityModal/InviteCommunityModal.jsx

import React, { useState, useEffect } from 'react';
import { HiXMark, HiQrCode, HiLink, HiClipboard } from "react-icons/hi2";

import { getCommunityInviteQR } from '../../services/communityService';

const InviteCommunityModal = ({ isOpen, onClose, communityId, communityName, selectedGroupId = null }) => {
  const [qrCodeData, setQrCodeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('qrcode'); // 'qrcode' or 'link'
  
  // Generate the invite URL base
  const baseUrl = `${window.location.origin}/invite`;
  
  useEffect(() => {
    if (isOpen && communityId) {
      generateQRCode();
    }
  }, [isOpen, communityId, selectedGroupId]);
  
  const generateQRCode = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Call API to generate QR code
      const data = await getCommunityInviteQR(communityId, baseUrl, selectedGroupId);
      
      setQrCodeData(data);
    } catch (err) {
      console.error('Error generating QR code:', err);
      setError('Failed to generate invitation QR code');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCopyLink = () => {
    if (!qrCodeData?.inviteUrl) return;
    
    navigator.clipboard.writeText(qrCodeData.inviteUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Error copying link:', err);
      });
  };
  
  const handleDownloadQR = () => {
    if (!qrCodeData?.qrCodeBase64) return;
    
    // Create a link element
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${qrCodeData.qrCodeBase64}`;
    link.download = `${communityName.replace(/\s+/g, '-')}-invitation.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Invite to {communityName}</h2>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500"
          >
            <HiXMark className="h-6 w-6" />
          </button>
        </div>
        
        {/* Tab Selector */}
        <div className="flex border-b border-gray-200">
          <button
            className={`flex-1 py-3 px-4 text-center ${activeTab === 'qrcode' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('qrcode')}
          >
            <div className="flex items-center justify-center">
              <HiQrCode className="w-5 h-5 mr-2" />
              QR Code
            </div>
          </button>
          <button
            className={`flex-1 py-3 px-4 text-center ${activeTab === 'link' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            onClick={() => setActiveTab('link')}
          >
            <div className="flex items-center justify-center">
              <HiLink className="w-5 h-5 mr-2" />
              Invitation Link
            </div>
          </button>
        </div>
        
        {/* Content */}
        <div className="p-6">
          {loading ? (
            <div className="text-center py-10">
              <div className="animate-spin h-10 w-10 border-4 border-blue-600 border-t-transparent rounded-full mx-auto mb-4"></div>
              <p className="text-gray-600">Generating invitation...</p>
            </div>
          ) : error ? (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg text-sm">
              {error}
            </div>
          ) : (
            <>
              {activeTab === 'qrcode' && qrCodeData?.qrCodeBase64 && (
                <div className="text-center">
                  <div className="bg-white p-4 rounded-lg border border-gray-200 inline-block mb-4">
                    <img 
                      src={`data:image/png;base64,${qrCodeData.qrCodeBase64}`} 
                      alt="QR Code for community invitation" 
                      className="max-w-full h-auto"
                    />
                  </div>
                  
                  <p className="text-sm text-gray-600 mb-6">
                    Share this QR code with your parish members. They can scan it to access the trivia questions directly.
                  </p>
                  
                  <button
                    onClick={handleDownloadQR}
                    className="w-full flex justify-center items-center gap-2 px-4 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <HiClipboard className="w-5 h-5" />
                    Download QR Code
                  </button>
                </div>
              )}
              
              {activeTab === 'link' && qrCodeData?.inviteUrl && (
                <div>
                  <div className="relative rounded-md shadow-sm mb-6">
                    <input
                      type="text"
                      className="block w-full pr-10 sm:text-sm border-gray-300 rounded-md py-3 px-4 bg-gray-50"
                      value={qrCodeData.inviteUrl}
                      readOnly
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                      <button 
                        onClick={handleCopyLink}
                        className="text-indigo-600 hover:text-indigo-800"
                      >
                        <HiClipboard className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                  
                  {copied && (
                    <div className="bg-green-50 text-green-700 p-2 rounded text-sm text-center mb-4">
                      Link copied to clipboard!
                    </div>
                  )}
                  
                  <p className="text-sm text-gray-600 mb-6">
                    Share this link directly with parish members via email, messaging, or include it in your parish bulletin.
                  </p>
                  
                  <button
                    onClick={handleCopyLink}
                    className="w-full flex justify-center items-center gap-2 px-4 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <HiClipboard className="w-5 h-5" />
                    Copy Invitation Link
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        
        {/* Instructions */}
        <div className="bg-gray-50 p-6 rounded-b-lg border-t border-gray-200">
          <h3 className="font-medium text-gray-900 mb-2">Invitation Details</h3>
          <ul className="text-sm text-gray-600 space-y-1">
            <li>• This invitation link is specific to your parish community</li>
            <li>• Parish members don't need an account to start the trivia</li>
            <li>• They will be prompted to join your community when they first visit</li>
            <li>• Results will be tracked for your community dashboard</li>
            {selectedGroupId && (
              <li>• This invitation links directly to a specific trivia session</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InviteCommunityModal;