// services/communityService.js
import axios from 'axios';
import { API_ENDPOINTS } from '../config/api';

/**
 * Get community details by ID
 * @param {number} communityId - The community ID
 * @param {boolean} preview - Whether this is a preview from invitation
 * @param {number} userId - Optional user ID
 * @returns {Promise<Object>} - Community details
 */
export const getCommunityDetails = async (communityId, preview = false, userId = null) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_DETAILS.replace(':communityId', communityId),
      { params: { preview, user_id: userId } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching community details:', error);
    throw error;
  }
};

/**
 * Join a community
 * @param {number} communityId - The community ID
 * @param {number} userId - The user ID
 * @returns {Promise<Object>} - Response data
 */
export const joinCommunity = async (communityId, userId) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.JOIN_COMMUNITY.replace(':communityId', communityId),
      { user_id: userId }
    );
    return response.data;
  } catch (error) {
    console.error('Error joining community:', error);
    throw error;
  }
};

/**
 * Get upcoming trivia for a community
 * @param {number} communityId - The community ID
 * @returns {Promise<Object>} - Upcoming trivia info
 */
export const getUpcomingCommunityTrivia = async (communityId) => {
  try {
    console.log("Requesting upcoming trivia for community:", communityId);
    
    const response = await axios.get(
      API_ENDPOINTS.GET_UPCOMING_COMMUNITY_TRIVIA.replace(':communityId', communityId)
    );
    
    return response.data;
  } catch (err) {
    console.error("Error fetching upcoming community trivia:", err);
    throw err;
  }
};

/**
 * Initialize trivia for a group
 * @param {number} groupId - The group ID
 * @param {number} userId - The user ID
 * @returns {Promise<Object>} - Initialized trivia data
 */
export const initializeGroupTrivia = async (groupId, userId) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.INITIALIZE_GROUP_TRIVIA.replace(':groupId', groupId),
      { userId }
    );
    return response.data;
  } catch (error) {
    console.error('Error initializing group trivia:', error);
    throw error;
  }
};

/**
 * Get the community administrator dashboard data
 * @param {number} communityId - The community ID
 * @param {number} userId - The admin user ID
 * @returns {Promise<Object>} - Admin dashboard data
 */
export const getCommunityAdminDashboard = async (communityId, userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_ADMIN_DASHBOARD.replace(':communityId', communityId),
      { params: { user_id: userId } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching community admin dashboard:', error);
    throw error;
  }
};

/**
 * Check if user is a member of a community
 * @param {number} communityId - The community ID
 * @param {number} userId - The user ID
 * @returns {Promise<boolean>} - Whether user is a member
 */
export const isUserCommunityMember = async (communityId, userId) => {
  try {
    const response = await axios.get(
      `${API_ENDPOINTS.GET_COMMUNITY_DETAILS.replace(':communityId', communityId)}/membership`,
      { params: { user_id: userId } }
    );
    return response.data.is_member === true;
  } catch (error) {
    console.error('Error checking community membership:', error);
    return false;
  }
};

/**
 * Get all communities for a user
 * @param {number} userId - The user ID
 * @returns {Promise<Array>} - List of user's communities
 */
export const getUserCommunities = async (userId) => {
  try {
    const response = await axios.get(
      `${API_ENDPOINTS.GET_COMMUNITY_DETAILS.replace(':communityId', 'user')}`,
      { params: { user_id: userId } }
    );
    return response.data.communities || [];
  } catch (error) {
    console.error('Error fetching user communities:', error);
    return [];
  }
};

/**
 * Get community QR code
 * @param {number} communityId - The community ID
 * @param {string} baseUrl - Base URL for the invite link
 * @param {number} groupId - Optional specific group ID
 * @returns {Promise<Object>} - QR code data
 */
export const getCommunityInviteQR = async (communityId, baseUrl, groupId = null) => {
  try {
    // Prepare query parameters
    const params = { baseUrl };
    
    // Add groupId to params if provided
    if (groupId) {
      params.groupId = groupId;
    }
    
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_INVITE_QR.replace(':communityId', communityId),
      { params }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error generating community invite QR:', error);
    throw error;
  }
};

/**
 * Get predefined parishes for simplified parish finder
 * @returns {Promise<Array>} - List of predefined parishes
 */
export const getPredefinedParishes = async () => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_PREDEFINED_PARISHES
    );
    
    return response.data.communities || [];
  } catch (error) {
    console.error('Error getting predefined parishes:', error);
    throw error;
  }
};

/**
 * Search for parishes/communities by name, city, or state
 * @param {string} searchTerm - The search term to find parishes
 * @returns {Promise<Array>} - List of matching parishes
 */
export const searchParishes = async (searchTerm) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.SEARCH_PARISHES,
      { params: { query: searchTerm } }
    );
    
    return response.data.communities || [];
  } catch (error) {
    console.error('Error searching parishes:', error);
    throw error;
  }
};

/**
 * Join a parish community with a specific mass time (now optional)
 * @param {number} communityId - The community/parish ID
 * @param {number} userId - The user ID
 * @param {number} massTimeId - The selected mass time ID (can be null in simplified version)
 * @returns {Promise<Object>} - Response data
 */
export const joinParishWithMass = async (communityId, userId, massTimeId = null) => {
  try {
    // Prepare request payload
    const payload = { 
      user_id: userId
    };
    
    // Only include mass_time_id if it's provided
    if (massTimeId) {
      payload.mass_time_id = massTimeId;
    }
    
    const response = await axios.post(
      API_ENDPOINTS.JOIN_COMMUNITY_PARISH.replace(':communityId', communityId),
      payload
    );
    
    return response.data;
  } catch (error) {
    console.error('Error joining parish:', error);
    throw error;
  }
};

/**
 * Get mass times for a specific parish
 * @param {number} communityId - The community/parish ID
 * @returns {Promise<Array>} - List of mass times
 */
export const getCommunityMassTimes = async (communityId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_MASS_TIMES.replace(':communityId', communityId)
    );
    
    return response.data.massTimes || [];
  } catch (error) {
    console.error('Error fetching parish mass times:', error);
    return [];
  }
};

/**
 * Get communities where the user is an admin
 * @param {number} userId - The user ID
 * @returns {Promise<Array>} - List of communities where user is admin
 */
export const getUserAdminCommunities = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_ADMIN_COMMUNITY.replace(':userId', userId)
    );
    
    return response.data.communities || [];
  } catch (error) {
    console.error('Error fetching user admin communities:', error);
    return [];
  }
};

/**
 * Create a new community/parish
 * @param {Object} communityData - The community data including name, description, etc.
 * @returns {Promise<Object>} - The newly created community
 */
export const createCommunity = async (communityData) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.CREATE_COMMUNITY,
      communityData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating community:', error);
    throw error;
  }
};

/**
 * Get upcoming weekends for a community
 * @param {number} communityId - The community ID
 * @param {number} limit - Number of weekends to fetch (default: 4)
 * @returns {Promise<Array>} - List of upcoming weekends
 */
export const getUpcomingWeekends = async (communityId, limit = 4) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_UPCOMING_WEEKENDS.replace(':communityId', communityId),
      { params: { limit } }
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming weekends:', error);
    throw error;
  }
};


/**
 * Generate content based on homily description
 * @param {Object} data - Contains communityId, weekendId, and description
 * @returns {Promise<Object>} - Generated content
 */
export const generateHomilyContent = async (data) => {
  try {
    const response = await axios.post(
      API_ENDPOINTS.GENERATE_HOMILY_CONTENT,
      data
    );
    
    // Handle the new response format that includes options
    if (response.data && response.data.success) {
      return {
        success: true,
        sourceText: response.data.sourceText,
        questionText: response.data.questionText,
        options: response.data.options || [] // Capture the options array if present
      };
    }
    
    return response.data;
  } catch (error) {
    console.error('Error generating homily content:', error);
    throw error;
  }
};


/**
 * Get community statistics including member count and completion metrics
 * @param {number} communityId - The community ID
 * @returns {Promise<Object>} - Community statistics
 */
export const getCommunityStats = async (communityId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_STATS.replace(':communityId', communityId)
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching community stats:', error);
    throw error;
  }
};

/**
 * Get list of community members
 * @param {number} communityId - The community ID
 * @returns {Promise<Object>} - List of community members
 */
export const getCommunityMembers = async (communityId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_MEMBERS.replace(':communityId', communityId)
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching community members:', error);
    throw error;
  }
};

/**
 * Get detailed completion data for community
 * @param {number} communityId - The community ID
 * @returns {Promise<Object>} - List of completions with question details
 */
export const getCommunityCompletions = async (communityId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_COMPLETIONS.replace(':communityId', communityId)
    );
    
    return response.data;
  } catch (error) {
    console.error('Error fetching community completions:', error);
    throw error;
  }
};


/**
 * Create or update a homily group for a specific Sunday
 * @param {Object} data - Contains communityId, weekendDate, description, and other parameters
 * @returns {Promise<Object>} - The created or updated group
 */
export const createOrUpdateHomilyGroup = async (data) => {
  try {
    const {
      communityId,
      weekendId,
      description,
      userId
    } = data;

    // Extract date from weekend_id (format: weekend_YYYYMMDD)
    const weekendDate = weekendId.replace('weekend_', '');
    const formattedDate = `${weekendDate.substring(0, 4)}-${weekendDate.substring(4, 6)}-${weekendDate.substring(6, 8)}`;
    
    // Format the description as a topics array (JSON string)
    const topics = JSON.stringify([description]);
    
    // Prepare the group data
    const groupData = {
      community_id: communityId,
      weekend_date: formattedDate,
      description: description,
      name: `Sunday Homily - ${formattedDate}`,
      difficulty: "advanced",
      step_title: "Homily",
      trivia_question_count: 6,
      include_source_text: true,
      topics: topics,
      admin_id: userId,
      type: "trivia",
      trivia_enabled: true
    };
    
    // Send request to create or update the homily group
    const response = await axios.post(
      API_ENDPOINTS.CREATE_OR_UPDATE_HOMILY_GROUP,
      groupData
    );
    
    return response.data;
  } catch (error) {
    console.error('Error creating/updating homily group:', error);
    throw error;
  }
};

/**
 * This function will replace the existing updateHomilyDescription function
 * to handle the group creation/update instead of just storing the description
 */
export const updateHomilyDescription = async (data) => {
  try {
    const { communityId, weekendId, description } = data;
    
    // First update the description in the homily_descriptions table for backward compatibility
    const descResponse = await axios.post(
      API_ENDPOINTS.UPDATE_HOMILY_DESCRIPTION,
      {
        communityId,
        weekendId,
        description
      }
    );
    
    // Then create or update the corresponding group
    const groupResponse = await createOrUpdateHomilyGroup({
      ...data,
      userId: data.userId || null // Pass the admin user ID if available
    });
    
    return {
      success: descResponse.data.success && groupResponse.success,
      message: "Homily description and group updated successfully",
      groupId: groupResponse.groupId
    };
  } catch (error) {
    console.error('Error in updateHomilyDescription:', error);
    throw error;
  }
};


export default {
  getCommunityDetails,
  getUserAdminCommunities,
  getCommunityInviteQR,
  joinCommunity,
  joinParishWithMass,
  searchParishes,
  getPredefinedParishes,
  getUpcomingCommunityTrivia,
  initializeGroupTrivia,
  getCommunityAdminDashboard,
  isUserCommunityMember,
  getUserCommunities,
  getCommunityMassTimes,
  createCommunity,
  getUpcomingWeekends,
  updateHomilyDescription,
  generateHomilyContent,
  getCommunityStats,
  getCommunityMembers,
  getCommunityCompletions,
  createOrUpdateHomilyGroup,

};