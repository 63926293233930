import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Mic, BookOpen, ChevronRight, Calendar, ChevronLeft, ChevronDown, ChevronUp, Lock, CheckCircle, Clock, X, Church } from 'lucide-react';

// Import components
import Header from '../../components/Header/Header';
import ReadingSelector from '../../components/ReadingSelector/ReadingSelector';
import TriviaStepComponent from '../../components/TriviaStepComponent/TriviaStepComponent';
import UpgradePrompt from '../../components/UpgradePrompt/UpgradePrompt';
import UpcomingExamen from '../../components/UpcomingGospelSection/UpcomingGospelSection';
import CommunityTriviaSection from '../../components/CommunityTriviaSection/CommunityTriviaSection';
import AdminCommunitiesSection from '../../components/AdminCommunitiesSection/AdminCommunitiesSection';
import ParishSelector from '../../components/ParishSelector/ParishSelector';
import CreateCommunitySection from '../../components/CreateCommunitySection/CreateCommunitySection';
import HomilyCard from '../../components/HomilyCard/HomilyCard'; // Add this line
import ScriptureProgressTracker from '../../components/ScriptureProgressTracker/ScriptureProgressTracker'; // Add this line


// Import services
import { 
  getCurrentGospelGroup,
  getUserTriviaStats,
  checkPremiumStatus,
  getAvailableReadings,
  checkGroupCompletion,
  getUserActiveParish,
  setUserActiveParish,
  getHomily
} from '../../services/dashboardService';
import { getUserCommunities, getUserAdminCommunities } from '../../services/communityService';
import ParishFinder from '../../components/ParishFinder/ParishFinder';
import MassTimeSelector from '../../components/MassTimeSelector/MassTimeSelector';
import CalendarCard from '../../components/CalendarCard/CalendarCard';
import ParishInterestForm from '../../components/ParishInterestForm/ParishInterestForm';

// Import analytics 
import Analytics from '../../services/analytics';

/**
 * UserDashboard - Updated with parish-specific homily support
 */
const UserDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;
  const [dashboardLoadTime] = useState(Date.now());
  const [userHasAdminCommunities, setUserHasAdminCommunities] = useState(false);

  
  // State for user subscription & data
  const [userSubscription, setUserSubscription] = useState('freemium');
  const [hasPremium, setHasPremium] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  // State for parish/community
  const [activeParish, setActiveParish] = useState(null);
  const [userParishes, setUserParishes] = useState([]);
  const [isParishDropdownOpen, setIsParishDropdownOpen] = useState(false);
  
  // State for calendar
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showParishInterestForm, setShowParishInterestForm] = useState(false);  


  // State for Gospel group
  const [currentGospelGroup, setCurrentGospelGroup] = useState(null);
  const [availableReadings, setAvailableReadings] = useState([]);
  const [progress, setProgress] = useState(0);
  
  // State for trivia stats
  const [triviaStats, setTriviaStats] = useState({
    totalCompleted: 0,
    currentStreak: 0
  });
  
  // State for upcoming examen
  const [upcomingExamen, setUpcomingExamen] = useState(null);
  
  // State for user communities
  const [userHasCommunities, setUserHasCommunities] = useState(false);
  const [checkingCommunities, setCheckingCommunities] = useState(true);
  
  // State for loading and errors
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State for premium prompt
  const [showPremiumPrompt, setShowPremiumPrompt] = useState(false);
  
  // References for click outside handling
  const calendarRef = React.useRef(null);
  const parishDropdownRef = React.useRef(null);
  
  const [showParishFinder, setShowParishFinder] = useState(false);
  const [showMassTimeSelector, setShowMassTimeSelector] = useState(false);


  // Helper function to parse topics
  const parseTopics = (topicsData) => {
    // If it's null or undefined, return empty array
    if (!topicsData) return [];
    
    // If it's already an array, return it
    if (Array.isArray(topicsData)) return topicsData;
    
    // If it's a string, try to parse it as JSON
    if (typeof topicsData === 'string') {
      try {
        const parsed = JSON.parse(topicsData);
        return Array.isArray(parsed) ? parsed : [topicsData];
      } catch (e) {
        // If it can't be parsed as JSON, treat it as a single topic
        return [topicsData];
      }
    }
    
    // Default: return as-is
    return [topicsData];
  };

  // Add a function to check if the user administers any communities
  const checkUserAdminCommunities = async () => {
    try {
      // Fetch communities where the user is an admin
      const adminCommunities = await getUserAdminCommunities(effectiveUserId);
      
      // Set flag if user has any admin communities
      setUserHasAdminCommunities(adminCommunities.length > 0);
    } catch (error) {
      console.error('Error checking user admin communities:', error);
      setUserHasAdminCommunities(false);
    }
  };


  // Close calendar dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
      if (parishDropdownRef.current && !parishDropdownRef.current.contains(event.target)) {
        setIsParishDropdownOpen(false);
      }
    };
    
    if (isCalendarOpen || isParishDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarOpen, isParishDropdownOpen]);
  
  // Initialize analytics tracking when component mounts
  useEffect(() => {
    // Track dashboard view
    Analytics.trackDashboardView({
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      subscriptionType: userSubscription,
      fromAuth: location.state?.fromAuth || false,
      isNewRegistration: location.state?.isNewRegistration || false,
      referrer: document.referrer
    });
    
    // Set up engagement tracking
    Analytics.startEngagementTracking();
    
    // Clean up on unmount
    return () => {
      const sessionDuration = Math.floor((Date.now() - dashboardLoadTime) / 1000);
      
      Analytics.trackAnswerSelection({
        questionType: 'dashboard_engagement',
        action: 'dashboard_exit',
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        timeSpent: sessionDuration
      });
      
      Analytics.stopEngagementTracking();
    };
  }, []);
  

  // Initial user setup and data loading
  useEffect(() => {
    if (effectiveUserId) {
      initializeUserData();
      checkUserCommunities();
      checkUserAdminCommunities();
      loadUserParishes();
    }
  }, [effectiveUserId]);
  

  // Load user parishes (communities)
  const loadUserParishes = async () => {
    try {
      // Load all communities the user is a member of
      const communities = await getUserCommunities(effectiveUserId);
      
      // Filter out the default community (ID 389)
      const filteredCommunities = communities.filter(comm => comm.id !== 389);
      setUserParishes(filteredCommunities);
      
      // Get user's active parish
      const parish = await getUserActiveParish(effectiveUserId);
      setActiveParish(parish);
      
      // If we have an active parish and it's a Sunday, pre-load the homily
      if (parish && selectedDate.getDay() === 0) {
        const dateStr = selectedDate.toISOString().split('T')[0];
        const homily = await getHomily(dateStr, parish.id, effectiveUserId);
        
        if (homily) {
          console.log("Pre-loaded homily data:", homily);
          
          // Add to availableReadings if not already there
          setAvailableReadings(prevReadings => {
            const updatedReadings = [...prevReadings];
            const homilyIndex = updatedReadings.findIndex(r => r.step_title === 'Homily');
            
            if (homilyIndex >= 0) {
              updatedReadings[homilyIndex] = {
                ...homily,
                name: `${parish.name} - Homily`,
                date: dateStr,
                step_title: 'Homily'
              };
            } else {
              updatedReadings.push({
                ...homily,
                name: `${parish.name} - Homily`,
                date: dateStr,
                step_title: 'Homily'
              });
            }
            
            return updatedReadings;
          });
        }
      }
      
      // Track analytics
      if (parish) {
        Analytics.trackAnswerSelection({
          questionType: 'parish_selection',
          action: 'parish_loaded',
          userId: effectiveUserId,
          userType: userId ? 'registered' : 'anonymous',
          parishId: parish.id,
          parishName: parish.name
        });
      }
    } catch (error) {
      console.error('Error loading user parishes:', error);
    }
  };


  // Handle parish selection
  const handleParishSelect = async (parish) => {
    try {
      setIsParishDropdownOpen(false);
      
      // Update active parish in the backend
      await setUserActiveParish(effectiveUserId, parish.id);
      setActiveParish(parish);
      
      // Track parish change
      Analytics.trackAnswerSelection({
        questionType: 'parish_selection',
        action: 'parish_changed',
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        parishId: parish.id,
        parishName: parish.name
      });
      
      // If currently viewing a homily, reload it with the new parish
      if (currentGospelGroup && currentGospelGroup.step_title === 'Homily') {
        handleReadingChange({
          readingType: 'Homily',
          date: selectedDate
        });
      }
    } catch (error) {
      console.error('Error setting active parish:', error);
    }
  };
  
  // Check if user has any communities
  const checkUserCommunities = async () => {
    try {
      setCheckingCommunities(true);
      
      // Fetch communities the user has joined
      const userCommunities = await getUserCommunities(effectiveUserId);
      
      // Filter out default community (ID 389)
      const filteredCommunities = userCommunities.filter(comm => comm.id !== 389);
      
      // Set flag if user has any communities
      setUserHasCommunities(filteredCommunities.length > 0);
      setCheckingCommunities(false);
    } catch (error) {
      console.error('Error checking user communities:', error);
      setUserHasCommunities(false);
      setCheckingCommunities(false);
    }
  };
  


  // Effect to check premium status
  useEffect(() => {
    const checkPremium = async () => {
      if (effectiveUserId) {
        try {
          const subscriptionInfo = await checkPremiumStatus(effectiveUserId);
          
          setHasPremium(subscriptionInfo.hasPremium);
          setUserSubscription(subscriptionInfo.subscriptionStatus);
          
          // Track premium status in analytics
          Analytics.trackAnswerSelection({
            questionType: 'user_status',
            action: 'premium_status',
            userId: effectiveUserId,
            userType: userId ? 'registered' : 'anonymous',
            hasPremium: subscriptionInfo.hasPremium,
            subscriptionType: subscriptionInfo.subscriptionStatus
          });
        } catch (error) {
          console.error('Error checking premium status:', error);
        }
      }
    };
    
    checkPremium();
  }, [effectiveUserId]);


  // Initialize user data and fetch Gospel group
  const initializeUserData = async () => {
    try {
      setLoading(true);
            
      const subscriptionInfo = await checkPremiumStatus(effectiveUserId);
      setHasPremium(subscriptionInfo.hasPremium);
      setUserSubscription(subscriptionInfo.subscriptionStatus);

      // Load active parish early in the initialization process
      const parish = await getUserActiveParish(effectiveUserId);
      setActiveParish(parish);

      // 2. Determine target date
      let targetDate = new Date();
      
      if (!subscriptionInfo.hasPremium) {
        // For freemium users:
        // - If today is Sunday, use today's date
        // - Otherwise, use the next upcoming Sunday only
        const dayOfWeek = targetDate.getDay(); // 0 = Sunday
        if (dayOfWeek !== 0) {
          const daysUntilSunday = 7 - dayOfWeek;
          targetDate.setDate(targetDate.getDate() + daysUntilSunday);
        }
      }
      
      setSelectedDate(targetDate);
      setCurrentMonth(new Date(targetDate));
      
      // 3. Get Gospel group for the target date
      const gospelGroup = await getCurrentGospelGroup(
        effectiveUserId, 
        null, // No subscription override
        targetDate // Pass the target date
      );
      
      if (gospelGroup) {
        // Process topics from the gospel group
        const parsedTopics = parseTopics(gospelGroup.topics);
        
        // Create a complete object with all required fields
        const completeGospelGroup = {
          ...gospelGroup,
          topics: parsedTopics,
          // Add a description if one doesn't exist
          description: gospelGroup.description || (parsedTopics.length > 0 ? parsedTopics[0] : '')
        };
        
        setCurrentGospelGroup(completeGospelGroup);
        
        // Check if the group is completed
        if (completeGospelGroup.id) {
          const completion = await checkGroupCompletion(completeGospelGroup.id, effectiveUserId);
          if (completion.isCompleted) {
            setCurrentGospelGroup(prevGroup => ({
              ...prevGroup,
              isCompleted: true
            }));
          }
        }
        
        // Track gospel group loaded
        Analytics.trackAnswerSelection({
          questionType: 'gospel_group',
          action: 'group_loaded',
          userId: effectiveUserId,
          userType: userId ? 'registered' : 'anonymous',
          groupId: gospelGroup.id,
          groupName: gospelGroup.name,
          date: targetDate.toISOString()
        });
      } else {
        setError('No Gospel content available for this date.');
      }
      

      // 4. Get all available readings for progress calculation
      // Format date in Pacific time
      const options = { 
        timeZone: 'America/Los_Angeles',
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      };
      const pacificDate = targetDate.toLocaleDateString('en-US', options);
      // Convert from MM/DD/YYYY to YYYY-MM-DD
      const [month, day, year] = pacificDate.split('/');
      const dateStr = `${year}-${month}-${day}`;
      const readingsResponse = await getAvailableReadings(dateStr, effectiveUserId);


      // Process readings to properly format topics and ensure all readings have descriptions
      const processedReadings = readingsResponse.readings.map(reading => {
        // Process topics if needed
        let topics = [];
        if (reading.topics) {
          if (typeof reading.topics === 'string') {
            try {
              topics = JSON.parse(reading.topics);
            } catch (e) {
              topics = [reading.topics];
            }
          } else if (Array.isArray(reading.topics)) {
            topics = reading.topics;
          }
        }
        
        return {
          ...reading,
          topics: topics,
          // Ensure these fields are always present
          description: reading.description || (topics.length > 0 ? topics[0] : ''),
          name: reading.name || `${reading.step_title} Reading`
        };
      });
      
      // 5. Pre-load homily content if parish is selected - always get upcoming Sunday homily
      let updatedReadings = [...processedReadings];
      let updatedTotalReadings = readingsResponse.total_readings;
      let updatedCompletedReadings = readingsResponse.completed_readings;
      let homilyAlreadyIncluded = updatedReadings.some(r => r.step_title === 'Homily');
      
      if (parish && parish.id) {
        // Always get homily for the upcoming Sunday regardless of selected date
        const homily = await getHomily(
          dateStr, // This date will be overridden in getHomily to use upcoming Sunday
          parish.id,
          effectiveUserId
        );
        
        console.log("Pre-loaded upcoming Sunday homily data:", homily);
        
        // Add homily to the available readings list
        if (homily) {
          // Parse homily topics
          const homilyTopics = parseTopics(homily.topics);
          
          // Either update existing homily or add new one
          const homilyIndex = updatedReadings.findIndex(r => r.step_title === 'Homily');
          if (homilyIndex >= 0) {
            // Update existing homily
            updatedReadings[homilyIndex] = {
              ...homily,
              step_title: 'Homily',
              topics: homilyTopics,
              description: homily.description || (homilyTopics.length > 0 ? homilyTopics[0] : '')
            };
            
            // If the completion status changed, update the completed count
            if (updatedReadings[homilyIndex].is_completed !== homily.is_completed) {
              updatedCompletedReadings += (homily.is_completed ? 1 : -1);
            }
          } else {
            // Add new homily
            updatedReadings.push({
              ...homily,
              step_title: 'Homily',
              topics: homilyTopics,
              description: homily.description || (homilyTopics.length > 0 ? homilyTopics[0] : '')
            });
            
            // Update the counts for progress calculation
            if (!homilyAlreadyIncluded) {
              updatedTotalReadings += 1;
              if (homily.is_completed) {
                updatedCompletedReadings += 1;
              }
            }
          }
        }
      }
      
      // Recalculate progress if we added or updated the homily
      let calculatedProgress = (updatedTotalReadings > 0) 
        ? (updatedCompletedReadings / updatedTotalReadings * 100) 
        : 0;
        
      setAvailableReadings(updatedReadings);
      setProgress(homilyAlreadyIncluded ? readingsResponse.progress : Math.round(calculatedProgress * 10) / 10);
      
      // 6. Get trivia stats
      const triviaStatsResponse = await getUserTriviaStats(effectiveUserId);
      if (triviaStatsResponse && triviaStatsResponse.success) {
        setTriviaStats({
          totalCompleted: triviaStatsResponse.total_completed || 0,
          currentStreak: triviaStatsResponse.current_streak || 0
        });
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error initializing user data:', err);
      setError('Failed to load your examen data');
      setLoading(false);
      
      // Track error
      Analytics.trackStepComplete('dashboard_error', {
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        error: 'initialization_error',
        details: err.message || 'Unknown error initializing user data'
      });
    }
  };

  // Format date helpers
  const formatDateFull = (date) => {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const formatDateCompact = (date) => {
    return date.toLocaleDateString('en-US', { 
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Calendar navigation
  const handlePrevMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentMonth(prevMonth);
  };
  
  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };
  
  // Get next Sunday (or return today if today is Sunday)
  const getNextSunday = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 = Sunday
    
    if (dayOfWeek === 0) {
      return now;
    }
    
    const daysUntilSunday = 7 - dayOfWeek;
    const nextSunday = new Date(now);
    nextSunday.setDate(now.getDate() + daysUntilSunday);
    
    return nextSunday;
  };
  
  // Calendar helper functions
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };
  
  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };
  
  // Render calendar days
  const renderCalendarDays = () => {
    const today = new Date();
    const currentYear = currentMonth.getFullYear();
    const currentMonthIndex = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(currentYear, currentMonthIndex);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonthIndex);
    const nextSunday = getNextSunday();
    
    const days = [];
    
    // Add empty cells for days before the first day of month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-8 w-8"></div>);
    }
    
    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonthIndex, day);
      const isToday = date.toDateString() === today.toDateString();
      const isSelected = date.toDateString() === selectedDate.toDateString();
      const isUpcomingSunday = date.toDateString() === nextSunday.toDateString();
      const isSunday = date.getDay() === 0;
      
      // For non-premium users, only the upcoming Sunday is selectable
      // For premium users, all dates are selectable
      const isSelectable = hasPremium || 
                          (isToday && today.getDay() === 0) || // Today is Sunday 
                          isUpcomingSunday; // Is the next upcoming Sunday
      
      // For non-premium, show a lock icon for non-selectable dates
      const showLock = !hasPremium && !isSelectable;
                          
      days.push(
        <div 
          key={day}
          onClick={() => handleDateSelect(date)}
          className={`
            h-8 w-8 flex items-center justify-center rounded-full cursor-pointer text-sm relative
            ${isSelectable ? 'hover:bg-blue-100' : !hasPremium ? 'hover:bg-gray-100' : 'opacity-50 cursor-not-allowed'}
            ${isToday ? 'bg-blue-100 text-blue-800' : ''}
            ${isUpcomingSunday && !isToday ? 'bg-blue-50 text-blue-800 border border-blue-200' : ''}
            ${isSunday && !isToday && !isUpcomingSunday && !isSelected ? 'text-blue-600' : ''}
            ${isSelected ? 'bg-blue-600 text-white' : ''}
            ${!isSelectable && !showLock ? 'opacity-50' : ''}
          `}
        >
          {day}
          {showLock && (
            <Lock className="absolute top-0 right-0 w-2.5 h-2.5 text-gray-400" />
          )}
        </div>
      );
    }
    
    return days;
  };
  

  const handleDateSelect = async (date) => {
    const today = new Date();
    
    // For non-premium users, only allow access to Next Sunday or Today if it's Sunday
    if (!hasPremium) {
      const nextSunday = getNextSunday();
      // Check if selected date is the next upcoming Sunday or today (if today is Sunday)
      const isNextSunday = date.toDateString() === nextSunday.toDateString();
      const isTodayAndSunday = date.toDateString() === today.toDateString() && today.getDay() === 0;
      
      if (!isNextSunday && !isTodayAndSunday) {
        setShowPremiumPrompt(true);
        return; // Don't close the calendar when showing premium prompt
      }
    }
    
    try {
      setLoading(true);
      setSelectedDate(date);
      setIsCalendarOpen(false);
      
      // Track date change
      Analytics.trackAnswerSelection({
        questionType: 'date_selection',
        action: 'date_changed',
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        date: date.toISOString()
      });
      
      // Get Gospel group for the new date
      const gospelGroup = await getCurrentGospelGroup(
        effectiveUserId, 
        hasPremium ? 'premium' : null,
        date
      );
      
      if (gospelGroup) {
        // Process topics from the gospel group
        const parsedTopics = parseTopics(gospelGroup.topics);
        
        // Create a complete object with all required fields
        const completeGospelGroup = {
          ...gospelGroup,
          topics: parsedTopics,
          // Add a description if one doesn't exist
          description: gospelGroup.description || (parsedTopics.length > 0 ? parsedTopics[0] : '')
        };
        
        setCurrentGospelGroup(completeGospelGroup);
        
        // Check if the group is completed
        if (completeGospelGroup.id) {
          const completion = await checkGroupCompletion(completeGospelGroup.id, effectiveUserId);
          if (completion.isCompleted) {
            setCurrentGospelGroup(prevGroup => ({
              ...prevGroup,
              isCompleted: true
            }));
          }
        }
      } else {
        setError('No Gospel content available for this date.');
      }
      

      // Update available readings and progress
      // Format date in Pacific time
      const options = { 
        timeZone: 'America/Los_Angeles',
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      };
      const pacificDate = date.toLocaleDateString('en-US', options);
      // Convert from MM/DD/YYYY to YYYY-MM-DD
      const [month, day, year] = pacificDate.split('/');
      const dateStr = `${year}-${month}-${day}`;
      const readingsResponse = await getAvailableReadings(dateStr, effectiveUserId);


      // Process readings to ensure proper format
      const processedReadings = readingsResponse.readings.map(reading => {
        // Parse topics if needed
        let topics = [];
        if (reading.topics) {
          if (typeof reading.topics === 'string') {
            try {
              topics = JSON.parse(reading.topics);
            } catch (e) {
              topics = [reading.topics];
            }
          } else if (Array.isArray(reading.topics)) {
            topics = reading.topics;
          }
        }
        
        return {
          ...reading,
          topics: topics,
          // Ensure name and description are present
          name: reading.name || `${reading.step_title} Reading`,
          description: reading.description || (topics.length > 0 ? topics[0] : '')
        };
      });
      
      // Get the current homily and its date if it exists
      const currentHomily = availableReadings.find(r => r.step_title === 'Homily');
      
      // Calculate the upcoming Sunday date
      const now = new Date();
      const todayDayOfWeek = now.getDay(); // 0 = Sunday
      const daysUntilSunday = todayDayOfWeek === 0 ? 0 : 7 - todayDayOfWeek;
      const upcomingSunday = new Date(now);
      upcomingSunday.setDate(now.getDate() + daysUntilSunday);
      upcomingSunday.setHours(0, 0, 0, 0);  // Reset time component for comparison
      
      // Clean date for comparison
      const selectedDateCopy = new Date(date);
      selectedDateCopy.setHours(0, 0, 0, 0);
      
      // Create new readings array
      let newReadings = [...processedReadings];
      let updatedTotalReadings = readingsResponse.total_readings;
      let updatedCompletedReadings = readingsResponse.completed_readings;
      let homilyAlreadyIncluded = newReadings.some(r => r.step_title === 'Homily');
      
      // Only include homily if the selected date is NOT beyond the upcoming Sunday
      if (currentHomily && selectedDateCopy <= upcomingSunday) {
        // Check if homily exists in new readings
        const homilyIndex = newReadings.findIndex(r => r.step_title === 'Homily');
        
        if (homilyIndex >= 0) {
          // Replace existing homily
          const oldHomilyCompleted = newReadings[homilyIndex].is_completed;
          newReadings[homilyIndex] = currentHomily;
          
          // Update completion count if status changed
          if (!homilyAlreadyIncluded && oldHomilyCompleted !== currentHomily.is_completed) {
            updatedCompletedReadings += (currentHomily.is_completed ? 1 : -1);
          }
        } else {
          // Add homily if not present
          newReadings.push(currentHomily);
          
          // Update counts for progress calculation
          if (!homilyAlreadyIncluded) {
            updatedTotalReadings += 1;
            if (currentHomily.is_completed) {
              updatedCompletedReadings += 1;
            }
          }
        }
      }
      

     let calculatedProgress = (updatedTotalReadings > 0) 
       ? (updatedCompletedReadings / updatedTotalReadings * 100) 
       : 0;
     
     // Update state with the processed readings
     setAvailableReadings(newReadings);
     setProgress(homilyAlreadyIncluded ? readingsResponse.progress : Math.round(calculatedProgress * 10) / 10);
     
     setLoading(false);
   } catch (err) {
     console.error('Error changing date:', err);
     setError('Failed to load content for the selected date');
     setLoading(false);
     
     // Track error
     Analytics.trackStepComplete('dashboard_error', {
       userId: effectiveUserId,
       userType: userId ? 'registered' : 'anonymous',
       error: 'date_change_error',
       details: err.message || 'Unknown error changing date'
     });
   }
 };

 const handleGoToSunday = () => {
   const nextSunday = getNextSunday();
   handleDateSelect(nextSunday);
 };
 
 // Handle the "Go to Today" action
 const handleGoToToday = () => {
   const today = new Date();
   
   // If user doesn't have premium and today is not Sunday, show premium prompt
   if (!hasPremium && today.getDay() !== 0) {
     setShowPremiumPrompt(true);
     return;
   }
   
   handleDateSelect(today);
 };
 


  // Handle reading type selection
  const handleReadingChange = async (reading) => {
    // Check if it's Sunday
    const isSunday = selectedDate.getDay() === 0;
    
    // Allow first and second readings for freemium users on Sundays
    if (!hasPremium && reading.readingType !== 'Gospel' && reading.readingType !== 'Homily' && 
        !(isSunday && (reading.readingType === 'First' || reading.readingType === 'Second'))) {
      // Show premium prompt only if not Sunday or not a valid reading type
      setShowPremiumPrompt(true);
      return;
    }
    
    try {
      // Track reading selection
      Analytics.trackAnswerSelection({
        questionType: 'reading_selection',
        action: 'reading_changed',
        userId: effectiveUserId,
        readingType: reading.readingType,
        date: reading.date
      });
      
      // Special handling for Homily - need to get community-specific homily
      if (reading.readingType === 'Homily') {
        if (activeParish) {
          // Get community-specific homily
          const homily = await getHomily(
            selectedDate.toISOString().split('T')[0], 
            activeParish.id, 
            effectiveUserId
          );
          
          if (homily) {
            // Process topics from homily
            const parsedTopics = parseTopics(homily.topics);
            
            // Set the homily as current group
            setCurrentGospelGroup({
              ...homily,
              name: `${activeParish.name} - Homily`,
              date: selectedDate.toISOString().split('T')[0],
              step_title: 'Homily',
              topics: parsedTopics,
              description: homily.description || (parsedTopics.length > 0 ? parsedTopics[0] : '')
            });
            return;
          } else {
            // No homily found for this parish
            setCurrentGospelGroup({
              id: null,
              name: `${activeParish.name} - Homily`,
              difficulty: 'advanced',
              include_source_text: true,
              trivia_question_count: 0,
              date: selectedDate.toISOString().split('T')[0],
              step_title: 'Homily',
              community_id: activeParish.id,
              isCompleted: false,
              isPlaceholder: true,
              noReadingMessage: `No homily available for ${activeParish.name} on this date.`
            });
            return;
          }
        } else {
          // No parish selected
          setCurrentGospelGroup({
            id: null,
            name: 'Homily',
            difficulty: 'advanced',
            include_source_text: true,
            trivia_question_count: 0,
            date: selectedDate.toISOString().split('T')[0],
            step_title: 'Homily',
            isCompleted: false,
            isPlaceholder: true,
            noReadingMessage: 'Please select a parish to view homily content.'
          });
          return;
        }
      }
      
      // Standard reading selection logic for non-Homily readings
      const readingTypeMap = {
        'First': 'FirstReading',
        'Second': 'SecondReading',
        'Gospel': 'Gospel',
        'Homily': 'Homily'
      };
      
      const mappedReadingType = readingTypeMap[reading.readingType] || reading.readingType;
      
      console.log("Looking for reading with step_title:", mappedReadingType);
      
      // Find the reading in available readings with the mapped type
      const selectedReading = availableReadings.find(r => 
        r.step_title === mappedReadingType
      );
      
      console.log("Selected reading:", selectedReading);
      
      if (selectedReading && selectedReading.id) {
        // Check if this specific reading has been completed
        const completionStatus = await checkGroupCompletion(selectedReading.id, effectiveUserId);
        
        // Process topics from the selected reading
        let topics = [];
        if (selectedReading.topics) {
          if (typeof selectedReading.topics === 'string') {
            try {
              topics = JSON.parse(selectedReading.topics);
            } catch (e) {
              topics = [selectedReading.topics];
            }
          } else if (Array.isArray(selectedReading.topics)) {
            topics = selectedReading.topics;
          }
        }
        
        // Update current gospel group with the selected reading
        setCurrentGospelGroup({
          id: selectedReading.id,
          name: selectedReading.name,
          difficulty: selectedReading.difficulty || 'advanced',
          include_source_text: selectedReading.include_source_text || true,
          trivia_question_count: selectedReading.trivia_question_count || 6,
          date: selectedReading.date || selectedDate.toISOString().split('T')[0],
          step_title: selectedReading.step_title,
          community_id: selectedReading.community_id || 389,
          isCompleted: completionStatus.isCompleted || selectedReading.is_completed || false,
          topics: topics,
          description: selectedReading.description || (topics.length > 0 ? topics[0] : '')
        });
        
        console.log("Updated current gospel group:", {
          id: selectedReading.id,
          name: selectedReading.name,
          step_title: selectedReading.step_title
        });
      } else {
        // Handle case when reading is not available for this day
        const readingNames = {
          'FirstReading': 'First Reading',
          'SecondReading': 'Second Reading',
          'Gospel': 'Gospel',
          'Homily': 'Homily'
        };
        
        const readingName = readingNames[mappedReadingType] || mappedReadingType;
        
        // Special message for Homily
        let noReadingMessage = `No ${readingName} available for today's Mass.`;
        if (mappedReadingType === 'Homily') {
          noReadingMessage = 'Homily is only available on Sundays.';
        }
        
        // Create a placeholder group with a message indicating no reading is available
        setCurrentGospelGroup({
          id: null, // Use null ID to indicate this is a placeholder
          name: `${readingName} - ${formatDateFull(selectedDate)}`,
          difficulty: 'advanced',
          include_source_text: true,
          trivia_question_count: 0,
          date: selectedDate.toISOString().split('T')[0],
          step_title: mappedReadingType,
          community_id: 389,
          isCompleted: false,
          // Add a special flag to indicate this is a placeholder
          isPlaceholder: true,
          noReadingMessage: noReadingMessage
        });
        
        console.log("Created placeholder for unavailable reading:", mappedReadingType);
      }
    } catch (error) {
      console.error('Error handling reading selection:', error);
      
      // Track error
      Analytics.trackStepComplete('dashboard_error', {
        userId: effectiveUserId,
        error: 'reading_selection_error',
        details: error.message || 'Unknown error handling reading selection'
      });
    }
  };



 // Handle starting trivia
 const handleStartTrivia = () => {
   // Log the current gospel group for debugging
   console.log("Starting trivia with group:", currentGospelGroup);
   
   if (!currentGospelGroup || currentGospelGroup.isPlaceholder) {
     console.error("Cannot start trivia - group is null or placeholder");
     return;
   }

   // Track starting trivia
   Analytics.trackStepStart('trivia', {
     userId: effectiveUserId,
     userType: userId ? 'registered' : 'anonymous',
     groupId: currentGospelGroup.id,
     groupName: currentGospelGroup.name,
     readingType: currentGospelGroup.step_title
   });
   
   // Navigate to trivia with necessary parameters
   navigate('/trivia', { 
     state: { 
       modeId: currentGospelGroup.id, 
       modeName: currentGospelGroup.name, 
       difficulty: currentGospelGroup.difficulty || 'advanced',
       language: 'english',
       questionCount: currentGospelGroup.trivia_question_count || 6,
       durationSeconds: 120,
       showSourceText: currentGospelGroup.include_source_text !== false,
       fromHome: false,
       userId: effectiveUserId,
       groupId: currentGospelGroup.id, 
       isReflection: false,
       readingType: currentGospelGroup.step_title,
       communityId: currentGospelGroup.community_id || activeParish?.id
     }
   });
 };



 // Handle continue trivia (if in progress)
 const handleContinueTrivia = () => {
   handleStartTrivia(); // Same behavior for now
 };


 // Handler for when a parish is joined or mass time is selected
 const handleParishJoined = async (parish) => {
   // Set the parish as active
   if (parish.selectedMass) {
     await setUserActiveParish(effectiveUserId, parish.id, parish.selectedMass.id);
   } else {
     await setUserActiveParish(effectiveUserId, parish.id);
   }
   
   setActiveParish(parish);
   
   // If currently viewing homily, reload it with new parish
   if (currentGospelGroup && currentGospelGroup.step_title === 'Homily') {
     handleReadingChange({
       readingType: 'Homily',
       date: selectedDate
     });
   }
   
   // Refresh the parishes list
   loadUserParishes();
   
   // Track analytics
   Analytics.trackAnswerSelection({
     questionType: 'parish_selection',
     action: 'parish_joined',
     userId: effectiveUserId,
     userType: userId ? 'registered' : 'anonymous',
     parishId: parish.id,
     parishName: parish.name,
     massTimeId: parish.selectedMass?.id
   });
 };


 // Render parish selector dropdown
 const renderParishSelector = () => {
   if (!hasPremium) return null; // Only show for premium users
   
   return (
     <div className="mt-4 mb-4" ref={parishDropdownRef}>
       <div className="text-sm font-medium text-gray-700 mb-2">Your Parish & Mass Time:</div>
       
       <div className="relative">
         <button
           onClick={() => setIsParishDropdownOpen(!isParishDropdownOpen)}
           className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
         >
           <div className="flex items-center">
             <Church className="h-4 w-4 text-gray-500 mr-2" />
             <span>
               {activeParish ? (
                 <span className="flex items-center">
                   {activeParish.name}
                   {activeParish.selectedMass && (
                     <span className="text-gray-500 ml-2 text-xs flex items-center">
                       <Clock className="h-3 w-3 mr-1" />
                       {activeParish.selectedMass.dayOfWeek === 'Saturday Vigil' 
                         ? 'Sat Vigil' 
                         : activeParish.selectedMass.dayOfWeek.substring(0, 3)}: {activeParish.selectedMass.startTime}
                     </span>
                   )}
                 </span>
               ) : (
                 userParishes.length ? 'Select a parish' : 'No parishes joined'
               )}
             </span>
           </div>
           <ChevronDown className="h-4 w-4 text-gray-500" />
         </button>
         
         {/* Parish dropdown menu */}
         {isParishDropdownOpen && (
           <div className="absolute mt-1 w-full z-10 bg-white border border-gray-300 rounded-md shadow-lg">
             <ul className="max-h-60 rounded-md py-1 overflow-auto focus:outline-none text-sm">
               {userParishes.length === 0 ? (
                 <li className="text-gray-500 py-2 px-3">
                   No parishes found
                 </li>
               ) : (
                 userParishes.map(parish => (
                   <li
                     key={parish.id}
                     className={`cursor-pointer select-none relative py-2 px-3 hover:bg-gray-100 ${
                       activeParish && activeParish.id === parish.id ? 'bg-blue-50 text-blue-700' : ''
                     }`}
                     onClick={() => handleParishSelect(parish)}
                   >
                     <div>
                       {parish.name}
                       {parish.selectedMass && (
                         <div className="text-xs text-gray-500 mt-1 flex items-center">
                           <Clock className="h-3 w-3 mr-1" />
                           {parish.selectedMass.dayOfWeek}: {parish.selectedMass.startTime}
                         </div>
                       )}
                     </div>
                   </li>
                 ))
               )}
             </ul>
             
             {/* Option to manage mass times */}
             {activeParish && (
               <div className="border-t border-b border-gray-200 py-2 px-3">
                 <button
                   className="text-blue-600 hover:text-blue-800 text-sm font-medium w-full text-left"
                   onClick={() => {
                     setIsParishDropdownOpen(false);
                     setShowMassTimeSelector(true);
                   }}
                 >
                   Change mass time for {activeParish.name}
                 </button>
               </div>
             )}
             
             {/* Option to find more parishes */}
             <div className="border-t border-gray-200 py-2 px-3">
               <button
                 className="text-blue-600 hover:text-blue-800 text-sm font-medium w-full text-left"
                 onClick={() => {
                   setIsParishDropdownOpen(false);
                   setShowParishFinder(true);
                 }}
               >
                 Find the parish homily where you're attending this weekend
               </button>
             </div>
           </div>
         )}
       </div>
     </div>
   );
 };

 return (
   <div className="min-h-screen bg-gray-50">
     <Header />

     <main className="max-w-2xl sm:max-w-3xl lg:max-w-4xl mx-auto px-4 py-4 sm:py-6">
       {/* Header */}
       <div className="flex justify-between items-center mb-4 sm:mb-6">
         <h1 className="text-xl sm:text-2xl font-bold text-gray-900">
           {userSubscription === 'premium' ? 'Daily Gospel Examen' : 'Weekly Gospel Examen'}
         </h1>
       </div>

       {/* Add Premium Modal */}
       {showPremiumPrompt && (
         <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center p-4">
           <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
             <h3 className="text-lg font-semibold text-gray-900 mb-2">
               Premium Feature
             </h3>
             <p className="text-gray-600 mb-4">
               Access to all reading types and dates requires a premium subscription.
             </p>
             <div className="flex justify-end space-x-3">
               <button 
                 onClick={() => setShowPremiumPrompt(false)} 
                 className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
               >
                 Cancel
               </button>
               <button 
                 onClick={() => {
                   // Track upgrade click
                   Analytics.trackAnswerSelection({
                     questionType: 'premium_prompt',
                     action: 'upgrade_click',
                     userId: effectiveUserId
                   });
                   
                   // Navigate to account page
                   navigate('/account');
                   setShowPremiumPrompt(false);
                 }} 
                 className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
               >
                 Upgrade Now
               </button>
             </div>
           </div>
         </div>
       )}

       {loading ? (
         <div className="text-center py-10 sm:py-12">
           <div className="animate-spin h-8 w-8 sm:h-10 sm:w-10 text-blue-600 mx-auto mb-4 border-4 border-blue-200 border-t-blue-600 rounded-full"></div>
           <p className="text-gray-600 text-sm sm:text-base">Loading your examen journey...</p>
         </div>
       ) : error ? (
         <div className="text-center py-10 sm:py-12">
           <p className="text-red-600 text-sm sm:text-base">{error}</p>
         </div>
       ) : currentGospelGroup ? (
         <>
           {/* Calendar Card - separate div */}
           <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden mb-6">
             <CalendarCard
               hasPremium={hasPremium}
               selectedDate={selectedDate}
               isCalendarOpen={isCalendarOpen}
               setIsCalendarOpen={setIsCalendarOpen}
               formatDateCompact={formatDateCompact}
               calendarRef={calendarRef}
               currentMonth={currentMonth}
               handlePrevMonth={handlePrevMonth}
               handleNextMonth={handleNextMonth}
               renderCalendarDays={renderCalendarDays}
               handleGoToToday={handleGoToToday}
               handleGoToSunday={handleGoToSunday}
             />
           </div>

           {/* Stats & Progress Card */}
           <div className="bg-white rounded-t-xl border border-gray-200 shadow-sm overflow-hidden mb-0 border-b-0">
             <div className="px-4 py-3">
               <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                 <div>
                   <h1 className="text-xl font-bold text-gray-900">
                     {hasPremium ? "Daily Examen" : "Sunday Examen"}
                   </h1>
                   <p className="text-sm text-gray-500">{formatDateFull(selectedDate)}</p>
                 </div>
                 
                 <div className="flex space-x-3 mt-2 sm:mt-0">
                   <div className="flex items-center bg-blue-50 border border-blue-100 rounded-lg px-3 py-1">
                     <Clock className="text-blue-600 h-4 w-4 mr-1" />
                     <div>
                       <span className="text-sm font-bold text-blue-600">{triviaStats.currentStreak}</span>
                       <span className="text-xs text-blue-600 ml-1">Week Streak</span>
                     </div>
                   </div>
                   
                   <div className="flex items-center bg-green-50 border border-green-100 rounded-lg px-3 py-1">
                     <CheckCircle className="text-green-600 h-4 w-4 mr-1" />
                     <div>
                       <span className="text-sm font-bold text-green-600">{triviaStats.totalCompleted}</span>
                       <span className="text-xs text-green-600 ml-1">Completed</span>
                     </div>
                   </div>
                 </div>
               </div>
               
               {/* Progress bar */}
               <div className="mt-3">
                 <div className="flex mb-1.5 items-center justify-between">
                   <div>
                     <span className="text-xs font-semibold inline-block text-blue-600">
                       Your progress
                     </span>
                   </div>
                   <div>
                     <span className="text-xs font-semibold inline-block text-blue-600">
                       {progress}%
                     </span>
                   </div>
                 </div>
                 <div className="w-full bg-gray-200 rounded-full h-2.5">
                   <div 
                     className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out" 
                     style={{ width: `${progress}%` }}
                   ></div>
                 </div>
               </div>
             </div>
           </div>

           {/* Reading Selector Card */}
           <div className="bg-white rounded-b-xl border border-gray-200 shadow-sm overflow-hidden mb-6">
             <div className="px-4 py-3">
               <ReadingSelector
                 userId={effectiveUserId}
                 communityId={activeParish ? activeParish.id : 389}
                 selectedDate={selectedDate}
                 onReadingChange={handleReadingChange}
                 hasPremium={hasPremium}
                 onPremiumPrompt={() => setShowPremiumPrompt(true)}
                 currentGospelGroup={currentGospelGroup}
                 availableReadings={availableReadings.filter(r => r.step_title !== 'Homily')}
                 onStartTrivia={handleStartTrivia}
                 onContinueTrivia={handleContinueTrivia}
                 excludeHomily={true} // Exclude homily from this selector
               />
             </div>
           </div>
           

           {!hasPremium && (
             <UpgradePrompt userId={effectiveUserId} />
           )}

           {/* Parish Finder Modal */}
           {showParishFinder && (
             <ParishFinder
               isOpen={showParishFinder}
               onClose={() => setShowParishFinder(false)}
               onParishJoined={handleParishJoined}
             />
           )}

           
           {/* Administrator Section - Only show if user is an admin of any communities */}
           <AdminCommunitiesSection userId={effectiveUserId} />

           {userSubscription === 'admin' && !userHasAdminCommunities && (
             <CreateCommunitySection userId={effectiveUserId} />
           )}

           {/* Community Trivia Section - Only show if user has communities */}
           {!checkingCommunities && userHasCommunities && (
             <CommunityTriviaSection userId={effectiveUserId} />
           )}
                      
           {/* Upcoming Examen Section */}
           {upcomingExamen && (
             <UpcomingExamen examen={upcomingExamen} />
           )}


           {/* Mass Time Selector Modal */}
           {showMassTimeSelector && activeParish && (
             <MassTimeSelector
               isOpen={showMassTimeSelector}
               onClose={() => setShowMassTimeSelector(false)}
               parish={activeParish}
               onMassTimeChanged={handleParishJoined}
             />
           )}
         </>
       ) : (
         <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 sm:p-8 text-center">
           <div className="h-12 w-12 mx-auto text-gray-400 mb-3 sm:mb-4">📚</div>
           <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-2">No Active Examen</h3>
           <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">You don't have an active examen yet.</p>
           <button 
             onClick={() => initializeUserData()}
             className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm sm:text-base"
           >
             Refresh
             <ChevronRight className="ml-2 h-4 w-4" />
           </button>
         </div>
       )}
     </main>
   </div>
 );
};

export default UserDashboard;
