import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
// Import pages
import Authenticate from './pages/Authenticate/Authenticate';
import Home from './pages/Home/Home';
import Trivia from './pages/Trivia/Trivia';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import InviteHandler from './components/InviteHandler/InviteHandler';
import Account from './pages/Account/Account';
// Import new password reset components
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';
import CommunityInvitationPage from './pages/CommunityInvitationPage/CommunityInvitationPage';
import CommunityAdminDashboard from './pages/CommunityAdminDashboard/CommunityAdminDashboard';
import LegacyRouteHandler from './components/LegacyRouteHandler/LegacyRouteHandler';

function App() {
  return (
    <Router>
      <GoogleAnalytics />
      <div className="app">
        <Routes>
          {/* Home route - handles both "/" and "/home" */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/invite" element={<CommunityInvitationPage />} />
          <Route path="/trivia" element={<Trivia />} />
          <Route path="/community-invite" element={<CommunityInvitationPage />} />
          <Route path="/community-admin/:communityId" element={<CommunityAdminDashboard />} />

          <Route path="/account" element={<Account />} />
          
          {/* Password Reset Routes */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
                    
          {/* User Dashboard Routes - Main routes */}
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/user-dashboard" element={<UserDashboard />} />
          
          {/* Legacy route handlers - These set the community ID as active and redirect */}
          <Route path="/dashboard/:communityId" element={<LegacyRouteHandler />} />
          <Route path="/user-dashboard/:communityId" element={<LegacyRouteHandler />} />
          <Route path="/user-dashboard/:communityId/:journeyId" element={<LegacyRouteHandler />} />
            
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;