// Create a simple component to handle legacy routes
// LegacyRouteHandler.jsx

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { setUserActiveParish } from '../../services/dashboardService';
import { useSelector } from 'react-redux';

const LegacyRouteHandler = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const { userId } = useSelector((state) => state.user);
  
  useEffect(() => {
    const handleLegacyRoute = async () => {
      // If we have both a communityId and userId, set this as the active parish
      if (communityId && userId) {
        try {
          await setUserActiveParish(userId, communityId);
          console.log(`Set community ${communityId} as active for user ${userId}`);
        } catch (error) {
          console.error('Error setting active parish from legacy route:', error);
        }
      }
      
      // Then redirect to the main dashboard
      navigate('/dashboard', { replace: true });
    };
    
    handleLegacyRoute();
  }, [communityId, userId, navigate]);
  
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center">
        <div className="animate-spin h-10 w-10 border-4 border-blue-600 border-t-transparent rounded-full mx-auto mb-4"></div>
        <p className="text-gray-600">Redirecting to dashboard...</p>
      </div>
    </div>
  );
};

export default LegacyRouteHandler;