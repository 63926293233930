import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, ArrowRight, ChevronDown, ChevronUp, Shield, Calendar, Book, CheckCircle } from 'lucide-react';
import Analytics from '../../services/analytics';

/**
 * UpgradePrompt - Component that redirects to the account page for upgrade
 * Updated with more subtle styling to reduce visual prominence
 */
const UpgradePrompt = ({ userId }) => {
  const [expanded, setExpanded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Check URL parameters for success status
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('subscription') === 'success') {
      setSuccess(true);
      // Remove the query parameter from URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
  }, []);

  // Handle upgrade button click - redirect to account page
  const handleUpgradeClick = () => {
    // Track upgrade click
    Analytics.trackAnswerSelection({
      questionType: 'premium_action',
      action: 'upgrade_click',
      userId,
      source: 'dashboard_upgrade_prompt'
    });
    
    setIsProcessing(true);
    
    // Add a small delay to show the processing state
    setTimeout(() => {
      // Navigate to account page with upgrade parameter
      navigate('/account?upgrade=true#subscription');
    }, 500);
  };
  
  // Success message component
  const renderSuccessMessage = () => {
    if (!success) return null;
    
    return (
      <div className="bg-green-50 border border-green-200 rounded-lg p-3 animate-fadeIn">
        <div className="flex items-start">
          <CheckCircle className="h-4 w-4 text-green-600 mr-2 flex-shrink-0" />
          <div>
            <h4 className="font-medium text-green-800 text-sm">Subscription Activated!</h4>
            <p className="text-xs text-green-700 mt-0.5">
              Your premium subscription is now active. Enjoy access to all readings!
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 rounded-lg border border-gray-200 p-2.5 mb-4 shadow-sm overflow-hidden mt-4 text-sm">
      {/* Success message */}
      {renderSuccessMessage()}
      
      {/* Only show main content if not in success state */}
      {!success && (
        <>
          {/* Header with toggle */}
          <div 
            className="flex items-start cursor-pointer" 
            onClick={() => setExpanded(!expanded)}
          >
            <div className="bg-gray-100 rounded-full p-1 mr-2">
              <Sparkles className="h-3.5 w-3.5 text-gray-500" />
            </div>
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-sm font-medium text-gray-700">Upgrade to Premium</h3>
                  <p className="text-xs text-gray-500 mt-0.5">
                    Access all Daily Mass Readings (Gospel, First Reading, and Second) up to a week in advance. Go from Weekly Sunday Scripture to Daily Scripture Superstar.
                  </p>
                  <p className="text-xs text-gray-600 mt-1 font-medium">
                    Just $5/month
                  </p>
                </div>
                <button 
                  className="text-gray-400 hover:text-gray-600 p-1 flex-shrink-0"
                  aria-label={expanded ? "Collapse premium details" : "Expand premium details"}
                >
                  {expanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                </button>
              </div>
            </div>
          </div>
          
          {/* Expandable content - now a single column */}
          {expanded && (
            <div className="mt-2 pt-2 border-t border-gray-200">
              <div className="bg-white rounded-md border border-gray-200 p-2.5">
                <div className="flex items-center mb-1.5">
                  <Calendar className="h-3.5 w-3.5 text-gray-500 mr-1.5" />
                  <h4 className="font-medium text-gray-600 text-xs">Daily Scripture Superstar Access</h4>
                </div>
                <ul className="text-xs text-gray-500 space-y-1 pl-5 list-disc">
                  <li>Daily Access with ability to view readings up to a week in advance</li>
                  <li>Daily Gospel, First, and Second Readings</li>
                  <li>Saint's interpretations and historical context</li>
                  <li>Applying Daily Scripture to Life Situations</li>
                  <li>Complete access to all daily and Sunday content</li>
                </ul>
              </div>
              
              <div className="mt-2 p-2 bg-gray-50 rounded-md flex items-start">
                <Shield className="h-3 w-3 text-gray-400 mt-0.5 mr-1.5 flex-shrink-0" />
                <div className="text-xs text-gray-500">
                  <p className="font-medium mb-0.5">Premium benefits</p>
                  <p>Your premium subscription gives you full access to all content. Manage your subscription anytime in your account settings.</p>
                </div>
              </div>
              
              {/* Upgrade button at bottom of expanded content */}
              <button 
                onClick={handleUpgradeClick}
                disabled={isProcessing}
                className="w-full mt-2.5 px-3 py-1.5 bg-gray-600 text-white rounded-md hover:bg-gray-700 text-xs font-medium flex items-center justify-center disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {isProcessing ? (
                  <>
                    <div className="h-3 w-3 border-2 border-white border-t-transparent rounded-full animate-spin mr-1.5"></div>
                    Redirecting...
                  </>
                ) : (
                  <>
                    Upgrade Now
                    <ArrowRight className="ml-1.5 h-3 w-3" />
                  </>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UpgradePrompt;
