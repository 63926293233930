// Manually set this to 'development' or 'production'
const CURRENT_ENV = 'production';

const API_BASE_URLS = {
  development: {
    5000: 'http://localhost:5000',
    5002: 'http://localhost:5002',
    5003: 'http://localhost:5003',
    5064: 'http://localhost:5064',
    5063: 'http://localhost:5063',

  },


  production: {
    5000: 'https://examenai.com',
    5002: 'https://examenai.com',
    5003: 'https://examenai.com', 
    5064: 'https://examenai.com',
    5063: 'https://examenai.com',

  },

  mixed: {
    5000: 'http://localhost:5000',  // Production
    5002: 'http://localhost:5002',  // Production
    5003: 'http://localhost:5003',  // Development - info-socket
    5064: 'http://localhost:5064',

  }
};

const CURRENT_BASE_URLS = API_BASE_URLS[CURRENT_ENV];

export const API_ENDPOINTS = {

  GET_RTC_TOKEN: `${CURRENT_BASE_URLS[5002]}/api/get_token`, // Add this line
  STORE_CONTEXT: `${CURRENT_BASE_URLS[5002]}/store-context`,
  TRIVIA_PROGRESS: `${CURRENT_BASE_URLS[5002]}/api/trivia-progress`,
  TEXT_SOCKET: `wss://examenai.com/text-socket`,
  INFO_SOCKET: `wss://examenai.com/info-socket`, // For production

  //TEXT_SOCKET: `ws://localhost:5002/text-socket`,


  USER_INFORMATION: `${CURRENT_BASE_URLS[5000]}/user-information`,
  LOGIN: `${CURRENT_BASE_URLS[5000]}/login`,
  GET_USER_ID: `${CURRENT_BASE_URLS[5000]}/get_user_id`,
  SIGNUP: `${CURRENT_BASE_URLS[5000]}/register`,
  CONVERT_TEMP_USER: `${CURRENT_BASE_URLS[5000]}/register/convert-temp-user`,  // Add this line
  TEMP_USER_COMPLETE: `${CURRENT_BASE_URLS[5000]}/register/temp-user-complete`,

  REQUEST_PASSWORD_RESET: `${CURRENT_BASE_URLS[5000]}/register/request-password-reset`,
  RESET_PASSWORD: `${CURRENT_BASE_URLS[5000]}/register/reset-password`,
  CHANGE_PASSWORD: `${CURRENT_BASE_URLS[5000]}/register/change-password`,
  

  // Gospel group and reading endpoints
  GET_CURRENT_GOSPEL_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/current-gospel`,
  CHECK_GROUP_COMPLETION: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/:groupId/completion`,
  GET_AVAILABLE_READINGS: `${CURRENT_BASE_URLS[5064]}/api/communities/readings/available`,
  SUBMIT_PARISH_INTEREST: `${CURRENT_BASE_URLS[5064]}/api/communities/parish-interest`,
  UPDATE_PERSONAL_CONTEXT: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/personal-context`,
  GET_PERSONAL_CONTEXT: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/personal-context`,
  
  // User trivia stats
  GET_USER_TRIVIA_STATS: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/trivia-stats`,

  // Group completion and trivia endpoints
  CHECK_GROUP_COMPLETION: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/:groupId/completion`,
  INITIALIZE_GROUP_TRIVIA: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/:groupId/initialize-trivia`,
  
  // Community-specific endpoints
  GET_COMMUNITY_DETAILS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId`,
  GET_COMMUNITY_INVITE_QR: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/invite-qr`,
  JOIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join`,
  GET_UPCOMING_COMMUNITY_TRIVIA: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/upcoming-trivia`,
  GET_COMMUNITY_ADMIN_DASHBOARD: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/admin-dashboard`,
  JOIN_COMMUNITY_PARISH: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join_parish`,


  // User communities and community groups
  GET_USER_MEMBER_COMMUNITIES: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/member-communities`,
  GET_USER_ADMIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/admin-community`,
  GET_COMMUNITY_GROUPS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/groups`,
  
  // Additional community-related endpoints
  GET_ADDITIONAL_ASSESSMENTS: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/additional-assessments`,
  GET_COMMUNITY_ADMIN_GROUPS: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/admin-groups`,
  
  // User parish management endpoints
  GET_USER_ACTIVE_PARISH: `${CURRENT_BASE_URLS[5064]}/api/communities/:userId/active-parish`,
  SET_USER_ACTIVE_PARISH: `${CURRENT_BASE_URLS[5064]}/api/communities/:userId/active-parish`,

  // Community-specific homily endpoint
  GET_COMMUNITY_HOMILY: `${CURRENT_BASE_URLS[5064]}/api/communities/readings/homily`,
    
  SEARCH_PARISHES: `${CURRENT_BASE_URLS[5064]}/api/communities/search`,
  GET_COMMUNITY_MASS_TIMES: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/mass-times`,

  GET_USER_ADMIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:userId/admin-communities`,
  CREATE_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/create`,
  GET_UPCOMING_WEEKENDS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/upcoming-weekends`,
  UPDATE_HOMILY_DESCRIPTION: `${CURRENT_BASE_URLS[5064]}/api/communities/update-homily-description`,
  GENERATE_HOMILY_CONTENT: `${CURRENT_BASE_URLS[5064]}/api/communities/generate-homily-content`,

  GET_COMMUNITY_MEMBERS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/members`,
  GET_COMMUNITY_STATS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/stats`,
  GET_COMMUNITY_COMPLETIONS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/completions`,
  GET_PREDEFINED_PARISHES: `${CURRENT_BASE_URLS[5064]}/api/communities/predefined-parishes`,

  CREATE_OR_UPDATE_HOMILY_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/homily-group`,


  // First, make sure GET_SUBSCRIPTION_STATUS is correctly defined:
  GET_SUBSCRIPTION_STATUS: `${CURRENT_BASE_URLS[5063]}/api/users/:userId/subscription`,


  // Additional endpoints that might be needed for subscription management:
  UPDATE_SUBSCRIPTION: `${CURRENT_BASE_URLS[5063]}/api/users/:userId/subscription/update`,
  CREATE_CHECKOUT_SESSION: `${CURRENT_BASE_URLS[5063]}/create-checkout-session`,
  CANCEL_SUBSCRIPTION: `${CURRENT_BASE_URLS[5063]}/cancel-subscription/:userId`,

  CREATE_TRIAL: `${CURRENT_BASE_URLS[5063]}/api/users/:userId/create-trial`,
  GET_TRIAL_STATUS: `${CURRENT_BASE_URLS[5063]}/api/users/:userId/trial-status`,

};
