import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  CheckSquare, 
  Star,
  Check,
  ChevronRight,
  BookOpen,
  Calendar,
  Sparkles
} from 'lucide-react';

import Header from '../../components/Header/Header';
import AuthenticationFlow from '../../components/AuthenticateModal/AuthenticationFlow';
import Trivia from '../Trivia/Trivia';

import { initializeHomePageUser } from '../../store/actions/userActions';
import { getCurrentGospelGroup } from '../../services/dashboardService';
import Analytics from '../../services/analytics';

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;
  
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groupName, setGroupName] = useState("Gospel Reading Examen");
  
  // Create location state for Trivia
  const [triviaLocation, setTriviaLocation] = useState(null);
  const [initialEffectiveUserId, setInitialEffectiveUserId] = useState(effectiveUserId);


  // Add this near the top of your component where other constants are defined
  const readingTitles = {
    1920: "Fulfilling the Law",
    1884: "Jesus Casts Out Demons",
    1886: "Greatest Commandment of Love",
    1888: "Parable of the Pharisee",
    1891: "Parable of Prodigal Son",
    1898: "Royal Official's Son Healed",
    1900: "Healing at Bethesda Pool",
    1902: "Jesus Reveals Divine Authority",
    1904: "Jesus' Witnesses",
    1906: "Jesus at Tabernacles",
    1908: "Division over Jesus' Identity",
    1911: "Raising Lazarus to Life"
  };



  const getModeIdByDate = () => {
    // Define mode IDs for each day
    const modeIds = [
      1884, // March 27, 2025
      1886, // March 28, 2025
      1888, // March 29, 2025
      1891, // March 30, 2025
      1898, // March 31, 2025
      1900, // April 1, 2025
      1902, // April 2, 2025
      1904, // April 3, 2025
      1906, // April 4, 2025
      1908, // April 5, 2025
      1911  // April 6, 2025
    ];
    
    // Get the current date in US Pacific Time
    const now = new Date();
    
    // Convert to US Pacific Time
    const pacificTime = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(now);
    
    // Parse the Pacific date
    const [month, day, year] = pacificTime.split('/').map(num => parseInt(num, 10));
    const pacificDate = new Date(year, month - 1, day);
    
    // Create reference date (March 27, 2025 in Pacific Time)
    const startDate = new Date(2025, 2, 27); // Month is 0-indexed (2 = March)
    
    // Calculate days since reference date
    const daysDiff = Math.floor((pacificDate - startDate) / (1000 * 60 * 60 * 24));
    
    // Get the index in the modeIds array (using modulo for cycling)
    const index = daysDiff % modeIds.length;
    
    // Return the appropriate mode ID
    return modeIds[index < 0 ? 0 : index]; // Safeguard against negative indexes
  };

  useEffect(() => {
    // When component mounts, save the initial effectiveUserId
    if (!initialEffectiveUserId) {
      setInitialEffectiveUserId(effectiveUserId);
    }
    
    // Rest of your existing code...
  }, [dispatch, effectiveUserId, location]);


  // Add this state to store the reading title
  const [readingTitle, setReadingTitle] = useState("Today's Reading");

  // Initialize analytics
  useEffect(() => {
    Analytics.initializeAnalytics();
    console.log("Home component mounted with initial state:", { userId, tempUserId, effectiveUserId });
    
    return () => {
      Analytics.stopEngagementTracking();
    };
  }, []);



  // Initialize user and fetch data
  useEffect(() => {

    const initializeData = async () => {
      try {
        setIsLoading(true);
        
        // Step 1: Ensure we have a user ID
        let currentUserId = effectiveUserId;
        
        if (!currentUserId) {
          console.log("No user ID found, initializing home page user");
          const newTempId = await dispatch(initializeHomePageUser());
          console.log("After initialization, new tempUserId:", newTempId);
          currentUserId = newTempId;
        } else {
          console.log("Using existing user ID:", currentUserId);
        }
        
        // Step 2: Get group name for display
        try {
          const gospelGroup = await getCurrentGospelGroup(currentUserId);
          if (gospelGroup && gospelGroup.name) {
            setGroupName(gospelGroup.name);
          }
        } catch (err) {
          console.log("Error fetching group name, using default");
        }
        
        // Step 3: Set up location with state for Trivia
        const HARDCODED_MODE_ID = 1920;
        const CURRENT_MODE_ID = getModeIdByDate();



        // Set the reading title based on the dynamic mode ID
        setReadingTitle(readingTitles[CURRENT_MODE_ID] || "Today's Reading");

        // Also set the group name to match
        setGroupName(readingTitles[CURRENT_MODE_ID] || "Gospel Examen");
        
        // Track analytics events
        Analytics.trackExamenStart('home_page_automatic');
        
        console.log("Setting up Trivia with:", {
          modeId: HARDCODED_MODE_ID,
          userId: currentUserId
        });
        
        // Create location object for Trivia with proper state
        const locationForTrivia = {
          ...location,
          state: {
            modeId: HARDCODED_MODE_ID,
            modeName: groupName,
            difficulty: "advanced",
            fromReading: false,
            isReflection: false,
            source: "home_page"
          },
          pathname: '/trivia'
        };
        
        setTriviaLocation(locationForTrivia);
        setIsLoading(false);
      } catch (err) {
        console.error('Error initializing data:', err);
        setIsLoading(false);
      }
    };
    
    initializeData();
  }, [dispatch, effectiveUserId, location]);

  // Auth success handler
  const handleAuthSuccess = (userData) => {
    Analytics.trackAuthSuccess(
      userData?.isNewUser ? 'register' : 'login', 
      { isNewUser: userData?.isNewUser || false, source: 'home_page' }
    );
  };

  // Get today's date in a readable format
  const getTodayDate = () => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return new Date().toLocaleDateString('en-US', options);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <Header />
      
      {/* Auth Modal */}
      <AuthenticationFlow 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)}
        onSuccess={handleAuthSuccess}
        initialMode="register"
        planType="weekly"
      />

      {/* Hero Section */}
      <div className="bg-blue-900 text-white px-4 sm:px-6 pt-8 pb-10 sm:py-12">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-2xl sm:text-4xl font-bold mb-3 sm:mb-4 leading-tight">
            Become A Scripture Superstar by Applying Lessons from Catholic Mass Readings to Everyday Life Situations
          </h1>
          <p className="text-base sm:text-lg text-blue-200 mb-6 mx-auto max-w-2xl">
            Take an interactive quiz on daily scripture readings. Easily learn advanced theology lessons through Saint's interpretations and Catholic tradition. Transform your everyday activities with Scripture-powered wisdom.
          </p>  
        </div>
      </div>

      {/* Improved Gospel Reading Title Banner */}
      <div className="relative -mt-6 mb-6">
        <div className="max-w-4xl mx-auto px-4 sm:px-6">
          <div className="bg-gradient-to-r from-indigo-800 to-indigo-600 rounded-xl shadow-lg overflow-hidden">
            <div className="relative z-10 px-6 py-6 sm:py-8 flex flex-col sm:flex-row items-center justify-between">
              <div className="flex items-center mb-4 sm:mb-0 flex-grow">
                <div className="bg-indigo-500/30 p-2.5 rounded-lg mr-4 flex-shrink-0">
                  <BookOpen className="h-6 w-6 text-white" />
                </div>
                <div className="min-w-0 flex-grow">
                  <h2 className="text-xl sm:text-2xl font-bold text-white truncate">{groupName}</h2>
                  <div className="flex items-center text-indigo-200 text-sm mt-1">
                    <Calendar className="h-3.5 w-3.5 mr-1.5 flex-shrink-0" />
                    <span>{getTodayDate()}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 flex-shrink-0 ml-4">
                <div className="flex items-center justify-center bg-indigo-500/30 py-1 px-3 rounded-full text-center">
                  <span className="text-xs font-medium text-indigo-100 whitespace-nowrap">Current Scripture</span>
                </div>
                <div className="flex items-center justify-center bg-indigo-500/20 py-1.5 px-3 rounded-lg text-center">
                  <BookOpen className="h-4 w-4 text-indigo-200 mr-1.5" />
                  <span className="text-sm font-medium text-white whitespace-nowrap">5-minute Interactive Examen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Embedded Trivia Component */}
      <div id="trivia-section" className="max-w-4xl mx-auto px-4 sm:px-6 mb-6">
        {isLoading ? (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex flex-col items-center justify-center">
              <div className="bg-indigo-100 p-3 rounded-full mb-4">
                <CheckSquare className="h-8 w-8 text-indigo-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Catholic Examen</h3>
              <p className="text-gray-600 text-center mb-6 max-w-md">
                Preparing your Scripture exploration. Personalized trivia based on this week's scripture reading.
              </p>
              
              <div className="w-full max-w-md bg-gray-100 h-2 rounded-full overflow-hidden mb-4">
                <div className="bg-indigo-600 h-2 rounded-full animate-pulse" style={{ width: '75%' }}></div>
              </div>
              
              <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
                <div className="animate-spin h-4 w-4 border-2 border-indigo-600 border-t-transparent rounded-full"></div>
                <span>Loading your personalized Examen...</span>
              </div>
            </div>
          </div>
        ) : triviaLocation ? (
          // The key prop forces remounting when dependencies change
          <div key="embedded-trivia-container" className="embedded-trivia-container">

            {/* CSS to hide the Header component inside Trivia */}
            <style>{`
              .embedded-trivia-container > div > header,
              .embedded-trivia-container header,
              .embedded-trivia-container .min-h-screen > header {
                display: none !important;
              }
              
              /* Remove top padding that might be expecting a header */
              .embedded-trivia-container .min-h-screen {
                padding-top: 0 !important;
                min-height: auto !important;
              }
              
              /* Add shadow and rounded corners to embedded trivia */
              .embedded-trivia-container > div {
                border-radius: 0.5rem;
                overflow: hidden;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
              }
            `}</style>
            <Trivia 
              location={triviaLocation}
              navigate={navigate}
            />
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex flex-col items-center justify-center">
              <div className="bg-red-100 p-3 rounded-full mb-4">
                <ChevronRight className="h-8 w-8 text-red-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Unable to Load Gospel Examen</h3>
              <p className="text-gray-600 text-center mb-4 max-w-md">
                We couldn't load your Gospel Examen content. Please try again.
              </p>
              
              <button 
                onClick={() => window.location.reload()}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
              >
                Refresh
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Registration Benefits Section */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 mb-6">
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-100 rounded-xl p-5">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-4 md:mb-0 md:mr-6">
              <h4 className="text-gray-900 font-medium text-lg mb-2 flex items-center">
                <Sparkles className="h-5 w-5 text-indigo-500 mr-2" />
                Create a Free Account for Full Access
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div className="flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm text-gray-700">Sunday Mass Reading Trivia</span>
                </div>
                <div className="flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm text-gray-700">Saint Interpretations of Readings</span>
                </div>
                <div className="flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm text-gray-700">Progress Tracking</span>
                </div>
                <div className="flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm text-gray-700">Life Applications of Scripture</span>
                </div>
                <div className="flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm text-gray-700">Daily Mass Reading Trivia <span className="text-xs text-yellow-600 font-medium ml-1 py-0.5 px-1.5 bg-yellow-100 rounded-full">Premium</span></span>
                </div>
              </div>
            </div>
            <button 
              onClick={() => setShowAuthModal(true)}
              className="px-5 py-2.5 bg-gradient-to-r from-indigo-600 to-blue-600 text-white text-sm font-medium rounded-lg hover:from-indigo-700 hover:to-blue-700 shadow-sm transition duration-150 ease-in-out flex-shrink-0 whitespace-nowrap"
            >
              Register Free
            </button>
          </div>
        </div>
      </div>

      {/* Features & Benefits Section - Always shown */}
      <div className="bg-white py-8 sm:py-10 border-t border-gray-100">
        <div className="px-4 sm:px-6 max-w-4xl mx-auto">
          <div className="mb-6 sm:mb-8 text-center">
            <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2 sm:mb-3">Transform Your Activities with Daily Mass Reading Wisdom</h3>
            <p className="text-sm sm:text-base text-gray-600 mx-auto max-w-2xl">Apply Teachings from Catholic Mass Readings to everyday challenges and become a Scripture Superstar in the process.</p>
          </div>
          
          {/* Benefits and testimonial - Responsive grid/stack layout */}
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8 mb-6 sm:mb-8">
            {/* Benefits list - Made more scannable */}
            <div className="order-2 md:order-1">
              <h4 className="font-medium text-gray-900 mb-3 sm:mb-4 text-base sm:text-lg">Why Use the Examen?</h4>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm sm:text-base text-gray-700">Understand lessons from the official Catholic Mass reading (USCCB) each week</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm sm:text-base text-gray-700">Learn How Saint Augustine, Thomas Aquinas, and John Paul II (among others) interpreted the reading</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm sm:text-base text-gray-700">You'll be more engaged at mass and become a scripture Superstar</span>
                </li>
                <li className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                  <span className="text-sm sm:text-base text-gray-700">Makes daily readings easy to apply to everyday life situations</span>
                </li>
              </ul>
            </div>
            
            {/* Testimonial - More distinct */}
            <div className="bg-blue-50 border border-blue-100 rounded-xl p-4 sm:p-5 order-1 md:order-2">
              <div className="flex mb-2 sm:mb-3">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-4 w-4 text-yellow-500" fill="currentColor" />
                ))}
              </div>
              <p className="italic text-gray-700 text-sm sm:text-base mb-3">
                "When I completed the Sunday Examen on the Gospel reading I learned about the significance in a way that marked the fulfillment of the Old Testament (Law & Prophets) in Jesus. I had always known a bit about the scripture but didn't really understand this. It also helped me pay more attention to the Gospel Reading and Homily that Sunday."
              </p>
              <p className="text-right text-xs sm:text-sm font-medium text-gray-900">
                — Parishioner at St. Raymond's, Menlo Park
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
