// components/CalendarCard/CalendarCard.jsx
import React from 'react';
import { Calendar, ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Lock } from 'lucide-react';

const CalendarCard = ({
  hasPremium,
  selectedDate,
  isCalendarOpen,
  setIsCalendarOpen,
  formatDateCompact,
  calendarRef,
  currentMonth,
  handlePrevMonth,
  handleNextMonth,
  renderCalendarDays,
  handleGoToToday,
  handleGoToSunday
}) => {
  return (
    <div>
      <div className="px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Calendar className="h-5 w-5 text-blue-600 mr-2" />
          <h2 className="text-gray-900 font-medium">
            {hasPremium ? "Daily Readings" : "Sunday Reading"}
          </h2>
        </div>
        
        <div className="flex items-center">
          {/* Date selector button */}
          <button
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            className="flex items-center text-sm rounded-md px-2 py-1 text-blue-600 hover:bg-blue-50"
            ref={calendarRef}
          >
            <span className="hidden sm:inline mr-1">{formatDateCompact(selectedDate)}</span>
            {isCalendarOpen ? 
              <ChevronUp className="h-4 w-4" /> : 
              hasPremium ? 
                <ChevronDown className="h-4 w-4" /> : 
                <Lock className="h-4 w-4" />
            }
          </button>
        </div>
      </div>
      
      {/* Calendar dropdown */}
      {isCalendarOpen && (
        <div className="border-t border-gray-200 bg-gray-50 px-4 py-3 animate-fadeIn" ref={calendarRef}>
          <div className="flex justify-between items-center mb-2">
            <button 
              onClick={handlePrevMonth}
              className="p-1 rounded hover:bg-gray-200"
            >
              <ChevronLeft className="h-4 w-4" />
            </button>
            
            <h3 className="text-sm font-medium">
              {currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
            </h3>
            
            <button 
              onClick={handleNextMonth}
              className="p-1 rounded hover:bg-gray-200"
            >
              <ChevronRight className="h-4 w-4" />
            </button>
          </div>
          
          {/* Calendar grid */}
          <div className="mb-2">
            {/* Days of week header */}
            <div className="grid grid-cols-7 mb-1">
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                <div 
                  key={`weekday-${index}`} 
                  className={`text-xs font-medium text-center ${index === 0 ? 'text-blue-600' : 'text-gray-500'}`}
                >
                  {day}
                </div>
              ))}
            </div>
            
            {/* Calendar days */}
            <div className="grid grid-cols-7 gap-1">
              {renderCalendarDays()}
            </div>
          </div>
          
          {/* Premium lock message */}
          {!hasPremium && (
            <div className="border-t border-gray-200 pt-2 mt-2">
              <div className="flex items-center justify-center text-xs text-gray-500">
                <Lock className="h-3 w-3 mr-1 text-gray-400" />
                <span>Premium membership unlocks all daily readings</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarCard;
