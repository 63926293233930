import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HiBookOpen,
  HiChevronRight,
  HiHome,
  HiCheckCircle,
  HiXCircle,
  HiQuestionMarkCircle,
  HiClipboardDocument,
  HiAcademicCap,
  HiArrowPath,
  HiArrowTopRightOnSquare,
  HiLightBulb,
  HiInformationCircle,
  HiClock,
  HiDocumentText
} from "react-icons/hi2";
import HintButton from '../../components/HintButton/HintButton';

import {
  handleWebSocketMessage,
  initializeWebSocket,
  handleOptionSelect,
  handleNextQuestion,
  handleReflectionSubmit,
  handleLifeApplicationSelection  
} from '../../services/quizService';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import NameInputModal from '../../components/NameInputModal/NameInputModal';
import Timer from '../../components/Timer/Timer';
import Header from '../../components/Header/Header';
import CompletionStats from '../../components/CompletionStats/CompletionStats';
import AuthenticateModal from '../../components/AuthenticateModal/AuthenticateModal';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import LearnMoreFeature from '../../components/LearnMoreFeature/LearnMoreFeature';
import Analytics from '../../services/analytics'; // Import analytics service

const Trivia = () => {
  const navigate = useNavigate();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const location = useLocation();


  // Update this function in Trivia.js
  const getModeIdByDate = () => {
    // Define daily gospel readings with their dates and IDs
    const dailyGospels = [
      // April readings
      { id: 1918, date: new Date(2025, 3, 2), name: "Jesus Reveals Divine Authority" },  // April 2
      { id: 1920, date: new Date(2025, 3, 3), name: "Jesus' Witnesses" },                // April 3
      { id: 1922, date: new Date(2025, 3, 4), name: "Jesus at Tabernacles" },            // April 4
      { id: 1924, date: new Date(2025, 3, 5), name: "Division over Jesus' Identity" },   // April 5
      { id: 1927, date: new Date(2025, 3, 6), name: "Raising Lazarus to Life" },         // April 6 (Sunday)
      { id: 1945, date: new Date(2025, 3, 7), name: "Light of the World" },              // April 7
      { id: 1929, date: new Date(2025, 3, 8), name: "I AM" },                            // April 8
      { id: 1931, date: new Date(2025, 3, 9), name: "Truth Sets Free" },                 // April 9
      { id: 1933, date: new Date(2025, 3, 10), name: "Before Abraham" },                 // April 10
      { id: 1935, date: new Date(2025, 3, 11), name: "Stones Gathered" },                // April 11
      { id: 1947, date: new Date(2025, 3, 12), name: "Plot to Kill Jesus" },             // April 12
      { id: 1951, date: new Date(2025, 3, 13), name: "Gospel - Passion Narrative" },     // April 13 (Sunday)
      { id: 1937, date: new Date(2025, 3, 14), name: "Anointing at Bethany" },           // April 14
      { id: 1939, date: new Date(2025, 3, 15), name: "Betrayal Foretold" },              // April 15
      { id: 1941, date: new Date(2025, 3, 16), name: "Passover Preparation" }            // April 16
    ];
    
    // Get the current date in US Pacific Time
    const now = new Date();
    
    // Convert to US Pacific Time
    const pacificTime = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }).format(now);
    
    // Parse the Pacific date
    const [month, day, year] = pacificTime.split('/').map(num => parseInt(num, 10));
    const pacificDate = new Date(year, month - 1, day);
    
    // Check if today matches any of our daily readings
    const isToday = (gospelDate) => {
      return gospelDate.getDate() === pacificDate.getDate() &&
             gospelDate.getMonth() === pacificDate.getMonth() &&
             gospelDate.getFullYear() === pacificDate.getFullYear();
    };
    
    // Find if today has a reading
    const todayGospel = dailyGospels.find(gospel => isToday(gospel.date));
    if (todayGospel) {
      return todayGospel.id;
    }
    
    // If today doesn't have a reading, find the next upcoming reading
    const upcomingReadings = dailyGospels.filter(gospel => gospel.date > pacificDate);
    if (upcomingReadings.length > 0) {
      // Sort by date and get the closest upcoming reading
      upcomingReadings.sort((a, b) => a.date - b.date);
      return upcomingReadings[0].id;
    }
    
    // If no upcoming readings in our list, return the last one
    return dailyGospels[dailyGospels.length - 1].id;
  };



  const modeId = location.state?.modeId || getModeIdByDate();

  const modeName = location.state?.modeName;
  const effectiveUserId = userId || tempUserId;
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [sessionStartTime] = useState(Date.now()); // Track overall session time

  const [showNameModal, setShowNameModal] = useState(false);
  const [userName, setUserName] = useState('');

  // Core state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wsConnection, setWsConnection] = useState(null);
  const [sessionActive, setSessionActive] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  // Question state
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);
  const [userInput, setUserInput] = useState('');
  
  // Question analytics tracking
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  const [showAuthGate, setShowAuthGate] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [userCommunityId, setUserCommunityId] = useState(null);

  const [initialLoad, setInitialLoad] = useState(true);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [reflectionLoading, setReflectionLoading] = useState(false);
  const [showOptionLoading, setShowOptionLoading] = useState(false);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [optionTransition, setOptionTransition] = useState(false);
  const [isLifeApplicationSelectionEnabled, setIsLifeApplicationSelectionEnabled] = useState(true);


  const isReflection = location.state?.isReflection || false;
  const fromReading = location.state?.fromReading || false;

  // Progress tracking
  const [triviaProgress, setTriviaProgress] = useState({
    total_questions: 0,
    current_question: 0,
    total_points: 0,
    correct_answers: 0,
    is_session_complete: false,
    max_difficulty_reached: null,
    current_topic: null,
    current_difficulty: null
  });

  // Initialize analytics tracking when component mounts
  useEffect(() => {
    // Track trivia session start
    Analytics.trackExamenStart(fromReading ? 'reading_completion' : 'direct_navigation');
    
    // Track specific trivia start event
    Analytics.trackStepStart('trivia', {
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      journeyId: location.state?.journeyId || 'direct',
      modeId: modeId,
      modeName: modeName,
      isReflection: isReflection,
      entryPoint: fromReading ? 'reading' : location.state?.source || 'direct',
      sequence: location.state?.currentSequence || 2, // Typically trivia is second step
      fromUserDashboard: location.pathname.includes('user-dashboard'),
      difficulty: location.state?.difficulty || 'enriching'
    });
    
    // Set up engagement tracking
    Analytics.startEngagementTracking();
    
    // Clean up on unmount
    return () => {
      const sessionDuration = Math.floor((Date.now() - sessionStartTime) / 1000);
      
      // Only track abandonment if not completed
      if (!triviaProgress.is_session_complete) {
        Analytics.trackStepComplete('trivia', {
          userId: effectiveUserId,
          userType: userId ? 'registered' : 'anonymous',
          status: 'abandoned',
          timeSpent: sessionDuration,
          questionsAttempted: triviaProgress.current_question,
          correctAnswers: triviaProgress.correct_answers,
          totalPoints: triviaProgress.total_points,
          difficulty: triviaProgress.current_difficulty || location.state?.difficulty || 'enriching'
        });
      }
      
      Analytics.stopEngagementTracking();
    };
  }, []);


  const LifeApplicationSelector = ({ applications, onSelectApplication, onStandardNext, disabled }) => {
    // State for custom life situation input
    const [customSituation, setCustomSituation] = useState('');
    // State to track validation error
    const [inputError, setInputError] = useState('');
    
    // Split the applications string into an array
    const applicationArray = applications?.split(',').map(app => app.trim()) || [];
    
    // Take only first 3 applications if there are more
    const displayApplications = applicationArray.slice(0, 3);
    
    if (!applications || applicationArray.length === 0) {
      return null;
    }

    // Handle custom life situation submission
    const handleCustomSituationSubmit = () => {
      // Simple validation - limit to 6 words
      const wordCount = customSituation.trim().split(/\s+/).length;
      
      if (customSituation.trim() === '') {
        setInputError('Please enter your situation');
        return;
      }
      
      if (wordCount > 20) {
        setInputError('Please limit to 20 words max');
        return;
      }
      
      // Clear any error
      setInputError('');
      
      // Call the same handler used for predefined applications
      onSelectApplication(customSituation.trim());
      
      // Reset the input
      setCustomSituation('');
    };

    return (
      <div className="mt-2 flex flex-col items-center">
        <div className="mb-1.5 text-center">
          <p className="text-xs text-gray-500 flex items-center gap-1">
            <HiLightBulb className="w-3 h-3 text-indigo-500" />
            <span>Personalize next question with today's challenges:</span>
          </p>
        </div>
        
        <div className="flex flex-wrap justify-center gap-1.5 mb-2">
          {/* Display the applications */}
          {displayApplications.map((application, index) => (
            <button
              key={index}
              onClick={() => onSelectApplication(application)}
              disabled={disabled}
              className="px-2 py-1 bg-white border border-indigo-100 rounded text-xs text-indigo-600 hover:bg-indigo-50 transition-colors focus:outline-none focus:ring-1 focus:ring-indigo-400 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {application}
            </button>
          ))}
        </div>
        
        {/* Custom life situation input */}
        <div className="w-full max-w-xs flex flex-col items-center">
          <div className="flex w-full mb-1">
            <input
              type="text"
              value={customSituation}
              onChange={(e) => setCustomSituation(e.target.value)}
              placeholder="Or explain today's activity here..."
              disabled={disabled}
              className="flex-grow px-2 py-1 text-xs border border-indigo-100 rounded-l focus:outline-none focus:ring-1 focus:ring-indigo-400 disabled:opacity-50 disabled:cursor-not-allowed"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleCustomSituationSubmit();
                }
              }}
            />
            <button
              onClick={handleCustomSituationSubmit}
              disabled={disabled || !customSituation.trim()}
              className="px-2 py-1 bg-indigo-500 text-white rounded-r text-xs hover:bg-indigo-600 transition-colors focus:outline-none focus:ring-1 focus:ring-indigo-400 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Go
            </button>
          </div>
          
          {inputError && (
            <p className="text-xs text-red-500 self-start ml-1">{inputError}</p>
          )}
        </div>
      </div>
    );
  };



  const shouldShowAuthGate = () => {
    return !userId && 
           triviaProgress.current_question === 2 && // Check on question 3 (index 2)
           answerResponse && 
           !triviaProgress.is_session_complete;
  };

  const handleAuthSuccess = (response) => {
    console.log('Auth success in Trivia with response:', response);
    
    // Track authentication success
    Analytics.trackAuthSuccess(
      response.isNewUser ? 'register' : 'login',
      {
        isNewUser: response.isNewUser || false,
        userId: response.user_id || response.userId,
        tempUserId: tempUserId,
        source: 'trivia_auth_gate',
        conversionPoint: `question_${triviaProgress.current_question}`,
        totalQuestions: triviaProgress.total_questions
      }
    );
    
    // Check for tempUserId in the response (should be included by AuthenticateModal)
    if (response.tempUserId) {
      console.log(`Received tempUserId ${response.tempUserId} in auth success response`);
    } else {
      console.log('No tempUserId in auth success response, using Redux tempUserId:', tempUserId);
    }
    
    // Store both IDs for use in CompletionStats
    const userAuthData = {
      user_id: response.user_id,
      tempUserId: response.tempUserId || tempUserId
    };
    
    console.log('User authentication completed with data:', userAuthData);
    
    setIsAuthModalOpen(false);
    setShowAuthGate(false);
    
    // The user can now continue with their next question, still using tempUserId
    // The conversion from tempUserId to real userId will happen after completion in CompletionStats
  };

  // Add simplified useEffect hook to set community ID directly
  useEffect(() => {
    // Always set to default community ID 389
    setUserCommunityId(389);
  }, [effectiveUserId]);

  useEffect(() => {
   console.log('=== WebSocket Effect Start ===');
   console.log('Init state:', { wsConnection, modeId, effectiveUserId });
   console.log('Is Reflection:', isReflection); 

   const initWs = async () => {
     if (!wsConnection && modeId && effectiveUserId) {
       try {
         console.log('Creating WebSocket with:', { effectiveUserId, modeId, modeName, isReflection });
         
         const ws = await initializeWebSocket(
           effectiveUserId, 
           modeId, 
           modeName,
           {
             onMessage: (event) => {
               console.log('WS Message received:', event.data);
               handleWebSocketMessage(event, {
                 setCurrentQuestion,
                 setSelectedOption, 
                 setAnswerResponse,
                 setTriviaProgress,
                 setLoading,
                 setError,
                 setShowErrorNotification,
                 setInitialLoad,
                 setIsNextLoading,
                 setReflectionLoading 
               });
               setSelectionLoading(false);
               setOptionTransition(false);
             },
             onError: (error) => {
               console.log('WS Error:', error);
               setError('Connection error occurred');
               setSelectionLoading(false);
               setOptionTransition(false);
               
               // Track connection error
               Analytics.trackStepComplete('trivia_error', {
                 userId: effectiveUserId,
                 userType: userId ? 'registered' : 'anonymous',
                 error: 'websocket_connection_error',
                 details: error.message || 'Unknown websocket error',
                 stage: 'connection',
                 questionsAttempted: triviaProgress.current_question,
                 modeId
               });
             },
             onClose: () => {
               console.log('WS Closed');
               setWsConnection(null);
               setSessionActive(false);
             },
             onOpen: () => {
               console.log('WS Opened');
               setSessionActive(true);
               setLoading(false);
               
               // Track successful WebSocket connection
               Analytics.trackAnswerSelection({
                 questionType: 'trivia_connection',
                 action: 'websocket_open',
                 success: true,
                 userId: effectiveUserId,
                 userType: userId ? 'registered' : 'anonymous',
                 modeId
               });
             }
           },
           isReflection
         );
         
         console.log('WS initialized, setting connection');
         setWsConnection(ws);
       } catch (err) {
         console.error('WS init error:', err);
         setError('Failed to connect to quiz service');
         setLoading(false);
         setSelectionLoading(false);
         
         // Track connection failure
         Analytics.trackStepComplete('trivia_error', {
           userId: effectiveUserId,
           userType: userId ? 'registered' : 'anonymous',
           error: 'websocket_initialization_error',
           details: err.message || 'Unknown connection error',
           stage: 'initialization',
           modeId
         });
       }
     }
   };

   initWs();

   return () => {
     if (wsConnection) {
       console.log('Cleaning up WS connection');
       wsConnection.close();
       setWsConnection(null);
     }
   };
  }, [modeId, modeName, isReflection]); // Remove effectiveUserId from dependencies


  // Update analytics when a new question is received
  useEffect(() => {
    if (currentQuestion && !answerResponse) {
      // Track question view
      Analytics.trackQuestionView({
        questionNumber: triviaProgress.current_question + 1,
        questionType: isReflection ? 'reflection' : 'trivia',
        difficulty: currentQuestion.difficulty || 'enriching',
        topic: currentQuestion.topic || 'general',
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        sourceTextIncluded: !!currentQuestion.sourceText,
        hasHint: !!currentQuestion.hint,
        questionMethod: currentQuestion.questionType || 'multiple_choice',
        optionsCount: currentQuestion.options?.length || 0
      });
      
      // Start tracking time for this question
      setQuestionStartTime(Date.now());
      setHasInteracted(false);
    }
  }, [currentQuestion, answerResponse]);

  // Handle response analytics
  useEffect(() => {
    if (answerResponse && questionStartTime) {
      const timeSpent = Math.floor((Date.now() - questionStartTime) / 1000);
      
      // Track answer submission and response
      Analytics.trackAnswerSubmission({
        questionNumber: triviaProgress.current_question,
        questionType: isReflection ? 'reflection' : 'trivia',
        isCorrect: answerResponse.isCorrect,
        timeSpent: timeSpent,
        difficulty: answerResponse.difficulty || currentQuestion?.difficulty || 'enriching',
        topic: answerResponse.topic || currentQuestion?.topic || 'general',
        score: answerResponse.score || 0,
        hasInteracted: hasInteracted,
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        isFinalQuestion: answerResponse.isFinalQuestion
      });
      
      // Track trivia completion if this is the final question
      if (answerResponse.isFinalQuestion) {
        const sessionDuration = Math.floor((Date.now() - sessionStartTime) / 1000);
        
        Analytics.trackTriviaCompletion({
          score: triviaProgress.total_points,
          correctAnswers: triviaProgress.correct_answers,
          totalQuestions: triviaProgress.total_questions,
          timeSpentTotal: sessionDuration,
          maxDifficultyReached: triviaProgress.max_difficulty_reached || answerResponse.difficulty,
          userId: effectiveUserId,
          userType: userId ? 'registered' : 'anonymous',
          modeId: modeId,
          modeName: modeName,
          isReflection: isReflection
        });
      }
      
      // Reset question start time
      setQuestionStartTime(null);
    }
  }, [answerResponse]);


  const handleLifeApplicationSelect = (application) => {
    // Analytics tracking
    Analytics.trackAnswerSelection({
      questionType: 'navigation',
      action: 'select_life_application',
      lifeApplication: application,
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      questionNumber: triviaProgress.current_question,
      difficulty: triviaProgress.current_difficulty
    });
    
    // Clear current UI states
    setCurrentQuestion(null);
    setSelectedOption(null);
    setAnswerResponse(null);
    setUserInput('');
    setOptionTransition(false);
    
    // Set loading state
    setIsNextLoading(true);
    
    // Call the application-specific question handler
    const difficulty = location.state?.difficulty || 'advanced';
    handleLifeApplicationSelection(application, wsConnection, (loadingState) => {
      setLoading(loadingState);
      setIsNextLoading(loadingState);
    }, triviaProgress, difficulty);
  };


  const handleCompletionNameCheck = async () => {
    if (effectiveUserId >= 10000 && effectiveUserId <= 99999) {
      try {
        const response = await axios.get(
          API_ENDPOINTS.GET_TRIVIA_USER_NAME.replace(':userId', effectiveUserId)
        );
        if (response.data.display_name) {
          setUserName(response.data.display_name);
        } else {
          setShowNameModal(true);
        }
      } catch (error) {
        setShowNameModal(true);
      }
    }
  };

  useEffect(() => {
    if (triviaProgress.is_session_complete) {
      // Track overall trivia completion
      const sessionDuration = Math.floor((Date.now() - sessionStartTime) / 1000);
      
      Analytics.trackStepComplete('trivia', {
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        status: 'completed',
        timeSpent: sessionDuration,
        questionsAttempted: triviaProgress.current_question,
        correctAnswers: triviaProgress.correct_answers,
        totalPoints: triviaProgress.total_points,
        difficulty: triviaProgress.max_difficulty_reached || triviaProgress.current_difficulty,
        modeId: modeId,
        modeName: modeName
      });
      
      handleCompletionNameCheck();
    }
  }, [triviaProgress.is_session_complete, effectiveUserId]);

  const handleNameSubmit = async (name) => {
    try {
      // Track name submission
      Analytics.trackAnswerSelection({
        questionType: 'user_info',
        action: 'name_submission',
        userId: effectiveUserId,
        userType: 'anonymous',
        hasValue: !!name,
        nameLength: name.length
      });
      
      await axios.post(API_ENDPOINTS.CREATE_TRIVIA_USER, {
        user_id: effectiveUserId,
        group_id: modeId,
        display_name: name
      });
      setUserName(name);
      setShowNameModal(false);
      setIsTimerActive(true);  // Start timer after name submission
    } catch (error) {
      console.error('Error saving user name:', error);
      
      // Track error
      Analytics.trackAnswerSubmission({
        questionType: 'user_info',
        action: 'name_submission_error',
        error: error.message || 'Unknown error saving name',
        userId: effectiveUserId,
        userType: 'anonymous'
      });
    }
  };

  // Start timer when question is loaded or after name modal
  useEffect(() => {
    if (currentQuestion && !answerResponse && !showNameModal) {
      setIsTimerActive(true);
    } else {
      setIsTimerActive(false);
    }
  }, [currentQuestion, answerResponse, showNameModal]);

  const handleTimeExpire = () => {
    if (currentQuestion) {
      // Track timeout event
      Analytics.trackAnswerSubmission({
        questionNumber: triviaProgress.current_question + 1,
        questionType: isReflection ? 'reflection' : 'trivia',
        isCorrect: false,
        status: 'timeout',
        timeSpent: 120, // Default timer duration
        hasInteracted: hasInteracted,
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        difficulty: currentQuestion.difficulty
      });
      
      if (currentQuestion.questionType === 'multiple_choice') {
        handleOptionSelect("My time expired for this question. I don't know the answer", wsConnection);
      } else {
        const response = userInput.trim() || "My time expired for this question. I don't know the answer";
        handleOptionSelect(response, wsConnection);
      }
      setUserInput('');
      setSelectionLoading(true);
    }
  };

  const onOptionSelect = (option) => {
    if (!wsConnection || loading) return;

    // Mark as interacted
    setHasInteracted(true);
    
    // Track option selection
    Analytics.trackAnswerSelection({
      questionType: isReflection ? 'reflection' : 'trivia',
      questionNumber: triviaProgress.current_question + 1,
      answer: option,
      answerType: typeof option === 'string' ? 'text' : 'choice',
      timeToAnswer: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0,
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      difficulty: currentQuestion?.difficulty
    });

    setSelectedOption(option);
    setShowOptionLoading(true);
    setTimeout(() => {
      setLoading(true);
      handleOptionSelect(option, wsConnection, () => setShowOptionLoading(false));
    }, 500);  // Slight delay for smooth UX
  };

  const onNextQuestion = () => {
    if (shouldShowAuthGate()) {
      // Track auth gate display
      Analytics.trackAuthModalOpen('trivia_auth_gate', {
        questionNumber: triviaProgress.current_question,
        totalQuestions: triviaProgress.total_questions,
        correctAnswers: triviaProgress.correct_answers,
        userId: effectiveUserId,
        userType: 'anonymous'
      });
      
      setShowAuthGate(true);
      setIsAuthModalOpen(true);
      return;
    }

    const difficulty = location.state?.difficulty || 'advanced';

    // Check if this is the final question using the flag from answerResponse
    if (answerResponse?.isFinalQuestion) {
      // Track navigating to completion stats
      Analytics.trackAnswerSelection({
        questionType: 'navigation',
        action: 'view_completion_stats',
        userId: effectiveUserId,
        userType: userId ? 'registered' : 'anonymous',
        questionsCompleted: triviaProgress.current_question,
        correctAnswers: triviaProgress.correct_answers,
        difficulty: triviaProgress.current_difficulty
      });
      
      // Only NOW set is_session_complete to true to trigger stats view
      setTriviaProgress(prev => ({
        ...prev,
        is_session_complete: true
      }));
      return; // Don't proceed to next question
    }

    // Track proceeding to next question
    Analytics.trackAnswerSelection({
      questionType: 'navigation',
      action: 'next_question',
      questionNumber: triviaProgress.current_question,
      nextQuestionNumber: triviaProgress.current_question + 1,
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      difficulty: answerResponse?.difficulty || currentQuestion?.difficulty
    });

    // Clear current UI states
    setCurrentQuestion(null);
    setSelectedOption(null);
    setAnswerResponse(null);
    setUserInput('');
    setOptionTransition(false);

    // Use a dedicated loading state
    setIsNextLoading(true);

    handleNextQuestion(wsConnection, (loadingState) => {
      setLoading(loadingState);
      setIsNextLoading(loadingState);
    }, difficulty, triviaProgress, isReflection);
  };

  const handleSubmit = () => {
    if (!userInput.trim()) return;
    
    // Mark as interacted
    setHasInteracted(true);
    
    // Track submission
    Analytics.trackAnswerSelection({
      questionType: isReflection ? 'reflection' : 'free_response',
      questionNumber: triviaProgress.current_question + 1,
      answer: userInput.trim(),
      answerType: 'text',
      timeToAnswer: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0,
      wordCount: userInput.trim().split(/\s+/).length,
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      difficulty: currentQuestion?.difficulty
    });
    
    // Use the appropriate handler based on whether this is a reflection
    if (isReflection) {
      console.log('Handling reflection submission');
      setReflectionLoading(true); // Set loading state here
      handleReflectionSubmit(userInput.trim(), wsConnection, setLoading, setReflectionLoading);
    } else {
      console.log('Handling regular option selection');
      onOptionSelect(userInput.trim());
    }
  };

  const handleReturnHome = () => {
    // Track returning to dashboard/home
    Analytics.trackAnswerSelection({
      questionType: 'navigation',
      action: 'return_to_dashboard',
      userId: effectiveUserId,
      userType: userId ? 'registered' : 'anonymous',
      questionsCompleted: triviaProgress.current_question,
      correctAnswers: triviaProgress.correct_answers,
      fromCompletionStats: triviaProgress.is_session_complete
    });
    
    if (userCommunityId) {
      navigate(`/user-dashboard/${userCommunityId}`);
    } else {
      navigate('/dashboard');
    }
  };

  const LoadingReflection = () => (
    <div className="w-full flex items-center justify-center gap-1.5 py-3 rounded-md border border-indigo-300 bg-indigo-50 text-indigo-700 animate-pulse">
      <HiArrowPath className="animate-spin w-4 h-4" />
      <span className="text-sm font-medium">Submitting your reflection...</span>
    </div>
  );

  // Get difficulty badge color
  const getDifficultyBadge = (difficulty) => {
    if (!difficulty) return "bg-gray-100 text-gray-600";
    
    const difficultyMap = {
      "beginner": "bg-green-50 text-green-700 border-green-200",
      "intermediate": "bg-blue-50 text-blue-700 border-blue-200",
      "advanced": "bg-indigo-50 text-indigo-700 border-indigo-200",
      "expert": "bg-purple-50 text-purple-700 border-purple-200",
      "foundational": "bg-green-50 text-green-700 border-green-200",
      "enriching": "bg-blue-50 text-blue-700 border-blue-200"
    };
    
    return difficultyMap[difficulty.toLowerCase()] || "bg-gray-50 text-gray-700 border-gray-200";
  };
    

  const renderAuthGate = () => {
    // Check if we're coming from a community invitation
    const isFromCommunityInvite = location.state?.fromCommunity && 
                                 location.state.fromCommunity !== 389; // Not the default community
    
    return (
      <div className="bg-indigo-50 border-l-4 border-indigo-500 p-3 sm:p-4 mb-3 rounded-md text-sm sm:text-base">
        <div className="flex items-start gap-2 sm:gap-4">
          <div className="flex-shrink-0">
            <HiInformationCircle className="h-5 w-5 text-indigo-600" />
          </div>
          
          <div>
            <h3 className="text-base font-semibold text-gray-800 mb-1.5">
              Amazing job! You're doing great!
            </h3>
            
            <p className="text-gray-700 mb-2 text-sm">
              {isFromCommunityInvite 
                ? "Create a free account to continue your parish examen and join your community!"
                : "You've completed your first 2 questions! Register for free to unlock Saint's Interpretations of Scripture in Questions 3 & 4 and continue your spiritual journey."}
            </p>
            
            {/* Keep existing benefits grid */}
            <div className="grid grid-cols-3 gap-1 mb-3 text-xs">
              <div className="bg-white p-1.5 rounded flex items-center gap-1 border border-gray-200">
                <HiBookOpen className="h-3 w-3 text-green-600" />
                <span className="text-gray-700">Daily Catholic Mass Readings</span>
              </div>
              
              <div className="bg-white p-1.5 rounded flex items-center gap-1 border border-gray-200">
                <HiLightBulb className="h-3 w-3 text-indigo-600" />
                <span className="text-gray-700">Personalized Life Applications</span>
              </div>
              
              <div className="bg-white p-1.5 rounded flex items-center gap-1 border border-gray-200">
                <HiClipboardDocument className="h-3 w-3 text-blue-600" />
                <span className="text-gray-700">Track Progress & Streaks</span>
              </div>
            </div>
            
            <button
              onClick={() => {
                // Keep existing analytics
                Analytics.trackAuthModalOpen('trivia_auth_gate_click', {
                  questionNumber: triviaProgress.current_question,
                  totalQuestions: triviaProgress.total_questions,
                  correctAnswers: triviaProgress.correct_answers,
                  userId: effectiveUserId,
                  userType: 'anonymous',
                  action: 'create_account_button'
                });
                
                setIsAuthModalOpen(true);
              }}
              disabled={loading}
              className="bg-indigo-600 text-white px-3 py-1.5 rounded text-xs sm:text-sm font-medium hover:bg-indigo-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors
                disabled:opacity-50 disabled:cursor-not-allowed w-full sm:w-auto"
            >
              {loading ? (
                <div className="flex items-center justify-center gap-1">
                  <HiArrowPath className="animate-spin h-3 w-3" />
                  <span>Creating Account...</span>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-1">
                  <span>{isFromCommunityInvite ? "Join Parish Community" : "Unlock Saint's Interpretations"}</span>
                  <HiChevronRight className="h-3 w-3" />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };


  const LoadingOption = ({ option }) => (
    <div className="w-full flex items-center justify-center gap-1.5 py-3 rounded-md border border-indigo-300 bg-indigo-50 text-indigo-700 animate-pulse">
      <HiArrowPath className="animate-spin w-4 h-4" />
      <span className="text-sm font-medium">Processing your response...</span>
    </div>
  );

  const renderAnswer = () => (
    <div className="bg-white rounded-md shadow-sm mb-4 overflow-hidden border border-gray-200">
      <div className={`p-3 border-b ${isReflection ? 
        'bg-indigo-50 text-indigo-800 border-indigo-100' : 
        answerResponse?.isCorrect ? 
        'bg-green-50 text-green-800 border-green-100' : 
        'bg-amber-50 text-amber-800 border-amber-100'}`}
      >
        <div className="flex items-center gap-2">
          <div className={`p-1 rounded-full ${
            isReflection ? 'bg-indigo-100' : 
            answerResponse?.isCorrect ? 'bg-green-100' : 'bg-amber-100'
          }`}>
            {isReflection ? (
              <HiLightBulb className="w-4 h-4 text-indigo-600" />
            ) : answerResponse?.isCorrect ? (
              <HiCheckCircle className="w-4 h-4 text-green-600" />
            ) : (
              <HiXCircle className="w-4 h-4 text-amber-600" />
            )}
          </div>
          <div>
            <div className="text-base font-semibold flex items-center gap-1.5">
              {isReflection ? 'Reflection Submitted' : 
               answerResponse?.isCorrect ? (
                 <>
                   <span className="text-green-700 font-bold">Correct!</span> 
                   <span>Great Understanding</span>
                 </>
               ) : (
                 <>
                   <span className="text-amber-700 font-bold">Incorrect.</span> 
                   <span>Let's Deepen Your Knowledge</span>
                 </>
               )}
            </div>
            <div className="text-xs">
              {isReflection ? 'Your thoughts have been recorded' : 
               answerResponse?.isCorrect ? 'You have a solid grasp of this teaching' : 
               'Every question is an opportunity to learn more'}
            </div>
          </div>
        </div>
      </div>
      
      <div className="p-3 space-y-3">
        {/* For regular questions (non-reflection) - show correct answer section with clearer visual indicator */}
        {!isReflection && (
          <div className={`p-2.5 rounded-md border text-sm ${
            answerResponse?.isCorrect ? 'bg-green-50 border-green-200' : 'bg-gray-50 border-gray-200'
          }`}>
            <div className="flex items-center gap-1.5 mb-1">
              <HiCheckCircle className="w-3.5 h-3.5 text-green-600" />
              <h3 className="font-semibold text-gray-800 text-sm">
                {answerResponse?.isCorrect ? "Your Answer is Correct" : "Correct Answer"}
              </h3>
            </div>
            <p className="text-gray-700 pl-5 text-sm">{answerResponse?.correct_answer}</p>
          </div>
        )}
        
        {/* Reflection-specific sections */}
        {isReflection ? (
          <>
            {answerResponse?.encouragement && (
              <div className="bg-indigo-50 p-2.5 rounded-md border border-indigo-100 text-sm">
                <div className="flex items-center gap-1.5 mb-1">
                  <HiLightBulb className="w-3.5 h-3.5 text-indigo-600" />
                  <h3 className="font-semibold text-indigo-800 text-sm">Encouragement</h3>
                </div>
                <p className="text-gray-700 pl-5 text-sm">{answerResponse.encouragement}</p>
              </div>
            )}
            
            {answerResponse?.spiritualContext && (
              <div className="bg-purple-50 p-2.5 rounded-md border border-purple-100 text-sm">
                <div className="flex items-center gap-1.5 mb-1">
                  <HiBookOpen className="w-3.5 h-3.5 text-purple-600" />
                  <h3 className="font-semibold text-purple-800 text-sm">Spiritual Context</h3>
                </div>
                <p className="text-gray-700 pl-5 text-sm">{answerResponse.spiritualContext}</p>
              </div>
            )}
          </>
        ) : (
          <>
            {/* Regular question sections */}
            <div className="bg-blue-50 p-2.5 rounded-md border border-blue-100 text-sm">
              <div className="flex items-center gap-1.5 mb-1">
                <HiInformationCircle className="w-3.5 h-3.5 text-blue-600" />
                <h3 className="font-semibold text-blue-800 text-sm">
                  {answerResponse?.isCorrect ? "Additional Insights" : "Understanding"}
                </h3>
              </div>
              <p className="text-gray-700 pl-5 text-sm">{answerResponse?.explanation}</p>
            </div>

            {answerResponse?.teaching_text && (
              <div className="bg-amber-50 p-2.5 rounded-md border border-amber-100 text-sm">
                <div className="flex items-center gap-1.5 mb-1">
                  <HiLightBulb className="w-3.5 h-3.5 text-amber-600" />
                  <h3 className="font-semibold text-amber-800 text-sm">
                    {answerResponse?.isCorrect ? "Deeper Context" : "Scripture Wisdom"}
                  </h3>
                </div>
                <p className="text-gray-700 pl-5 text-sm">{answerResponse.teaching_text}</p>
                
                {/* Add LearnMoreFeature component here */}
                <LearnMoreFeature 
                  questionContext={{
                    question_text: currentQuestion?.question_text,
                    sourceText: currentQuestion?.sourceText,
                    correctAnswer: answerResponse?.correct_answer,
                    topic: currentQuestion?.topic
                  }} 
                />
              </div>
            )}
          </>
        )}
      </div>
      
      <div className="p-3 bg-gray-50 border-t">
        {shouldShowAuthGate() ? (
          renderAuthGate()
        ) : (
          <>
            <button
              onClick={() => {
                // Track user clicking next
                Analytics.trackAnswerSelection({
                  questionType: 'navigation',
                  action: answerResponse?.isFinalQuestion ? 'view_completion' : 'next_question_button',
                  userId: effectiveUserId,
                  userType: userId ? 'registered' : 'anonymous',
                  questionNumber: triviaProgress.current_question,
                  isCorrect: answerResponse?.isCorrect,
                  isFinalQuestion: answerResponse?.isFinalQuestion
                });
                
                onNextQuestion();
              }}
              disabled={loading}
              className={`w-full flex items-center justify-center gap-2 
                ${answerResponse?.isFinalQuestion 
                  ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500' 
                  : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                } text-white px-3 py-2 rounded
                transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed`}
            >
              <span className="flex items-center justify-center gap-1">
                {answerResponse?.isFinalQuestion
                  ? "Complete Gospel Study"
                  : isReflection ? "Next Question" : "Next Question"} 
                <HiChevronRight className="w-4 h-4" />
              </span>
            </button>
            
            
            {/* Add Theme Selector if not the final question and themes are available */}
            {!answerResponse?.isFinalQuestion && 
             !isReflection && 
             answerResponse?.lifeApplications && 
             isLifeApplicationSelectionEnabled && (
               <LifeApplicationSelector 
                 applications={answerResponse.lifeApplications}
                 onSelectApplication={handleLifeApplicationSelect}
                 onStandardNext={onNextQuestion}
                 disabled={loading || isNextLoading}
               />
             )}
          </>
        )}
      </div>
    </div>
  );


  const renderSimpleHeader = () => (
    <div className="bg-indigo-600 p-3 sm:p-4 rounded-t-md mb-4 text-white">
      <div className="flex items-center justify-between">
        {/* Left Side - Title only */}
        <div className="flex items-center gap-2 min-w-0 flex-shrink-1">
          <HiClipboardDocument className="w-5 h-5 flex-shrink-0" />
          <h2 className="font-semibold text-lg truncate">
            {modeName || "Examen"}
          </h2>
        </div>
        
        {/* Right Side - Counter and Timer on one line */}
        <div className="flex items-center gap-2 flex-shrink-0">
          {/* Simple inline counter */}
          <div className="text-sm font-medium bg-white/20 rounded-md px-2 py-1 whitespace-nowrap">
            Q{triviaProgress.current_question + 1}/{triviaProgress.total_questions}
          </div>
          
          {/* Timer */}
          {!isReflection && isTimerActive && (
            <Timer duration={120} onTimeExpire={handleTimeExpire} isActive={isTimerActive} />
          )}
        </div>
      </div>
    </div>
  );


  const renderQuestion = () => (
    <div className="bg-white rounded-md shadow-sm mb-4 overflow-hidden border border-gray-200">
      {renderSimpleHeader()}

      {currentQuestion?.sourceText && (
        <div className="px-4 pt-3">
          <div className="p-3 bg-blue-50 rounded-md border border-blue-200 max-h-48 sm:max-h-56 overflow-y-auto mb-4">
            <div className="flex items-start gap-2">
              <div className="mt-0.5 bg-blue-100 p-1 rounded-full flex-shrink-0">
                <HiBookOpen className="w-3.5 h-3.5 text-blue-700" />
              </div>
              <div>
                <h4 className="text-sm font-semibold text-blue-800 mb-1">Source Text</h4>
                <p className="text-sm text-gray-700 leading-relaxed">{currentQuestion.sourceText}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="p-4">
        <div className="mb-4">
          <h2 className="text-lg font-medium text-gray-800 mb-1">
            {isReflection ? currentQuestion?.reflectionPrompt : currentQuestion?.question_text}
          </h2>
          
          {/* Timer is handled in the header, so we don't need it here */}
        </div>

        {!isReflection && currentQuestion?.questionType === 'multiple_choice' && (
          <div className="space-y-2.5">
            {/* Add instruction line here */}
            <p className="text-sm text-gray-600 italic mb-2">Based on the source text and question, select the best answer:</p>
            
            {currentQuestion?.options?.map((option, index) => (
              <button
                key={index}
                onClick={() => {
                  // Mark as interacted with options
                  setHasInteracted(true);
                  onOptionSelect(option);
                }}
                disabled={loading || selectedOption !== null}
                className={`w-full text-left p-3 rounded-md border transition-all duration-200 text-sm
                  ${selectedOption === option
                    ? 'bg-indigo-100 border-indigo-500 text-indigo-800 font-medium'
                    : 'border-gray-200 hover:border-indigo-300 hover:bg-gray-50'
                  } ${loading || selectedOption !== null ? 'opacity-70 cursor-not-allowed' : ''}`}
              >
                <span>{option}</span>
              </button>
            ))}
            {loading && selectedOption && <LoadingOption option={selectedOption} />}
            
            {/* Fixed hint button placement */}
            {!isReflection && currentQuestion?.hint && (
              <HintButton 
                hint={currentQuestion.hint} 
                isDisabled={loading || selectedOption !== null}
                onHintShow={() => {
                  // Track hint usage
                  Analytics.trackAnswerSelection({
                    questionType: 'hint_usage',
                    questionNumber: triviaProgress.current_question + 1,
                    action: 'view_hint',
                    userId: effectiveUserId,
                    userType: userId ? 'registered' : 'anonymous',
                    difficulty: currentQuestion.difficulty,
                    timeToHint: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0
                  });
                  setHasInteracted(true);
                }}
              />
            )}
          </div>
        )}

        {!isReflection && ['free_response', 'fill_in_blank'].includes(currentQuestion?.questionType) && (
          <div className="space-y-3">
            <textarea
              value={userInput}
              onChange={(e) => {
                setUserInput(e.target.value);
                // Mark as interacted when typing
                if (!hasInteracted && e.target.value.trim().length > 0) {
                  setHasInteracted(true);
                  
                  // Track first interaction
                  Analytics.trackAnswerSelection({
                    questionType: 'free_response',
                    action: 'started_typing',
                    userId: effectiveUserId,
                    userType: userId ? 'registered' : 'anonymous',
                    questionNumber: triviaProgress.current_question + 1,
                    timeToStart: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0
                  });
                }
              }}
              placeholder="Type your answer..."
              rows={3}
              disabled={loading || selectionLoading}
              className="w-full p-3 border rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            />
            <button
              onClick={handleSubmit}
              disabled={!userInput.trim() || loading || selectionLoading}
              className="w-full bg-indigo-600 text-white p-3 rounded-md text-sm font-medium hover:bg-indigo-700 transition disabled:opacity-50"
            >
              Submit Answer
            </button>
            
            {/* Fixed hint button placement */}
            {!isReflection && currentQuestion?.hint && (
              <HintButton 
                hint={currentQuestion.hint} 
                isDisabled={loading || selectionLoading}
                onHintShow={() => {
                  // Track hint usage
                  Analytics.trackAnswerSelection({
                    questionType: 'hint_usage',
                    questionNumber: triviaProgress.current_question + 1,
                    action: 'view_hint',
                    userId: effectiveUserId,
                    userType: userId ? 'registered' : 'anonymous',
                    difficulty: currentQuestion.difficulty,
                    timeToHint: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0
                  });
                  setHasInteracted(true);
                }}
              />
            )}
          </div>
        )}

        {isReflection && (
          <div className="space-y-3">
            <textarea
              value={userInput}
              onChange={(e) => {
                setUserInput(e.target.value);
                // Mark as interacted when typing reflection
                if (!hasInteracted && e.target.value.trim().length > 0) {
                  setHasInteracted(true);
                  
                  // Track first interaction with reflection
                  Analytics.trackAnswerSelection({
                    questionType: 'reflection',
                    action: 'started_typing',
                    userId: effectiveUserId,
                    userType: userId ? 'registered' : 'anonymous',
                    questionNumber: triviaProgress.current_question + 1,
                    timeToStart: questionStartTime ? Math.floor((Date.now() - questionStartTime) / 1000) : 0
                  });
                }
              }}
              placeholder="Share your personal reflection here..."
              rows={4}
              disabled={loading || reflectionLoading}
              className="w-full p-3 border rounded-md text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            />
            {reflectionLoading ? (
              <LoadingReflection />
            ) : (
              <button
                onClick={handleSubmit}
                disabled={!userInput.trim() || loading || reflectionLoading}
                className="w-full bg-indigo-600 text-white p-3 rounded-md text-sm font-medium hover:bg-indigo-700 transition disabled:opacity-50"
              >
                Submit Reflection
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );


  const renderInitialLoading = () => (
    <div className="min-h-[40vh] sm:min-h-[50vh] flex flex-col items-center justify-center">
      <div className="bg-indigo-100 p-2.5 rounded-full mb-3">
        <HiArrowPath className="w-5 h-5 text-indigo-600 animate-spin" />
      </div>
      <h3 className="text-base font-medium text-gray-800 mb-1">Loading Your Question</h3>
      <p className="text-gray-500 text-xs">Preparing Gospel content...</p>
    </div>
  );

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 pt-4 sm:pt-6 px-3 sm:px-6 pb-6">
        <div className="max-w-3xl mx-auto">
          {triviaProgress.is_session_complete ? (
            <CompletionStats 
              triviaProgress={triviaProgress}
              effectiveUserId={effectiveUserId}
              handleReturnHome={handleReturnHome}
            />
          ) : (
            <>
              {initialLoad ? (
                renderInitialLoading()
              ) : (
                <>
                  {currentQuestion && !answerResponse && renderQuestion()}
                  {answerResponse && renderAnswer()}
                                        
                  {isNextLoading && (
                    <div className="bg-white rounded-md shadow-sm p-4 text-center border border-gray-200">
                      <div className="flex flex-col items-center justify-center gap-2">
                        <div className="bg-indigo-100 p-1.5 rounded-full">
                          <HiArrowPath className="w-4 h-4 text-indigo-600 animate-spin" />
                        </div>
                        <p className="text-gray-600 text-sm">Loading next question...</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <AuthenticateModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        isFromTrivia={true}
        isFromCompletionStats={false}
        tempUserId={tempUserId}
        onSuccess={handleAuthSuccess}
        // This is the key change - use the community ID from location state when present
        communityId={location.state?.fromCommunity || userCommunityId}
        onModeToggle={(fromMode, toMode) => {
          Analytics.trackAuthModeToggle(fromMode, toMode);
        }}
        onAuthFailure={(method, error) => {
          Analytics.trackAuthFailure(method, {
            errorType: error.type || 'unknown',
            message: error.message || 'Authentication failed',
            source: 'trivia_auth_gate',
            questionNumber: triviaProgress.current_question
          });
        }}
      />

      <NameInputModal
        isOpen={showNameModal}
        onClose={() => {
          // Track closing name modal
          Analytics.trackAnswerSelection({
            questionType: 'user_info',
            action: 'name_modal_close',
            userId: effectiveUserId,
            userType: 'anonymous'
          });
          
          setShowNameModal(false);
        }}
        onSubmit={handleNameSubmit}
      />

      {showErrorNotification && (
        <ErrorNotification 
          message={error || "An error occurred"}
          onClose={() => {
            // Track error dismissal
            Analytics.trackAnswerSelection({
              questionType: 'error_notification',
              action: 'dismiss',
              error: error || "An error occurred",
              userId: effectiveUserId,
              userType: userId ? 'registered' : 'anonymous'
            });
            
            setShowErrorNotification(false);
          }}
        />
      )}
    </>
  );
};

export default Trivia;
