// pages/CommunityAdminDashboard/CommunityAdminDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HiUsers, HiQrCode } from "react-icons/hi2";

import Header from '../../components/Header/Header';
import { getCommunityAdminDashboard } from '../../services/communityService';
import InviteCommunityModal from '../../components/InviteCommunityModal/InviteCommunityModal';
import AdminHomiliesSection from '../../components/AdminHomiliesSection/AdminHomiliesSection';

const CommunityAdminDashboard = () => {
  const { communityId } = useParams();
  const { userId } = useSelector((state) => state.user);
  const navigate = useNavigate();
  
  const [communityData, setCommunityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  
  // Fetch community admin dashboard data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        
        const data = await getCommunityAdminDashboard(communityId, userId);
        setCommunityData(data);
      } catch (err) {
        console.error('Error fetching community admin dashboard:', err);
        setError('Failed to load community dashboard');
      } finally {
        setLoading(false);
      }
    };
    
    if (communityId && userId) {
      fetchDashboardData();
    }
  }, [communityId, userId]);
  
  const handleShowInvite = () => {
    setShowInviteModal(true);
  };
  
  // Handle closing the invite modal
  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
  };
  
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="max-w-7xl mx-auto px-4 py-12 text-center">
          <div className="animate-spin h-8 w-8 text-blue-600 mx-auto mb-4 border-4 border-blue-200 border-t-blue-600 rounded-full"></div>
          <p className="text-gray-600">Loading community dashboard...</p>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="w-full overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="space-y-6">
            {/* Header Section */}
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
              <div className="w-full sm:w-auto">
                <h1 className="text-2xl font-bold text-gray-900 break-words">
                  {communityData?.name || 'Community Dashboard'}
                </h1>
                <p className="text-gray-600">Admin Dashboard</p>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                <button
                  onClick={() => navigate(`/dashboard`)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 border border-gray-200 transition-colors"
                >
                  <HiUsers className="w-4 h-4 mr-2 flex-shrink-0" />
                  Member Dashboard
                </button>
              </div>
            </div>

            {/* Community Stats & Invite Button */}
            <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div className="flex items-center">
                <div className="p-3 rounded-md bg-blue-100 text-blue-600 mr-4">
                  <HiUsers className="h-6 w-6" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">{communityData?.members || 0}</h3>
                  <p className="text-sm text-gray-500">Total Members</p>
                </div>
              </div>
              
              <button
                onClick={handleShowInvite}
                className="sm:w-auto inline-flex items-center px-4 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white shadow-sm transition-colors"
              >
                <HiQrCode className="w-4 h-4 mr-2" />
                Invite Members
              </button>
            </div>

            {/* Homily Management Section - The main focus */}
            <AdminHomiliesSection 
              userId={userId} 
              communityId={communityId} 
            />
          </div>
        </div>
      </main>
      
      {/* Invite Modal */}
      {showInviteModal && (
        <InviteCommunityModal
          isOpen={showInviteModal}
          onClose={handleCloseInviteModal}
          communityId={communityId}
          communityName={communityData?.name || 'Community'}
        />
      )}
    </div>
  );
};

export default CommunityAdminDashboard;