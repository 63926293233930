// Updated components/AdminHomiliesSection/AdminHomiliesSection.jsx
import React, { useState, useEffect } from 'react';
import { Calendar, Edit, Check, Award } from 'lucide-react';
import communityService from '../../services/communityService';
import Analytics from '../../services/analytics';

const AdminHomiliesSection = ({ userId, communityId }) => {
  const [loading, setLoading] = useState(true);
  const [upcomingWeekends, setUpcomingWeekends] = useState([]);
  const [editingDescription, setEditingDescription] = useState(null);
  const [pendingDescription, setPendingDescription] = useState('');
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({ totalMembers: 0, totalCompletions: 0, averageScore: 0 });
  const [showCompletionsModal, setShowCompletionsModal] = useState(false);
  const [completions, setCompletions] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  
  // Load upcoming weekends data
  useEffect(() => {
    const loadUpcomingWeekends = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await communityService.getUpcomingWeekends(communityId, 4);
        
        if (response && response.success) {
          // For each weekend, check if there's already a homily group
          const weekendsWithGroupData = await Promise.all(response.weekends.map(async (weekend) => {
            try {
              // Extract date from weekend.id (format: weekend_YYYYMMDD)
              const date = weekend.id.replace('weekend_', '');
              const formattedDate = `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
              
              // Check if this weekend already has a homily group
              const availableReadings = await communityService.getAvailableReadings(formattedDate, userId);
              
              // Look for a "Homily" reading type
              const homilyGroup = availableReadings.readings?.find(reading => reading.step_title === "Homily");

              return {
                ...weekend,
                homilyGroupId: homilyGroup?.id || null,
                homilyTopics: homilyGroup?.topics || []
              };
            } catch (err) {
              console.error(`Error fetching homily info for weekend ${weekend.id}:`, err);
              return weekend;
            }
          }));

          setUpcomingWeekends(weekendsWithGroupData);
        } else {
          setError('Failed to load upcoming weekends');
        }
        
        // Load community stats
        await loadCommunityStats();
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading upcoming weekends:', err);
        setError('Failed to load upcoming weekends');
        setLoading(false);
      }
    };
    
    loadUpcomingWeekends();
  }, [communityId, userId]);
  
  // Load community stats
  const loadCommunityStats = async () => {
    try {
      const statsResponse = await communityService.getCommunityStats(communityId);
      
      if (statsResponse && statsResponse.success) {
        setStats({
          totalMembers: statsResponse.totalMembers || 0,
          totalCompletions: statsResponse.totalCompletions || 0,
          averageScore: statsResponse.averageScore || 0
        });
      }
    } catch (err) {
      console.error('Error loading community stats:', err);
    }
  };
  
  // Load completions for modal
  const loadCompletions = async () => {
    try {
      setLoadingModal(true);
      const response = await communityService.getCommunityCompletions(communityId);
      
      if (response && response.success) {
        setCompletions(response.completions || []);
      }
      setLoadingModal(false);
    } catch (err) {
      console.error('Error loading completions:', err);
      setLoadingModal(false);
    }
  };

  // Handle opening completions modal
  const handleOpenCompletionsModal = () => {
    loadCompletions();
    setShowCompletionsModal(true);
  };
  
  // Handle description editing
  const startEditingDescription = (weekendId, currentDescription) => {
    setEditingDescription(weekendId);
    setPendingDescription(currentDescription || '');
  };
  
  // Save homily description
  const saveHomilyDescription = async (weekendId) => {
    try {
      setError(null);
      
      // Create or update a homily group with the description as topics
      const response = await communityService.updateHomilyDescription({
        communityId,
        weekendId,
        description: pendingDescription.trim(),
        userId
      });
      
      if (response && response.success) {
        // Update the local state
        setUpcomingWeekends(prevWeekends => 
          prevWeekends.map(weekend => {
            if (weekend.id === weekendId) {
              return {
                ...weekend,
                homilyDescription: pendingDescription.trim(),
                homilyGroupId: response.groupId || weekend.homilyGroupId,
                homilyTopics: [pendingDescription.trim()]
              };
            }
            return weekend;
          })
        );
        
        // Track update
        Analytics.trackAnswerSelection({
          questionType: 'admin_action',
          action: 'homily_description_updated',
          userId,
          communityId,
          weekendId
        });
        
        // Clear editing state
        setEditingDescription(null);
        setPendingDescription('');
      } else {
        setError('Failed to update homily description');
      }
    } catch (err) {
      console.error('Error updating homily description:', err);
      setError('Failed to update homily description');
    }
  };
  
  // Format date display - ensure we're showing Sunday dates correctly
  const formatWeekendDate = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    
    // If the date is not a Sunday (0 is Sunday in JavaScript's getDay())
    if (date.getDay() !== 0) {
      // Find the next Sunday - move forward by appropriate number of days
      const daysUntilSunday = 7 - date.getDay();
      date.setDate(date.getDate() + daysUntilSunday);
    }
    
    // Format the (now Sunday) date
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Render weekend card
  const renderWeekendCard = (weekend) => {
    const isEditing = editingDescription === weekend.id;
    const hasDescription = weekend.homilyDescription && weekend.homilyDescription.trim() !== '';
    const hasExistingGroup = weekend.homilyGroupId !== null;
    const formattedDate = formatWeekendDate(weekend.date);
    
    return (
      <div 
        key={weekend.id}
        className="bg-white border border-gray-200 rounded-lg overflow-hidden mb-4"
      >
        {/* Weekend header */}
        <div className="p-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold text-gray-900 flex items-center">
              <Calendar className="h-5 w-5 mr-2 text-gray-500" />
              Sunday {formattedDate}
            </h3>
            <button
              onClick={handleOpenCompletionsModal} 
              className="flex items-center text-blue-600 hover:text-blue-800 text-sm px-3 py-1 border border-blue-200 rounded-md hover:bg-blue-50"
            >
              <Award className="h-4 w-4 mr-1" />
              <span>Completions</span>
            </button>
          </div>
        </div>
        
        {/* Content */}
        <div className="p-4">
          {/* Homily description */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <h4 className="text-sm font-medium text-gray-700">
                Brief Homily Summary
              </h4>
              {!isEditing && (
                <button
                  onClick={() => startEditingDescription(weekend.id, weekend.homilyDescription || (weekend.homilyTopics?.length > 0 ? weekend.homilyTopics[0] : ''))}
                  className="text-blue-600 hover:text-blue-800 text-sm flex items-center"
                >
                  <Edit className="h-4 w-4 mr-1" />
                  {hasDescription ? 'Edit' : 'Add Homily Description'}
                </button>
              )}
            </div>
            
            {isEditing ? (
              <div className="space-y-2">
                <textarea
                  value={pendingDescription}
                  onChange={(e) => setPendingDescription(e.target.value)}
                  placeholder="Enter the topics or instructions for your Sunday homily trivia..."
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={3}
                />
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setEditingDescription(null)}
                    className="px-3 py-1 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => saveHomilyDescription(weekend.id)}
                    className="px-3 py-1 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md flex items-center"
                  >
                    <Check className="h-4 w-4 mr-1" />
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-gray-50 border border-gray-200 rounded-md p-3">
                {hasDescription ? (
                  <p className="text-gray-700">{weekend.homilyDescription}</p>
                ) : weekend.homilyTopics?.length > 0 ? (
                  <ul className="list-disc pl-5 text-gray-700">
                    {weekend.homilyTopics.map((topic, index) => (
                      <li key={index}>{topic}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 italic">
                    Instructions for the homily trivia questions, eg: 'Focus on Thomas Aquinas Teachings of First Motion of God (from Summa Theologica) and how that relates to the creation story in Genesis.' 
                  </p>
                )}
              </div>
            )}
          </div>
          
        </div>
      </div>
    );
  };

  // Render completions modal
  const renderCompletionsModal = () => {
    if (!showCompletionsModal) return null;
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg w-full max-w-4xl max-h-[80vh] overflow-hidden flex flex-col">
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-lg font-semibold">Homily Responses</h2>
            <button 
              onClick={() => setShowCompletionsModal(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          
          <div className="p-4 overflow-y-auto flex-grow">
            {loadingModal ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
              </div>
            ) : completions.length === 0 ? (
              <p className="text-gray-500 text-center py-4">No completions found.</p>
            ) : (
              <div className="space-y-6">
                {completions.map((completion, index) => (
                  <div key={index} className="border border-gray-200 rounded-lg overflow-hidden">
                    <div className="bg-gray-50 p-3 border-b border-gray-200">
                      <div className="flex justify-between">
                        <div>
                          <p className="font-medium">{completion.userName || 'Anonymous'}</p>
                          <p className="text-sm text-gray-500">Date: {new Date(completion.completedAt).toLocaleDateString()}</p>
                        </div>
                        <div className="text-right">
                          <p className="font-medium">Score: {completion.score}%</p>
                          <p className="text-sm text-gray-500">
                            {completion.correctAnswers} of {completion.totalQuestions} correct
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="p-3">
                      <h4 className="text-sm font-medium mb-2">Responses:</h4>
                      <div className="space-y-3">
                        {completion.questions.map((q, qIndex) => (
                          <div 
                            key={qIndex} 
                            className={`p-3 rounded-md border ${q.isCorrect ? 'border-gray-200' : 'border-gray-200'}`}
                          >
                            <p className="font-medium mb-1">{q.questionText}</p>
                            <p className="text-sm text-gray-700">
                              Answer: {q.userResponse}
                              {!q.isCorrect && q.correctAnswer && 
                                <span className="text-gray-600"> (Correct: {q.correctAnswer})</span>
                              }
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          <div className="p-4 border-t border-gray-200">
            <button
              onClick={() => setShowCompletionsModal(false)}
              className="w-full py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden mb-6">
      <div className="border-b border-gray-200 px-4 py-3">
        <h2 className="text-lg font-semibold text-gray-900">Homily Trivia Instructions</h2>
        <p className="text-sm text-gray-600">
          Explain your homily in 1-2 sentences. 
        </p>
      </div>
      
      <div className="p-4">
        {loading ? (
          <div className="text-center py-8">
            <div className="animate-spin h-6 w-6 border-2 border-blue-600 border-t-transparent rounded-full mx-auto mb-2"></div>
            <p className="text-gray-600 text-sm">Loading upcoming weekends...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 rounded-md p-3 text-red-600 text-sm">
            {error}
          </div>
        ) : upcomingWeekends.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-600">No upcoming weekends found.</p>
          </div>
        ) : (
          <div className="space-y-4">
            {upcomingWeekends.map(renderWeekendCard)}
          </div>
        )}
      </div>
      
      {/* Render modals */}
      {renderCompletionsModal()}
    </div>
  );
};

export default AdminHomiliesSection;