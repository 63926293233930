import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import authenticateService from '../../services/authenticateService';
import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_CLEAR_ERRORS,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  SET_TEMP_USER_ID,
  SET_USER_ID,
  FETCH_USER_ID_REQUEST,
  FETCH_USER_ID_SUCCESS,
  FETCH_USER_ID_FAIL,
  SET_SCHWAB_AUTHENTICATION,
  CHECK_SCHWAB_AUTH_STATUS,
  CLEAR_TEMP_USER_ID,
  SET_ONBOARDING_STATUS,
  UPDATE_USER_SUBSCRIPTION_REQUEST,
  UPDATE_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_USER_SUBSCRIPTION_FAIL,
  FETCH_CHAT_RESPONSE_COUNT_REQUEST,
  FETCH_CHAT_RESPONSE_COUNT_SUCCESS,
  FETCH_CHAT_RESPONSE_COUNT_FAIL,
  UPDATE_LOCAL_CHAT_RESPONSE_COUNT
} from "../constants/userConstants";

export const loadUserData = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_DATA_REQUEST });
    const { data } = await axios.post(API_ENDPOINTS.USER_INFORMATION, { email });
    dispatch({ type: USER_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_DATA_FAIL, payload: error.response?.data?.error || error.message });
  }
};

export const signIn = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    
    // First login request
    const response = await axios.post(API_ENDPOINTS.LOGIN, userData);
    
    // Important: Get the userId from the login response
    const userId = response.data.user_id;
    
    // Dispatch login success first
    dispatch({ 
      type: USER_LOGIN_SUCCESS, 
      payload: { 
        email: userData.email, 
        userId: userId,  // Use the userId we already have
        phone: response.data.phone  // Add this line here

      } 
    });

    // No need for a separate fetchUserId call since we already have the ID
    return response.data;  // Return the response for the component
    
  } catch (error) {
    console.error('Sign in error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    dispatch({ 
      type: USER_LOGIN_FAIL, 
      payload: error.response?.data?.error || error.message 
    });
    
    throw error;  // Re-throw to handle in component
  }
};




export const clearSignUpErrors = () => (dispatch) => {
  dispatch({ type: USER_REGISTER_CLEAR_ERRORS });
};

// Updated to use user-information endpoint
export const fetchUserId = (email) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_ID_REQUEST });
    const { data } = await axios.post(API_ENDPOINTS.USER_INFORMATION, { email });
    dispatch({ type: FETCH_USER_ID_SUCCESS, payload: data.user_id });
  } catch (error) {
    dispatch({ type: FETCH_USER_ID_FAIL, payload: error.response?.data?.error || error.message });
  }
};


// store/actions/userActions.js
export const signup = (userData) => async (dispatch) => {
  console.log("=== Starting Signup Process ===");
  console.log("Received userData:", userData);
  
  try {
    dispatch({ type: USER_REGISTER_REQUEST });
    
    const registrationPayload = {
      fullName: userData.fullName,
      name: userData.fullName,
      email: userData.email,
      phone: userData.phone || "333",
      password: userData.password,
      subscriptionType: userData.subscriptionType || 'weekly',
      subscription_status: 'trial', // Always start with trial
      parish_name: userData.parish,  // This matches the register endpoint
      initialAssessment: userData.initialAssessment || [],
      communityId: userData.communityId,
      tempUserId: userData.tempUserId, // Make sure tempUserId is included
      language: userData.language || 'english',
      personalContext: userData.personalContext || ''  // Add this line

    };
    
    console.log("Sending registration payload:", registrationPayload);
    const response = await axios.post(API_ENDPOINTS.SIGNUP, registrationPayload);
    console.log("Registration response received:", response.data);
    
    if (!response.data.user_id) {
      throw new Error('No userId returned from registration');
    }

    dispatch({ 
      type: USER_REGISTER_SUCCESS, 
      payload: { 
        fullName: userData.fullName,
        email: userData.email,
        phone: userData.phone,
        userId: response.data.user_id,
        communityId: response.data.community_id,
        language: userData.language || 'english',
        subscriptionType: userData.subscriptionType || 'weekly',
        subscriptionStatus: 'trial'
      } 
    });
    
    // Return full response including tempUserId for reference
    return {
      ...response.data,
      isRegistration: true,
      subscriptionType: userData.subscriptionType || 'weekly',
      trialEndDate: response.data.trial_end_date || null,
      tempUserId: userData.tempUserId // Pass this back for reference
    };
  } catch (error) {
    console.error("=== Registration Error ===");
    console.error("Error object:", error);
    console.error("Error response data:", error.response?.data);
    dispatch({ 
      type: USER_REGISTER_FAIL, 
      payload: error.response?.data?.error || error.message 
    });
    throw error;
  }
};


export const logout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: CLEAR_TEMP_USER_ID });
};

export const initializeUserId = () => (dispatch, getState) => {
  const { user } = getState();
  if (!user.userId && !user.tempUserId) {
    dispatch(setTempUserId());
  }
};

export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: userId,
});

export const setTempUserId = () => (dispatch) => {
  const tempUserId = Math.floor(Math.random() * (99999 - 10000 + 1) + 10000);
  dispatch({ type: SET_TEMP_USER_ID, payload: tempUserId });
};

export const checkSchwabAuthStatus = (userId) => async (dispatch) => {
  dispatch({ type: CHECK_SCHWAB_AUTH_STATUS });
  try {
    const response = await axios.get(API_ENDPOINTS.GET_USER_AUTH_STATUS, {
      params: { user_id: userId }
    });
    dispatch(setSchwabAuthentication(response.data.is_authenticated));
  } catch (error) {
    console.error('Error checking Schwab auth status:', error);
    dispatch(setSchwabAuthentication(false));
  }
};

export const setSchwabAuthentication = (isAuthenticated) => ({
  type: SET_SCHWAB_AUTHENTICATION,
  payload: isAuthenticated,
});

export const checkAndSetUserId = () => (dispatch, getState) => {
  const { user } = getState();
  if (!user.userId) {
    dispatch(setTempUserId());
  }
};

export const setOnboardingStatus = (status) => ({
  type: SET_ONBOARDING_STATUS,
  payload: status,
});


export const updateUserSubscription = (subscriptionData) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_SUBSCRIPTION_REQUEST });
    
    // No API call needed here since we're just updating the Redux state
    // with data we already received from the GET_SUBSCRIPTION_STATUS endpoint
    dispatch({
      type: UPDATE_USER_SUBSCRIPTION_SUCCESS,
      payload: subscriptionData
    });
    
    return subscriptionData;
  } catch (error) {
    dispatch({
      type: UPDATE_USER_SUBSCRIPTION_FAIL,
      payload: error.response?.data?.error || error.message
    });
  }
};


export const fetchChatResponseCount = (userId) => async (dispatch) => {
  console.log('Fetching chat response count for userId:', userId);
  try {
    dispatch({ type: FETCH_CHAT_RESPONSE_COUNT_REQUEST });
    const response = await axios.get(`${API_ENDPOINTS.CHAT_RESPONSE_COUNT}/${userId}`);
    console.log('Chat response count API response:', response.data);
    dispatch({
      type: FETCH_CHAT_RESPONSE_COUNT_SUCCESS,
      payload: {
        count: response.data.count,
        limit: response.data.limit,
        isUnlimited: response.data.isUnlimited
      }
    });
    console.log('Updated chat response count in Redux:', response.data.count);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat response count:', error);
    dispatch({
      type: FETCH_CHAT_RESPONSE_COUNT_FAIL,
      payload: error.response?.data?.error || error.message
    });
  }
};

export const updateLocalChatResponseCount = (count) => ({
  type: UPDATE_LOCAL_CHAT_RESPONSE_COUNT,
  payload: count,
});


// Modified userActions.js - updated initializeHomePageUser function

export const initializeHomePageUser = () => async (dispatch, getState) => {
  console.log("=== Initializing user for home page ===");
  const { user } = getState();
  
  // If user is already logged in, no need to do anything
  if (user.userId) {
    console.log("User already logged in with ID:", user.userId);
    return user.userId;
  }
  
  // If we already have a temp user ID, use it
  if (user.tempUserId) {
    console.log("Using existing temp user ID:", user.tempUserId);
    return user.tempUserId;
  }
  
  // Create a new complete temp user via API
  try {
    console.log("Creating new complete temp user via API");
    const response = await axios.post(API_ENDPOINTS.TEMP_USER_COMPLETE);
    
    if (response.data && response.data.success) {
      const tempId = response.data.user_id;
      console.log(`Created new complete temp user ID via API: ${tempId}`);
      
      // Store in localStorage and Redux
      localStorage.removeItem('tempUserId'); // Clear any existing
      localStorage.setItem('tempUserId', tempId.toString());
      dispatch({ type: SET_TEMP_USER_ID, payload: tempId });
      
      return tempId;
    } else {
      throw new Error("API returned unsuccessful response");
    }
  } catch (error) {
    console.error('Failed to create complete temp user via API', error);
    
    // Fall back to your existing temp ID generation
    console.log("Falling back to default temp ID generation");
    const tempId = Math.floor(Math.random() * (99999 - 10000 + 1) + 10000);
    
    dispatch({ type: SET_TEMP_USER_ID, payload: tempId });
    
    return tempId;
  }
};