import React, { useState } from 'react';
import { HiUser, HiXMark } from 'react-icons/hi2';

const NameInputModal = ({ isOpen, onSubmit, onClose }) => {
 const [name, setName] = useState('');
 if (!isOpen) return null;

 const handleSubmit = (e) => {
   e.preventDefault();
   if (name.trim()) {
     onSubmit(name.trim());
   }
 };

 const handleBackgroundClick = (e) => {
   if (e.target === e.currentTarget) {
     onClose();
   }
 };

 return (
   <div 
     className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
     onClick={handleBackgroundClick}
   >
     <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl relative">
       <button
         onClick={onClose}
         className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
       >
         <HiXMark className="w-5 h-5" />
       </button>
       
       <div className="text-center">
         <HiUser className="mx-auto h-12 w-12 text-indigo-600" />
         
         <h3 className="mt-4 text-lg font-semibold text-gray-900">
           Congratulations on completing the Examen!
         </h3>
         <p className="mt-2 text-gray-600">
           Enter your email to save your score
         </p>          
         <form onSubmit={handleSubmit} className="mt-4">
           <input
             type="text"
             value={name}
             onChange={(e) => setName(e.target.value)}
             placeholder="Enter your email"
             className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
             required
           />
           <button
             type="submit"
             className="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
           >
             Save Stats
           </button>
         </form>
       </div>
     </div>
   </div>
 );
};

export default NameInputModal;