// src/services/analytics.js

/**
 * Google Analytics utility for ExamenAI
 * 
 * This utility provides a comprehensive set of tracking functions
 * for monitoring user behavior throughout the application.
 */

// Helper function to safely call gtag
const sendEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    // Add UTM parameters to all events if available
    const utmData = getUTMData();
    const enhancedParams = {
      ...eventParams,
      ...utmData
    };
    
    window.gtag('event', eventName, enhancedParams);
    console.log(`Analytics event: ${eventName}`, enhancedParams);
  } else {
    console.warn(`Analytics not loaded for event: ${eventName}`);
  }
};

// Set user properties in Google Analytics
export const setUserProperties = (userId, userProperties = {}) => {
  if (window.gtag) {
    // Set user ID for cross-device tracking
    window.gtag('config', 'G-VD24BEX16T', {
      'user_id': userId
    });
    
    // Set custom user properties
    window.gtag('set', 'user_properties', userProperties);
    console.log('Set user properties:', userProperties);
  }
};

// Initialize user for analytics
export const initializeUser = (userId, tempUserId, userType = 'returning') => {
  if (!window.gtag) return;
  
  const properties = {
    user_type: userType, // 'new' or 'returning'
    user_id: userId,
    temp_user_id: tempUserId,
    first_seen_date: new Date().toISOString().split('T')[0]
  };
  
  setUserProperties(userId, properties);
};

// ====== HOME PAGE EVENTS ======

// Track when users start an examen
export const trackExamenStart = (entryPoint = 'home_page') => {
  sendEvent('examen_start', {
    entry_point: entryPoint
  });
};

// Track when users select a specific step
export const trackStepStart = (stepType, stepData = {}) => {
  sendEvent('step_start', {
    step_type: stepType,
    user_type: stepData.userId ? 'registered' : 'anonymous',
    journey_id: stepData.journeyId || 'default',
    sequence: stepData.sequence || 0,
    ...stepData
  });
};

// Track when users complete a step
export const trackStepComplete = (stepType, stepData = {}) => {
  sendEvent('step_complete', {
    step_type: stepType,
    user_type: stepData.userId ? 'registered' : 'anonymous',
    journey_id: stepData.journeyId || 'default',
    time_spent: stepData.timeSpent || 0,
    ...stepData
  });
};

// ====== DASHBOARD EVENTS ======

// Track dashboard page view
export const trackDashboardView = (userData = {}) => {
  sendEvent('dashboard_view', {
    user_type: userData.isNewUser ? 'new' : 'returning',
    subscription_type: userData.subscriptionType || 'weekly',
    has_completed_examens: userData.hasCompletedExamens || false,
    ...userData
  });
};

// Track step selection from dashboard
export const trackDashboardStepClick = (step, journeyData = {}) => {
  sendEvent('dashboard_step_click', {
    step_type: step.type,
    step_id: step.id,
    step_status: step.status,
    journey_id: journeyData.id,
    journey_progress: journeyData.progress || 0,
    ...journeyData
  });
};

// Track journey completion
export const trackJourneyCompletion = (journeyData = {}) => {
  sendEvent('journey_complete', {
    journey_id: journeyData.id,
    journey_name: journeyData.title || 'Unknown Journey',
    steps_count: journeyData.steps?.length || 0,
    steps_completed: journeyData.stepsCompleted || 0,
    time_to_complete_days: journeyData.daysToComplete || 0,
    ...journeyData
  });
};

// ====== TRIVIA/REFLECTION EVENTS ======

// Track when users view a question
export const trackQuestionView = (questionData = {}) => {
  sendEvent('question_view', {
    question_number: questionData.questionNumber,
    question_type: questionData.questionType,
    difficulty: questionData.difficulty,
    topic: questionData.topic,
    ...questionData
  });
};

// Track when users select an answer
export const trackAnswerSelection = (answerData = {}) => {
  sendEvent('answer_select', {
    question_number: answerData.questionNumber,
    question_type: answerData.questionType,
    answer_type: typeof answerData.answer === 'string' ? 'text' : 'choice',
    ...answerData
  });
};

// Track when users submit an answer
export const trackAnswerSubmission = (answerData = {}) => {
  sendEvent('answer_submit', {
    is_correct: answerData.isCorrect,
    question_number: answerData.questionNumber,
    difficulty: answerData.difficulty,
    time_spent: answerData.timeSpent || 0,
    ...answerData
  });
};

// Track trivia session completion
export const trackTriviaCompletion = (triviaData = {}) => {
  sendEvent('trivia_complete', {
    score: triviaData.score || 0,
    correct_answers: triviaData.correctAnswers || 0,
    total_questions: triviaData.totalQuestions || 0,
    time_spent_total: triviaData.timeSpentTotal || 0,
    difficulty_reached: triviaData.maxDifficultyReached,
    ...triviaData
  });
};

// Track reflection submission
export const trackReflectionSubmission = (reflectionData = {}) => {
  sendEvent('reflection_submit', {
    question_topic: reflectionData.topic,
    word_count: reflectionData.wordCount || 0,
    time_spent: reflectionData.timeSpent || 0,
    ...reflectionData
  });
};

// ====== AUTHENTICATION EVENTS ======

// Track when auth modal is opened
export const trackAuthModalOpen = (source = 'home', modalData = {}) => {
  sendEvent('auth_modal_open', {
    trigger_source: source,
    initial_mode: modalData.initialMode || 'register',
    is_from_trivia: modalData.isFromTrivia || false,
    ...modalData
  });
};

// Track when user toggles between login/register
export const trackAuthModeToggle = (fromMode, toMode) => {
  sendEvent('auth_mode_toggle', {
    from_mode: fromMode,
    to_mode: toMode
  });
};

// Track successful authentication
export const trackAuthSuccess = (method, authData = {}) => {
  sendEvent('auth_success', {
    auth_method: method, // 'login' or 'register'
    user_type: authData.isNewUser ? 'new' : 'returning',
    ...authData
  });
};

// Track failed authentication attempts
export const trackAuthFailure = (method, errorData = {}) => {
  sendEvent('auth_failure', {
    auth_method: method, // 'login' or 'register'
    error_type: errorData.errorType || 'unknown',
    error_message: errorData.message || 'Authentication failed',
    ...errorData
  });
};

// ====== UTM & CAMPAIGN TRACKING ======

// Capture UTM parameters from URL
export const captureUTMParameters = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {};
    
    // Collect all UTM parameters
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
      if (urlParams.has(param)) {
        utmParams[param] = urlParams.get(param);
      }
    });
    
    // Store UTM data if any parameters exist
    if (Object.keys(utmParams).length > 0) {
      localStorage.setItem('utm_data', JSON.stringify(utmParams));
      
      // Send UTM data as user properties to Google Analytics
      if (window.gtag) {
        window.gtag('set', 'user_properties', utmParams);
      }
      
      console.log('Captured UTM parameters:', utmParams);
    }
    
    return utmParams;
  } catch (error) {
    console.error('Error capturing UTM parameters:', error);
    return {};
  }
};

// Get stored UTM data
export const getUTMData = () => {
  try {
    const utmData = JSON.parse(localStorage.getItem('utm_data')) || {};
    
    // Add traffic source properties if available
    if (utmData.utm_source) {
      return {
        traffic_source: utmData.utm_source,
        traffic_medium: utmData.utm_medium || '',
        traffic_campaign: utmData.utm_campaign || '',
        ...utmData
      };
    }
    
    return utmData;
  } catch (error) {
    console.error('Error getting UTM data:', error);
    return {};
  }
};

// ====== ENGAGEMENT METRICS ======

// Time on page tracking
let pageLoadTime = Date.now();
let lastActivityTime = Date.now();
let totalEngagementTime = 0;
let isPageVisible = true;
let engagementInterval;

// Start tracking user engagement time
export const startEngagementTracking = () => {
  pageLoadTime = Date.now();
  lastActivityTime = Date.now();
  
  // Track page visibility changes
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      isPageVisible = true;
      lastActivityTime = Date.now();
    } else {
      isPageVisible = false;
      // Calculate engagement time until now
      totalEngagementTime += (Date.now() - lastActivityTime);
    }
  });
  
  // Track user activity (scroll, click, keypress)
  ['scroll', 'click', 'keypress'].forEach(eventType => {
    document.addEventListener(eventType, () => {
      if (isPageVisible) {
        lastActivityTime = Date.now();
      }
    });
  });
  
  // Send engagement data every 30 seconds if page is active
  engagementInterval = setInterval(() => {
    if (isPageVisible) {
      const currentTime = Date.now();
      const sessionEngagementTime = (currentTime - lastActivityTime) + totalEngagementTime;
      totalEngagementTime = 0;
      lastActivityTime = currentTime;
      
      // Send engagement data if more than 5 seconds engaged
      if (sessionEngagementTime > 5000) {
        sendEvent('user_engagement', {
          engagement_time_msec: sessionEngagementTime,
          page_path: window.location.pathname
        });
      }
    }
  }, 30000); // 30 seconds interval
};

// Stop tracking when navigating away
export const stopEngagementTracking = () => {
  clearInterval(engagementInterval);
  
  // Send final engagement data
  if (isPageVisible) {
    const finalEngagementTime = (Date.now() - lastActivityTime) + totalEngagementTime;
    if (finalEngagementTime > 1000) {
      sendEvent('user_engagement', {
        engagement_time_msec: finalEngagementTime,
        page_path: window.location.pathname,
        engagement_type: 'exit'
      });
    }
  }
};

// Initialize analytics with event listeners
export const initializeAnalytics = () => {
  // Capture UTM parameters on page load
  captureUTMParameters();
  
  // Start engagement tracking
  startEngagementTracking();
  
  // Add cleanup on page unload
  window.addEventListener('beforeunload', () => {
    stopEngagementTracking();
  });
  
  console.log('Analytics initialized');
};

export default {
  // User tracking
  initializeUser,
  setUserProperties,
  
  // Home page events
  trackExamenStart,
  trackStepStart,
  trackStepComplete,
  
  // Dashboard events
  trackDashboardView,
  trackDashboardStepClick,
  trackJourneyCompletion,
  
  // Trivia/Reflection events
  trackQuestionView,
  trackAnswerSelection,
  trackAnswerSubmission,
  trackTriviaCompletion,
  trackReflectionSubmission,
  
  // Authentication events
  trackAuthModalOpen,
  trackAuthModeToggle,
  trackAuthSuccess,
  trackAuthFailure,
  
  // UTM tracking
  captureUTMParameters,
  getUTMData,
  
  // Engagement tracking
  startEngagementTracking,
  stopEngagementTracking,
  
  // Initialize
  initializeAnalytics
};