import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Building, ChevronRight, Users, Shield } from 'lucide-react';
import { getUserAdminCommunities } from '../../services/communityService';

/**
 * Component to display communities where the user is an admin
 */
const AdminCommunitiesSection = ({ userId }) => {
  const navigate = useNavigate();
  const [adminCommunities, setAdminCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdminCommunities = async () => {
      if (!userId) return;
      
      try {
        setLoading(true);
        const communities = await getUserAdminCommunities(userId);
        setAdminCommunities(communities);
      } catch (error) {
        console.error('Error fetching admin communities:', error);
        setError('Failed to load communities you administer');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminCommunities();
  }, [userId]);

  if (loading) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 shadow-sm p-4 animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="h-16 bg-gray-100 rounded mb-2"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-xl border border-gray-200 shadow-sm p-4">
        <p className="text-red-600 text-sm">{error}</p>
      </div>
    );
  }

  if (adminCommunities.length === 0) {
    return null; // Don't show any UI if user isn't an admin of any communities
  }

  return (
    <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden mb-4">
      <div className="px-4 py-3 border-b border-gray-200 bg-gradient-to-r from-indigo-50 to-purple-50">
        <div className="flex items-center">
          <Shield className="h-5 w-5 text-indigo-600 mr-2" />
          <h2 className="text-gray-900 font-medium">
            Parish Administrator
          </h2>
        </div>
      </div>
      
      <div className="divide-y divide-gray-100">
        {adminCommunities.map(community => (
          <div 
            key={community.id}
            className="p-4 hover:bg-gray-50 transition-colors cursor-pointer"
            onClick={() => navigate(`/community-admin/${community.id}`)}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-start space-x-3">
                <div className="flex-shrink-0 mt-1">
                  <Building className="h-5 w-5 text-indigo-500" />
                </div>
                <div>
                  <h3 className="text-base font-medium text-gray-900">{community.name}</h3>
                  <div className="flex items-center mt-1">
                    <Users className="h-4 w-4 text-gray-400 mr-1" />
                    <span className="text-xs text-gray-500">{community.memberCount || 0} members</span>
                  </div>
                </div>
              </div>
              <ChevronRight className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        ))}
      </div>
      
      <div className="px-4 py-3 border-t border-gray-200 bg-gray-50">
        <p className="text-xs text-gray-500 text-center">
          Access parish dashboards to manage content and members
        </p>
      </div>
    </div>
  );
};

export default AdminCommunitiesSection;